const initState = {
  updateStateLoading: false,
}

const orderReducer = (state = initState, action) => {
  switch (action.type) {
    case 'UPDATE_STATE_LOADING':
      return {
        ...state,
        updateStateLoading: true,
      }

    case 'UPDATE_STATE_DONE':
      return {
        ...state,
        updateStateLoading: false,
      }

    default:
      return state
  }
};

export default orderReducer;