import React, { useState } from 'react'
import { useSelector } from 'react-redux';
import AreaSelectView from '../../components/AreaSelectView';
import Admin from '../../models/admin';
import { RootState } from '../../store/reducers/rootReducer';
import CustomerList from './components/CustomerList';

function CustomerDashboardScreen() {
  const [searchBar, setSearchBar] = useState("");
  const [showOnlyCustomersInAdminAreas, setShowOnlyCustomersInAdminAreas] = useState(true);

  const admin: Admin = useSelector((state: RootState) => state.firestore.data.currentAdmin);

  return (
    <div className="customerDashboard container my-4">
      <h2>Clienti</h2>
      <AreaSelectView urlPrefix="customers" />
      <h4 className="mt-4">Clienti</h4>
      <h6 className="mt-0" style={{ color: "#777777" }}>Tutte le aree</h6>
      <div className="d-flex justify-content-between align-items-center my-3">
        <div className="form-inline">
          <div className="form-group">
            <label htmlFor="searchBar">Cerca per email</label>
            <input type="text" id="searchBar" className="form-control mx-sm-3" value={searchBar} onChange={(e) => setSearchBar(e.target.value)} />
          </div>
        </div>

        {
          !admin.isMainAdmin &&
          <div className="custom-control custom-checkbox">
            <input type="checkbox" className="custom-control-input" id="showOnlyCustomersInAdminAreas"
              checked={showOnlyCustomersInAdminAreas} onChange={(e) => setShowOnlyCustomersInAdminAreas(e.target.checked)} />
            <label className="custom-control-label" htmlFor="showOnlyCustomersInAdminAreas">Mostra solo i clienti delle tue aree </label>
          </div>
        }
      </div>
      <CustomerList searchBar={searchBar} />
    </div>
  )
}

export default CustomerDashboardScreen;