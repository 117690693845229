import React from 'react';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom'
import SignInScreen from './screens/SignInScreen'
import { useSelector } from 'react-redux'
import { isLoaded } from 'react-redux-firebase'
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-redux-toastr/lib/css/react-redux-toastr.min.css'
import AdminRoute from './components/auth/AdminRoute'
import SplashScreen from './screens/SplashScreen'
import ForbiddenScreen from './screens/ForbiddenScreen'
import NonAdminRoute from './components/auth/NonAdminRoute';
import SupplierRequestDetailsScreen from './screens/suppliers/SupplierRequestDetailsScreen';
import OrderDetailsScreen from './screens/orders/OrderDetailsScreen';
import DriverRequestDetailsScreen from './screens/drivers/request_details/DriverRequestDetailsScreen';
import DriverDashboardScreen from './screens/drivers/dashboard/DriverDashboardScreen';
import DriverDetailsScreen from './screens/drivers/details/DriverDetailsScreen';
import SupplierDashboardScreen from './screens/suppliers/dashboard/SupplierDashboardScreen';
import SupplierAreaCategoryScreen from './screens/suppliers/SupplierCategoryScreen';
import SupplierDetailsScreen from './screens/suppliers/details/SupplierDetailsScreen';
import SupplierAreaScreen from './screens/suppliers/SupplierAreaScreen';
import CustomerDashboardScreen from './screens/customers/CustomerDashboardScreen';
import CustomerDetailsScreen from './screens/customers/CustomerDetailsScreen';
import SettingsScreen from './screens/SettingsScreen';
import { Modal, Button } from 'react-bootstrap'
import DriverAreaScreen from './screens/drivers/DriverAreaScreen';
import ShiftsDashboardScreen from './screens/shifts/ShiftsDashboardScreen';
import ShiftsAreaScreen from './screens/shifts/area/ShiftsAreaScreen';
import SupplierEditCategoryScreen from './screens/suppliers/edit_category/SupplierEditCategoryScreen';
import SupplierCreateCategoryScreen from './screens/suppliers/SupplierCreateCategoryScreen';
import ShiftsConfigScreen from './screens/shifts/config/ShiftsConfigScreen';
import DiscountsScreen from './screens/discounts/DiscountsScreen';
import DiscountCreateScreen from './screens/discounts/DiscountCreateScreen';
import DiscountDetailsScreen from './screens/discounts/DiscountDetailsScreen';
import AreasDashboardScreen from './screens/areas/AreasDashboardScreen';
import AreaEditScreen from './screens/areas/AreaEditScreen';
import OrderDashboardScreen from './screens/orders/OrderDashboardScreen';
import OrderAreaScreen from './screens/orders/OrderAreaScreen';
import { LoadScript } from '@react-google-maps/api';
import DisabledAdminScreen from './screens/DisabledAdminScreen';
import AdminDashboardScreen from './screens/admin/AdminDashboardScreen';
import AdminEditScreen from './screens/admin/AdminEditScreen';
import { RootState } from './store/reducers/rootReducer';
import AdminDetailsScreen from './screens/admin/AdminDetailsScreen';
import CustomerAreaScreen from './screens/customers/CustomerAreaScreen';
import WorkWithUsDashboardScreen from './screens/WorkWithUsDashboardScreen';
import CreateReportScreen from './screens/reports/CreateReportScreen';

import 'react-widgets/dist/css/react-widgets.css';
import MyNavbar from './components/MyNavbar';
import ReportDashboardScreen from './screens/reports/ReportDashboardScreen';
import ReportDetailsScreen from './screens/reports/ReportDetailsScreen';
import DriversReportsScreen, { DriverReportsScreen } from './screens/reports/DriversReportsScreen';
import SuppliersReportsScreen, { SupplierReportsScreen } from './screens/reports/SuppliersReportsScreen';

const AuthIsLoaded: React.FunctionComponent<{}> = ({ children }) => {
  const { auth, profile } = useSelector((state: RootState) => ({ auth: state.firebase.auth, profile: state.firebase.profile }));
  if (!isLoaded(auth) || !isLoaded(profile))
    return <SplashScreen />;
  return <>{children}</>
}

function App() {
  const isServiceWorkerUpdated = useSelector(
    (state: RootState) => state.serviceWorker.serviceWorkerUpdated,
  );

  const serviceWorkerRegistration = useSelector(
    (state: RootState) => state.serviceWorker.serviceWorkerRegistration,
  );

  const updateServiceWorker = () => {
    const registrationWaiting = serviceWorkerRegistration.waiting;

    if (registrationWaiting) {
      registrationWaiting.postMessage({ type: 'SKIP_WAITING' });

      registrationWaiting.addEventListener('statechange', (e: any) => {
        if (e.target.state === 'activated') {
          window.location.reload();
        }
      });
    }
  };

  const admin = useSelector((state: RootState) => state.firestore.data.currentAdmin);

  return (
    <BrowserRouter>
      <AuthIsLoaded>
        <LoadScript googleMapsApiKey="AIzaSyBF0Pfn0O6gmM0sgugGglxvdoBvJFsEq0U" >
          <div className="App">
            <div className="App-alert">
              {isServiceWorkerUpdated && (
                <Modal show={true} onHide={updateServiceWorker}>
                  <Modal.Body>
                    <div>Una nuova versione del sito è disponibile.</div>
                    <div>Clicca &apos;Ricarica&apos; per ricaricare la pagina.</div>
                  </Modal.Body>
                  <div className="d-flex justify-content-center mx-2 mb-2">
                    <Button variant="primary" type="submit" onClick={updateServiceWorker}>
                      Ricarica
                    </Button>
                  </div>
                </Modal>
              )}
            </div>
            <Switch>
              <Route path='/signin' component={SignInScreen} />
              <NonAdminRoute path='/forbidden'>
                <ForbiddenScreen />
              </NonAdminRoute>
              <NonAdminRoute path='/disabled'>
                <DisabledAdminScreen />
              </NonAdminRoute>
              <AdminRoute exact path='/discounts'>
                <MyNavbar />
                <DiscountsScreen />
              </AdminRoute>
              <AdminRoute exact path='/discounts/new'>
                <MyNavbar />
                <DiscountCreateScreen />
              </AdminRoute>
              <AdminRoute exact path='/discounts/:codeId'>
                <MyNavbar />
                <DiscountDetailsScreen />
              </AdminRoute>
              <AdminRoute exact path='/shifts'>
                <MyNavbar />
                <ShiftsDashboardScreen />
              </AdminRoute>
              <AdminRoute exact path='/shifts/area/:areaId'>
                <MyNavbar />
                <ShiftsAreaScreen />
              </AdminRoute>
              <AdminRoute exact path='/shifts/area/:areaId/config'>
                <MyNavbar />
                <ShiftsConfigScreen />
              </AdminRoute>
              <AdminRoute exact path='/drivers'>
                <MyNavbar />
                <DriverDashboardScreen />
              </AdminRoute>
              <AdminRoute exact path='/drivers/area/:areaId'>
                <MyNavbar />
                <DriverAreaScreen />
              </AdminRoute>
              {
                admin?.isMainAdmin === true &&
                <AdminRoute exact path='/drivers/reports'>
                  <MyNavbar />
                  <DriversReportsScreen />
                </AdminRoute>
              }
              <AdminRoute exact path='/drivers/:id'>
                <MyNavbar />
                <DriverDetailsScreen />
              </AdminRoute>
              <AdminRoute exact path='/drivers/:id/reports'>
                <MyNavbar />
                <ReportDashboardScreen type="driver" />
              </AdminRoute>
              <AdminRoute exact path='/drivers/:id/reports/list'>
                <MyNavbar />
                <DriverReportsScreen />
              </AdminRoute>
              <AdminRoute exact path='/drivers/:id/reports/create'>
                <MyNavbar />
                <CreateReportScreen type="driver" />
              </AdminRoute>
              <AdminRoute exact path='/drivers/:id/reports/:reportId'>
                <MyNavbar />
                <ReportDetailsScreen type="driver" />
              </AdminRoute>
              <AdminRoute exact path='/drivers/requests/:id'>
                <MyNavbar />
                <DriverRequestDetailsScreen />
              </AdminRoute>
              <AdminRoute exact path='/suppliers'>
                <MyNavbar />
                <SupplierDashboardScreen />
              </AdminRoute>
              {
                admin?.isMainAdmin &&
                <AdminRoute exact path='/suppliers/category/new'>
                  <MyNavbar />
                  <SupplierCreateCategoryScreen />
                </AdminRoute>
              }
              {
                admin?.isMainAdmin &&
                <AdminRoute exact path='/suppliers/category/:categoryId'>
                  <MyNavbar />
                  <SupplierEditCategoryScreen />
                </AdminRoute>
              }
              {
                admin?.isMainAdmin === true &&
                <AdminRoute exact path='/suppliers/reports'>
                  <MyNavbar />
                  <SuppliersReportsScreen />
                </AdminRoute>
              }
              <AdminRoute exact path='/suppliers/:id'>
                <MyNavbar />
                <SupplierDetailsScreen />
              </AdminRoute>
              <AdminRoute exact path='/suppliers/:id/reports'>
                <MyNavbar />
                <ReportDashboardScreen type="supplier" />
              </AdminRoute>
              <AdminRoute exact path='/suppliers/:id/reports/list'>
                <MyNavbar />
                <SupplierReportsScreen />
              </AdminRoute>
              <AdminRoute exact path='/suppliers/:id/reports/create'>
                <MyNavbar />
                <CreateReportScreen type="supplier" />
              </AdminRoute>
              <AdminRoute exact path='/suppliers/:id/reports/:reportId'>
                <MyNavbar />
                <ReportDetailsScreen type="supplier" />
              </AdminRoute>
              <AdminRoute exact path='/suppliers/requests/:id'>
                <MyNavbar />
                <SupplierRequestDetailsScreen />
              </AdminRoute>
              <AdminRoute exact path='/suppliers/area/:areaId'>
                <MyNavbar />
                <SupplierAreaScreen />
              </AdminRoute>
              <AdminRoute exact path='/suppliers/area/:areaId/category/:categoryId'>
                <MyNavbar />
                <SupplierAreaCategoryScreen />
              </AdminRoute>
              <AdminRoute exact path='/customers'>
                <MyNavbar />
                <CustomerDashboardScreen />
              </AdminRoute>
              <AdminRoute exact path='/customers/area/:areaId'>
                <MyNavbar />
                <CustomerAreaScreen />
              </AdminRoute>
              <AdminRoute exact path='/customers/:id'>
                <MyNavbar />
                <CustomerDetailsScreen />
              </AdminRoute>
              <AdminRoute exact path='/orders/area/:areaId'>
                <MyNavbar />
                <OrderAreaScreen />
              </AdminRoute>
              <AdminRoute exact path='/orders/:orderId'>
                <MyNavbar />
                <OrderDetailsScreen />
              </AdminRoute>
              {
                admin?.isMainAdmin === true &&
                <AdminRoute exact path='/settings'>
                  <MyNavbar />
                  <SettingsScreen />
                </AdminRoute>
              }
              {
                admin?.isMainAdmin === true &&
                <AdminRoute exact path='/admin'>
                  <MyNavbar />
                  <AdminDashboardScreen />
                </AdminRoute>
              }
              {
                admin?.isMainAdmin === true &&
                <AdminRoute exact path='/admin/edit'>
                  <MyNavbar />
                  <AdminEditScreen />
                </AdminRoute>
              }
              {
                admin?.isMainAdmin === true &&
                <AdminRoute exact path='/admin/:id'>
                  <MyNavbar />
                  <AdminDetailsScreen />
                </AdminRoute>
              }
              <AdminRoute exact path='/areas'>
                <MyNavbar />
                <AreasDashboardScreen />
              </AdminRoute>
              <AdminRoute exact path='/areas/edit'>
                <MyNavbar />
                <AreaEditScreen />
              </AdminRoute>
              <AdminRoute exact path='/work_with_us'>
                <MyNavbar />
                <WorkWithUsDashboardScreen />
              </AdminRoute>
              <AdminRoute exact path='/'>
                <MyNavbar />
                <OrderDashboardScreen />
              </AdminRoute>
              <Route>
                <Redirect
                  to={{
                    pathname: "/",
                  }}
                />
              </Route>

            </Switch>
          </div>
        </LoadScript>
      </AuthIsLoaded>
    </BrowserRouter>
  );
}

export default App;
