import firebase from 'firebase';
import moment from 'moment';

function getDateTimeString(timestamp: firebase.firestore.Timestamp): string {
  let datetime = moment(timestamp.toMillis());
  return datetime.format("D MMMM") + " alle " + datetime.format("HH:mm");
}

function getDateString(timestamp: firebase.firestore.Timestamp): string {
  let datetime = moment(timestamp.toMillis());
  return datetime.format("DD-MM-YYYY");
}

function getTimeString(timestamp: firebase.firestore.Timestamp): string {
  let datetime = moment(timestamp.toMillis());
  return datetime.format("HH:mm");
}

export { getDateString, getTimeString, getDateTimeString }