import React, { useEffect, useState } from 'react'
import { Modal, Button } from 'react-bootstrap'
import firebase from 'firebase/app'
import { toastr } from 'react-redux-toastr';

type ChangeZipCodesModalProps = {
  initialValue: string[],
  show: boolean,
  close: () => void,
  supplierId: string
}

function ChangeZipCodesModal({ initialValue, show, close, supplierId }: ChangeZipCodesModalProps) {
  const [zipCodes, setZipCodes] = useState(initialValue);

  useEffect(() => {
    if (show)
      setZipCodes(initialValue);
  }, [show])

  async function save() {
    close();
    const firestore = firebase.firestore();

    let formattedZipCodes = [];
    for (let code of zipCodes) {
      let trimmedCode = code.trim();
      if (trimmedCode.length > 0)
        formattedZipCodes.push(code.trim());
    }

    try {
      await firestore.collection("suppliers").doc(supplierId).update({
        zipCodes: formattedZipCodes,
      });

      toastr.success("CAP cambiati con successo!", "");
    }
    catch (err) {
      toastr.error("Errore inaspettato!", "");
      console.log(err);
    }
  }

  return (
    <Modal show={show} onHide={close}>
      <Modal.Header closeButton>
        <Modal.Title>Modifica dei CAP del fornitore</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {zipCodes.map((c, i) =>
          <input key={i} type="text" style={{ minWidth: "400px" }} className="form-control mb-2" value={c} onChange={(e) => {
            let newZipCodes = zipCodes.slice();
            newZipCodes[i] = e.target.value;
            setZipCodes(newZipCodes);
          }} />)}
        <button className="btn btn-primary" onClick={() => {
          let newZipCodes = zipCodes.slice();
          newZipCodes.push("");
          setZipCodes(newZipCodes);
        }}>+</button>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" type="button" onClick={close}>
          Annulla
        </Button>
        <Button variant="success" type="button" onClick={save}>
          Salva
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default ChangeZipCodesModal;
