import React from 'react'

function SplashScreen() {
    return (
        <div className="jumbotron d-flex align-items-center min-vh-100 bg-light">
            <div className="container d-flex justify-content-center">
                <div className="loader"></div>
            </div>
        </div>
    );
}

export default SplashScreen;