import React, { Component } from 'react'
import { connect } from 'react-redux';
import { firestoreConnect, isLoaded, isEmpty } from 'react-redux-firebase'
import { compose } from 'redux'
import { getDateTimeString } from '../../helpers/dateHelper';
import { withRouter } from 'react-router-dom';
import { updateRequestState } from '../../store/actions/supplierActions'
import { doubleToCurrency, doubleToPercentage } from '../../helpers/currencyHelper';
import moment from 'moment';
import SplashScreen from '../SplashScreen';
import ClickableImage from '../../components/ClickableImage';
import ButtonWithConfirmation from '../../components/ButtonWithConfirmation';
import ButtonWithConfirmationAndTextArea from '../../components/ButtonWithConfirmationAndTextArea';

class SupplierRequestDetails extends Component {
  handleRefuse = (reason) => {
    this.props.refuseSupplierRequest(reason);
    this.props.history.push('/suppliers');
  }

  handleAccept = () => {
    this.props.acceptSupplierRequest();
    this.props.history.push('/suppliers');
  }

  render() {
    let { request, category, admin, area } = this.props;

    if (!isLoaded(request, area, category))
      return <SplashScreen />

    if (isEmpty(request) || request?.state !== "PENDING")
      return <div className="container my-4">Richiesta inesistente.</div>;

    if (isEmpty(category))
      return (
        <div className="container my-4">
          La categoria del fornitore non è più esistente.
          <div className="mt-2">
            <button className="btn btn-outline-danger btn-sm"
              onClick={this.handleRefuse}>Rifiuta la richiesta</button>
          </div>
        </div>
      );

    if (!admin.isMainAdmin && !admin.areaIds.includes(request.areaId))
      return <div className="container my-4">Non hai accesso alla visualizzazione di questa richiesta.</div>;

    let tagNames = [];
    for (let i = 0; i < request.tags.length; i++) {
      let tagName = category.tags.find(t => t.id === request.tags[i])?.name;
      if (tagName !== null)
        tagNames.push(tagName);
    }

    if (tagNames.length === 0)
      return (
        <div className="container my-4">
          I tag scelti dal fornitore non sono più esistenti.
          <div className="mt-2">
            <button className="btn btn-outline-danger btn-sm"
              onClick={this.handleRefuse}>Rifiuta la richiesta</button>
          </div>
        </div>
      );

    let tagString = "";
    for (let i = 0; i < tagNames.length; i++) {
      tagString += tagNames[i] + ", ";
    }

    tagString = tagString.slice(0, -2);

    return (
      <div className="productSummary container my-4">
        <h4 className="my-auto">Richiesta di collaborazione fornitore</h4>
        <div className="card mt-2">
          <div className="card-body">
            <h5 className="mt-2">Informazioni del titolare/admin</h5>
            <table className="table table-borderless table-sm">
              <tbody>
                <tr>
                  <th scope="row">Data richiesta: </th>
                  <td style={{ whiteSpace: "nowrap", width: "70%" }}>{getDateTimeString(request.timestamp)}</td>
                </tr>
                <tr>
                  <th scope="row">Nominativo titolare: </th>
                  <td>{request.name}</td>
                </tr>
                <tr>
                  <th scope="row">Email: </th>
                  <td>{request.email}</td>
                </tr>
              </tbody>
            </table>
            <hr />
            <h5 className="mt-2">Informazioni dell&apos;attività</h5>
            <table className="table table-borderless table-sm">
              <tbody>
                <tr>
                  <th scope="row">Area: </th>
                  <td style={{ width: "70%" }}>{area.name ?? "Non specificato"}</td>
                </tr>
                <tr>
                  <th scope="row">Ragione sociale: </th>
                  <td style={{ width: "70%" }}>{request.companyName}</td>
                </tr>
                <tr>
                  <th scope="row">Nome dell&apos;attività: </th>
                  <td style={{ width: "70%" }}>{request.supplierName}</td>
                </tr>
                <tr>
                  <th scope="row">Partita IVA: </th>
                  <td>{request.partitaIVA}</td>
                </tr>
                <tr>
                  <th scope="row">Codice univoco per la fatturazione: </th>
                  <td>{request.ipa}</td>
                </tr>
                <tr>
                  <th scope="row">Indirizzo: </th>
                  <td>{request.address}</td>
                </tr>
                {
                  request.registeredOfficeAddress && request.registeredOfficeAddress !== "" &&
                  <tr>
                    <th scope="row">Indirizzo sede legale: </th>
                    <td>{request.registeredOfficeAddress}</td>
                  </tr>
                }
                <tr>
                  <th scope="row">Numero di telefono: </th>
                  <td style={{ whiteSpace: "nowrap" }}>{request.phoneNumber}</td>
                </tr>
              </tbody>
            </table>
            <hr />
            <h5 className="mt-2">Scheda dell&apos;attività nell&apos;app</h5>
            <table className="table table-borderless table-sm">
              <tbody>
                <tr>
                  <th scope="row">Descrizione breve: </th>
                  <td style={{ width: "70%" }}>{request.description}</td>
                </tr>
                <tr>
                  <th scope="row">Immagine di copertina: </th>
                  <td><ClickableImage url={request.imageUrl} style={{ maxWidth: "100%", maxHeight: "200px" }} /></td>
                </tr>
                <tr>
                  <th scope="row">Categoria: </th>
                  <td>{category.name}</td>
                </tr>
                <tr>
                  <th scope="row">Tag: </th>
                  <td>{tagString}</td>
                </tr>
              </tbody>
            </table>
            <hr />
            <h5 className="mt-2">Percentuale fornitore</h5>
            <table className="table table-borderless table-sm">
              <tbody>
                <tr>
                  <th scope="row">Percentuale fornitore: </th>
                  <td style={{ width: "70%" }}>{doubleToPercentage(request.supplierPercentage)}</td>
                </tr>
                <tr>
                  <th scope="row">Promozione: </th>
                  <td>{request.hasPercentagePromo ? "Si" : "No"}</td>
                </tr>
                {
                  request.hasPercentagePromo && <>
                    <tr>
                      <th scope="row">Percentuale fornitore durante la promozione: </th>
                      <td>{doubleToPercentage(request.promoSupplierPercentage)}</td>
                    </tr>
                    <tr>
                      <th scope="row">Fine promozione: </th>
                      <td>{moment(request.promoEndDate, "YYYY-MM-DD").format("D MMMM YYYY")}</td>
                    </tr>
                  </>
                }
              </tbody>
            </table>
            <hr />
            <h5 className="mt-2">Utilizzo propri fattorini</h5>
            <table className="table table-borderless table-sm">
              <tbody>
                <tr>
                  <th scope="row">Fattorini propri abilitati: </th>
                  <td style={{ width: "70%" }}>{request.canUseOwnDrivers ? "Si" : "No"}</td>
                </tr>
                {
                  request.canUseOwnDrivers && <>
                    <tr>
                      <th scope="row">Percentuale con fattorini propri: </th>
                      <td>{doubleToPercentage(request.supplierPercentageWithOwnDrivers)}</td>
                    </tr>
                    <tr>
                      <th scope="row">Costo spedizione con fattorini propri: </th>
                      <td>{doubleToCurrency(request.deliveryAmountWithOwnDrivers)}</td>
                    </tr>
                  </>
                }
              </tbody>
            </table>
            <hr />
            {
              request.notes && request.notes !== "" &&
              <>
                <h5 className="mt-2">Note aggiuntive</h5>
                <div>{request.notes}</div>
              </>
            }
            <div className="mt-2 d-flex justify-content-end">
              <ButtonWithConfirmationAndTextArea
                textAreaTitle="Motivazione"
                modalMessage="Sicuro di voler rifiutare la richiesta?"
                className="btn btn-outline-danger btn-sm mr-1"
                onConfirm={this.handleRefuse}
                buttonText="Rifiuta" />
              <ButtonWithConfirmation
                modalMessage="Sicuro di voler accettare la richiesta?"
                className="btn btn-outline-success btn-sm"
                onConfirm={this.handleAccept}
                buttonText="Accetta" />
            </div>
          </div>
        </div>
      </div>
    )
  }
}


const mapStateToProps = (state) => {
  return {
    request: state.firestore.data.request,
    category: state.firestore.data.category,
    admin: state.firestore.data.currentAdmin,
    area: state.firestore.data.requestArea,
  }
}

const mapDispatchToProps = (dispatch, props) => {
  return {
    acceptSupplierRequest: () => dispatch(updateRequestState(props.match.params.id, "ACCEPTED")),
    refuseSupplierRequest: (reason) => dispatch(updateRequestState(props.match.params.id, "REFUSED", reason)),
  }
}

export default withRouter(compose(
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect((props) => {
    return [
      {
        collection: 'supplier_requests',
        doc: props.match.params.id,
        storeAs: 'request',
      },
    ];
  }),
  firestoreConnect((props) => {
    if (props.request?.category === undefined)
      return [];
    return [
      {
        collection: 'supplier_categories',
        doc: props.request.category,
        storeAs: 'category',
      },
      ...props.request !== null && props.request !== undefined ? [
        {
          collection: "areas",
          doc: props.request.areaId,
          storeAs: "requestArea"
        }
      ] : [],
    ];
  }),
)(SupplierRequestDetails))