import React from "react";
import NumberFormat from "react-number-format";

type CurrencyInputProps = {
    amount: number | null;
    setAmount: (newVal: number | null) => void,
    isInteger?: boolean;
    required?: boolean;
    max?: number | null;
    allowNegative?: boolean
}

function CurrencyInput({ amount, setAmount, max = null, isInteger = false, required = false, allowNegative = false }: CurrencyInputProps) {
    let amountDouble = calcAmountDouble(amount, isInteger);
    let maxDouble = calcAmountDouble(max, isInteger);

    return <NumberFormat
        decimalScale={2}
        allowNegative={allowNegative}
        thousandSeparator={false}
        decimalSeparator=","
        prefix="€"
        isAllowed={(values) => {
            const { floatValue } = values;
            return floatValue === undefined || ((allowNegative || floatValue >= 0) && (maxDouble === undefined || floatValue <= maxDouble));
        }}
        max={maxDouble}
        fixedDecimalScale={true}
        className="form-control"
        value={amountDouble}
        style={{ maxWidth: 300 }}
        onValueChange={({ floatValue }) => {
            if (floatValue === amountDouble)
                return;

            setAmount(floatValue === undefined ? null : (isInteger ? Math.floor(floatValue * 100.0) : floatValue));
        }} required={required} />;
}

type CurrencyInputWithTitleProps = CurrencyInputProps & {
    title: string;
    hint?: string;
}

export function CurrencyInputWithTitle({ title, hint, amount, max, setAmount, isInteger = false, required = false, allowNegative }: CurrencyInputWithTitleProps) {
    return <div className="form-group">
        <label className="required">{title}</label>
        <CurrencyInput
            amount={amount}
            setAmount={setAmount}
            isInteger={isInteger}
            allowNegative={allowNegative}
            required={required}
            max={max} />
        {hint &&
            <div className="mt-1" style={{ fontSize: 12, color: "grey" }}>{hint}</div>}
    </div>
}

export default CurrencyInput;

function calcAmountDouble(amount: number | null, isInteger: boolean): number | undefined {
    return amount === null ? undefined : (isInteger ? amount / 100.0 : amount)
};
