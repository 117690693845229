import React, { useState } from 'react'
import { Modal, Button } from 'react-bootstrap'
import { toastr } from 'react-redux-toastr';

type ChangePasswordModalProps = {
  show: boolean;
  onCancel: () => void;
  onDone: (email: string) => void
}

function ChangePasswordModal({ show, onCancel, onDone }: ChangePasswordModalProps) {
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");

  return (
    <Modal show={show} onHide={onCancel}>
      <Modal.Header closeButton>
        <Modal.Title>Reimpostazione della password</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="form-group">
          <label htmlFor="password" className="required">Password</label>
          <input className="form-control" minLength={8} type="password" id="password" value={password} required onChange={(e) => setPassword(e.target.value)} />
        </div>
        <div className="form-group">
          <label htmlFor="passwordConfirm" className="required">Conferma password</label>
          <input className="form-control" minLength={8} type="password" id="passwordConfirm" value={passwordConfirm} required onChange={(e) => setPasswordConfirm(e.target.value)} />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" type="button" onClick={onCancel}>
          Annulla
        </Button>
        <Button variant="success" type="button" onClick={() => {
          if (password.length < 8) {
            toastr.error("Password inserita troppo corta!", "");
            return;
          }
          if (password !== passwordConfirm) {
            toastr.error("Le password inserite non corrispondono!", "");
            return;
          }
          onDone(password);
        }}>
          Conferma
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default ChangePasswordModal;
