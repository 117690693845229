import React from 'react'
import DriverRequests from './components/DriverRequests';
import DriverList from '../components/DriverList';
import AreaSelectView from '../../../components/AreaSelectView';
import { Link } from 'react-router-dom';

function DriverDashboardScreen() {
  return (
    <div className="driverDashboard container my-4">
      <div className="d-flex justify-content-between flex-wrap align-items-center">
        <h2 className="mb-2 mr-2">Fattorini</h2>
        <Link to="drivers/reports" className="btn btn-outline-secondary mb-2">Visualizza resoconti</Link>
      </div>
      <DriverRequests />
      <AreaSelectView urlPrefix="drivers" />
      <h4 className="mt-4">Fattorini</h4>
      <h6 className="mt-0" style={{ color: "#777777" }}>Tutte le aree</h6>
      <DriverList />
    </div>
  )
}



export default DriverDashboardScreen;