import React, { FormEvent, useState } from 'react'
import moment from 'moment';
import NumberFormat from 'react-number-format';
import shortid from 'shortid';
import firebase from 'firebase/app'
import { toastr } from 'react-redux-toastr';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { defaultWeekdayValidity, DiscountCodeType, WeekdayNumber, WeekdayValidity } from '../../models/discount_code';
import { useSelector } from 'react-redux';
import Admin from '../../models/admin';
import { RootState } from '../../store/reducers/rootReducer';
import Supplier from '../../models/supplier';


function DiscountCreateScreen(props: RouteComponentProps) {
  const admin: Admin = useSelector((state: RootState) => state.firestore.data.currentAdmin as Admin);

  const [code, setCode] = useState("");

  const [startDate, setStartDate] = useState(moment().format("YYYY-MM-DD"));
  const [hasEndDate, setHasEndDate] = useState(false);
  const [endDate, setEndDate] = useState(moment().add(1, "days").format("YYYY-MM-DD"));

  const [startTime, setStartTime] = useState("00:00");
  const [endTime, setEndTime] = useState("00:00");

  const [isPeriodic, setIsPeriodic] = useState(false);
  const [weekdayValidity, setWeekdayValidity] = useState<WeekdayValidity>(defaultWeekdayValidity);

  const [codeType, setCodeType] = useState<DiscountCodeType>(admin.isMainAdmin ? "ALL_CUSTOMERS" : "SINGLE_AREA");

  const [customerId, setCustomerId] = useState("");
  const [supplierId, setSupplierId] = useState("");
  const [areaId, setAreaId] = useState("");

  const [hasFixedAmount, setHasFixedAmount] = useState(true);
  const [amount, setAmount] = useState<number | null>(5);
  const [percentage, setPercentage] = useState<number | null>(0.10);
  const [hasFreeDelivery, setHasFreeDelivery] = useState(false);

  const [pointsThreshold, setPointsThreshold] = useState<number | null>(100);

  const [hasMinOrderAmount, setHasMinOrderAmount] = useState(false);
  const [minOrderAmount, setMinOrderAmount] = useState<number | null>(5);

  const [isPromo, setIsPromo] = useState(false);
  const [promoTitle, setPromoTitle] = useState("");
  const [promoMessage, setPromoMessage] = useState("");

  const [codeCheckLoading, setCodeCheckLoading] = useState(false);
  const [validCode, setValidCode] = useState(true);
  const [typingTimeout, setTypingTimeout] = useState(0);
  const [createLoading, setCreateLoading] = useState(false);


  /// Controlla se il codice passato come parametro esiste già
  async function checkCode(code: string) {
    const firestore = firebase.firestore();
    const snap = await firestore.collection("discountCodes").where("code", "==", code.toUpperCase()).where("deleted", "==", false).get();
    return snap.empty;
  }

  async function generateCode() {
    let valid = false;
    let code = "";
    setCodeCheckLoading(true);
    while (!valid) {
      code = shortid.generate().toUpperCase();
      valid = await checkCode(code);
    }
    setCodeCheckLoading(false);
    setCode(code.toUpperCase());
    setValidCode(true);
  }


  function setAndCheckCode(code: string) {
    setCode(code.toUpperCase());
    if (typingTimeout)
      clearTimeout(typingTimeout);

    setTypingTimeout(window.setTimeout(async () => {
      setCodeCheckLoading(true);
      setValidCode(await checkCode(code));
      setCodeCheckLoading(false);
    }, 700));
  }

  async function submit(e: FormEvent) {
    e.preventDefault();

    setCreateLoading(true);

    let valid = await checkCode(code);
    if (!valid) {
      setValidCode(false);
      toastr.error("ERRORE!", "Codice già utilizzato!")
      return;
    }

    const firestore = firebase.firestore();

    try {
      // Controllo di aver inserito un'area/fornitore gestito dall'admin loggato
      if (!admin.isMainAdmin) {
        if (codeType === "SINGLE_AREA" && !admin.areaIds!.includes(areaId)) {
          setCreateLoading(false);
          toastr.error("Non sei abilitato a creare sconti per l'area inserita!", "");
          return;
        }
        if (codeType === "SINGLE_SUPPLIER") {
          const supplier = (await firestore.collection("suppliers").doc(supplierId).get()).data() as Supplier | undefined;
          if (supplier === undefined || !admin.areaIds!.includes(supplier.areaId)) {
            setCreateLoading(false);
            toastr.error("Non sei abilitato a creare sconti per il fornitore inserito!", "");
            return;
          }
        }
      }

      let batch = firestore.batch();
      const docRef = firestore.collection("discountCodes").doc();

      batch.set(docRef, {
        id: docRef.id,
        code: code,
        deleted: false,
        active: false,
        type: codeType,
        hasFixedAmount: hasFixedAmount,
        amount: hasFixedAmount ? amount : null,
        percentage: hasFixedAmount ? null : percentage,
        hasFreeDelivery: hasFreeDelivery,
        hasMinOrderAmount: hasMinOrderAmount,
        minOrderAmount: hasMinOrderAmount ? minOrderAmount : null,
        startDate: firebase.firestore.Timestamp.fromDate(moment(startDate, "YYYY-MM-DD").toDate()),
        endDate: hasEndDate ? firebase.firestore.Timestamp.fromDate(moment(endDate, "YYYY-MM-DD").toDate()) : null,
        startTime,
        endTime,
        pointsThreshold: codeType === "REWARD" ? pointsThreshold : null,
        supplierId,
        areaId,
        isPromo,
        promoMessage: promoMessage.trim(),
        promoTitle: promoTitle.trim(),
        isPeriodic,
        weekdayValidity,
      });

      if (codeType === "SINGLE_CUSTOMER") {
        batch.set(docRef.collection("discountCodeCustomers").doc(customerId), {
          customerId: customerId,
          codeId: docRef.id,
          used: false,
        });
      }

      await batch.commit();
      toastr.success("", "Creazione dello sconto avvenuta con successo!");

      props.history.push("/discounts/" + docRef.id);
    } catch (err) {
      console.log(err);
      setCreateLoading(false);
      toastr.error("", "Errore inaspettato!");
    }
  }

  let weekdays = moment.weekdays(true);

  return <div className="container my-4">
    <h4>Creazione sconto</h4>
    <form className="card card-body" onSubmit={submit}>
      <b>Impostazioni sconto</b>
      <div className="form-inline mt-2">
        <div className="form-group">
          <label htmlFor="searchBar">Nome sconto</label>
          <input type="text" id="searchBar" className="form-control mx-sm-3" value={code} onChange={(e) => setAndCheckCode(e.target.value)} required />
          {
            codeCheckLoading ? <div className="small-loader" /> :
              <button className="btn btn-sm btn-outline-secondary" onClick={generateCode}>Genera</button>
          }
        </div>
      </div>
      {!validCode && <div className="small my-2" style={{ color: "red" }}> Il codice inserito è già assegnato a un altro sconto</div>}
      {
        admin.isMainAdmin &&
        <div className="custom-control custom-radio mt-3" onClick={() => setCodeType("ALL_CUSTOMERS")}>
          <input type="radio" id="allCustomers" className="custom-control-input" checked={codeType === "ALL_CUSTOMERS"} />
          <label className="custom-control-label" htmlFor="allCustomers">
            Sconto per tutti i clienti
          </label>
        </div>
      }
      <div className="custom-control custom-radio mt-3" onClick={() => setCodeType("SINGLE_AREA")}>
        <input type="radio" id="singleArea" className="custom-control-input" checked={codeType === "SINGLE_AREA"} />
        <label className="custom-control-label" htmlFor="singleArea">
          Sconto utilizzabile in un&apos;area
          {
            codeType === "SINGLE_AREA" &&
            <div className="mt-2 d-flex align-items-center">
              <div className="mr-2">ID: </div>
              <input className="form-control" style={{ minWidth: 400 }} value={areaId} required onChange={(e) => setAreaId(e.target.value)} disabled={codeType !== "SINGLE_AREA"} />
            </div>
          }
        </label>
      </div>
      <div className="custom-control custom-radio mt-3" onClick={() => setCodeType("SINGLE_SUPPLIER")}>
        <input type="radio" id="singleSupplier" className="custom-control-input" checked={codeType === "SINGLE_SUPPLIER"} />
        <label className="custom-control-label" htmlFor="singleSupplier">
          Sconto utilizzabile in un singolo fornitore
          {
            codeType === "SINGLE_SUPPLIER" &&
            <div className="mt-2 d-flex align-items-center">
              <div className="mr-2">ID: </div>
              <input className="form-control" style={{ minWidth: 400 }} value={supplierId} required onChange={(e) => setSupplierId(e.target.value)} disabled={codeType !== "SINGLE_SUPPLIER"} />
            </div>
          }
        </label>
      </div>
      <div className="custom-control custom-radio mt-3" onClick={() => setCodeType("SINGLE_CUSTOMER")}>
        <input type="radio" id="singleCustomer" className="custom-control-input" checked={codeType === "SINGLE_CUSTOMER"} />
        <label className="custom-control-label" htmlFor="singleCustomer">
          Sconto per un cliente singolo
          {
            codeType === "SINGLE_CUSTOMER" &&
            <div className="mt-2 d-flex align-items-center">
              <div className="mr-2">ID: </div>
              <input className="form-control" style={{ minWidth: 400 }} value={customerId} required onChange={(e) => setCustomerId(e.target.value)} disabled={codeType !== "SINGLE_CUSTOMER"} />
            </div>
          }
        </label>
      </div>
      {
        admin.isMainAdmin &&
        <div className="custom-control custom-radio mt-3" onClick={() => setCodeType("REWARD")}>
          <input type="radio" id="reward" className="custom-control-input" checked={codeType === "REWARD"} />
          <label className="custom-control-label" htmlFor="reward">
            Ricompensa punti
            {
              codeType === "REWARD" &&
              <div className="mt-2 d-flex align-items-center">
                <label style={{ whiteSpace: "nowrap" }} className="my-auto">Punti richiesti</label>
                <input type="number" min="0" step="1" id="pointsThreshold" style={{ minWidth: "200px" }}
                  className="form-control ml-3 " value={pointsThreshold === null ? undefined : pointsThreshold} onChange={(e) => setPointsThreshold(parseInt(e.target.value))} required />
              </div>
            }
          </label>
        </div>
      }
      {
        codeType !== "REWARD" &&
        <>
          <b className="mt-4">Durata validità sconto</b>
          <div className="form-inline mt-2">
            <div className="form-group">
              <label htmlFor="startDate">Data di inizio validità</label>
              <input type="date" id="startDate" className="form-control mx-sm-3" value={startDate} onChange={(e) => setStartDate(e.target.value)} />
            </div>
          </div>
          <div className="align-items-top d-flex mt-2">
            <label htmlFor="endDate" className="mt-2">Data di fine validità</label>
            <div>
              <input type="date" id="endDate" className="form-control mx-sm-3" disabled={!hasEndDate} value={endDate} onChange={(e) => setEndDate(e.target.value)} />
              <div className="custom-control custom-checkbox mt-2 ml-3">
                <input type="checkbox" className="custom-control-input" id="hasEndTime" checked={!hasEndDate} onChange={(e) => setHasEndDate(!e.target.checked)} />
                <label className="custom-control-label" htmlFor="hasEndTime">Nessuna data di fine validità</label>
              </div>
            </div>
          </div>
          <div className="form-inline mt-2">
            <div className="form-group">
              <label htmlFor="startTime">Orario inizio validità</label>
              <input type="time" id="startTime" className="form-control mx-sm-3" value={startTime} onChange={(e) => setStartTime(e.target.value)} />
            </div>
          </div>
          <div className="form-inline mt-2">
            <div className="form-group">
              <label htmlFor="endTime" className="mt-2">Orario fine validità</label>
              <input type="time" id="endTime" className="form-control mx-sm-3" value={endTime} onChange={(e) => setEndTime(e.target.value)} />
            </div>
          </div>
          <div className="custom-control custom-checkbox  mt-3">
            <input type="checkbox" className="custom-control-input" id="isPeriodic" checked={isPeriodic} onChange={(e) => setIsPeriodic(e.target.checked)} />
            <label className="custom-control-label" htmlFor="isPeriodic">Sconto periodico</label>
          </div>
          {
            isPeriodic === true && <>
              <div className="ml-4">
                {[1, 2, 3, 4, 5, 6, 7].map((weekday) => {
                  var index = weekday as WeekdayNumber;
                  let weekdayCapitalized = weekdays[weekday - 1];
                  weekdayCapitalized = weekdayCapitalized.charAt(0).toUpperCase() + weekdayCapitalized.slice(1);
                  return (
                    <div className="custom-control custom-checkbox mt-1" key={"weekday-" + weekday}>
                      <input type="checkbox" className="custom-control-input" id={"weekday-" + weekday} checked={weekdayValidity[index]} onChange={(e) => setWeekdayValidity({
                        ...weekdayValidity,
                        [index]: !weekdayValidity[index],
                      })} />
                      <label className="custom-control-label" htmlFor={"weekday-" + weekday}>{weekdayCapitalized}</label>
                    </div>
                  );
                })}
              </div>
            </>
          }

          <b className="mt-4">Condizioni sconto</b>

          <div className="custom-control custom-checkbox  mt-3">
            <input type="checkbox" className="custom-control-input" id="hasMinOrderAmount" checked={hasMinOrderAmount} onChange={(e) => setHasMinOrderAmount(e.target.checked)} />
            <label className="custom-control-label" htmlFor="hasMinOrderAmount">Lo sconto ha un minimo ammontare per l'ordine</label>
          </div>

          {
            hasMinOrderAmount &&
            <NumberFormat
              decimalScale={2}
              allowNegative={false}
              thousandSeparator={false}
              decimalSeparator=","
              prefix="€"
              fixedDecimalScale={true}
              id="amount"
              style={{ maxWidth: "200px" }}
              className="form-control mt-2 ml-4"
              value={minOrderAmount === null ? undefined : minOrderAmount}
              onValueChange={({ floatValue }) => {
                if (floatValue === undefined)
                  setMinOrderAmount(null);
                else if (floatValue !== amount)
                  setMinOrderAmount(floatValue);
              }} required />
          }
        </>
      }
      <b className="mt-4">Ammontare sconto</b>
      <div className="custom-control custom-radio mt-3" onClick={() => setHasFixedAmount(true)}>
        <input type="radio" id="hasFixedAmount" className="custom-control-input" checked={hasFixedAmount} onChange={(e) => setHasFixedAmount(e.target.checked)} />
        <label className="custom-control-label" htmlFor="hasFixedAmount">
          Sconto fisso (€)
          {
            hasFixedAmount &&
            <NumberFormat
              decimalScale={2}
              allowNegative={false}
              thousandSeparator={false}
              decimalSeparator=","
              prefix="€"
              fixedDecimalScale={true}
              id="amount"
              className="form-control mt-2"
              value={amount === null ? undefined : amount}
              onValueChange={({ floatValue }) => {
                if (floatValue === undefined)
                  setAmount(null);
                else if (floatValue !== amount)
                  setAmount(floatValue);
              }} required />
          }
        </label>
      </div>
      <div className="custom-control custom-radio mt-3" onClick={() => setHasFixedAmount(false)}>
        <input type="radio" id="hasntFixedAmount" className="custom-control-input" checked={!hasFixedAmount} onChange={(e) => setHasFixedAmount(!e.target.checked)} />
        <label className="custom-control-label" htmlFor="hasntFixedAmount">
          Sconto percentuale (%)
          {
            !hasFixedAmount &&
            <NumberFormat
              decimalScale={1}
              allowNegative={false}
              thousandSeparator={false}
              decimalSeparator=","
              suffix="%"
              fixedDecimalScale={true}
              className="form-control mt-2"
              value={percentage === null ? undefined : percentage * 100}
              isAllowed={(values) => {
                const { floatValue } = values;
                if (floatValue === undefined) return true;
                return floatValue > 0 && floatValue <= 100;
              }}
              onValueChange={({ floatValue }) => {
                if (floatValue === undefined)
                  setPercentage(null);
                else if (floatValue !== percentage)
                  setPercentage(floatValue / 100.);
              }} required />
          }
        </label>
      </div>
      <div className="custom-control custom-checkbox  mt-3">
        <input type="checkbox" className="custom-control-input" id="hasFreeDelivery" checked={hasFreeDelivery} onChange={(e) => setHasFreeDelivery(e.target.checked)} />
        <label className="custom-control-label" htmlFor="hasFreeDelivery">Spedizione gratuita</label>
      </div>

      {
        admin.isMainAdmin && codeType !== "REWARD" && codeType !== "SINGLE_CUSTOMER" &&
        <>
          <b className="mt-4">Dettagli promozione</b>
          <div className="custom-control custom-checkbox  mt-3">
            <input type="checkbox" className="custom-control-input" id="isPromo" checked={isPromo} onChange={(e) => setIsPromo(e.target.checked)} />
            <label className="custom-control-label" htmlFor="isPromo">Associa promozione</label>
          </div>
          {
            isPromo && <>
              <div className="form-group mt-2">
                <label>Titolo promozione</label>
                <input type="text" className="form-control" style={{ maxWidth: "300px" }} value={promoTitle} onChange={(e) => setPromoTitle(e.target.value)} required />
              </div>
              <div className="form-group">
                <label>Messaggio promozione</label>
                <textarea className="form-control" style={{ maxWidth: "500px" }} value={promoMessage} onChange={(e) => setPromoMessage(e.target.value)} />
              </div>
            </>
          }
        </>
      }

      <div className="mt-4 d-flex justify-content-end">
        <button className="btn btn-success" type="submit" disabled={createLoading || !validCode}>Crea</button>
      </div>
    </form>
  </div>
}

export default withRouter(DiscountCreateScreen);
