const initialState = {
  serviceWorkerUpdated: false,
  serviceWorkerRegistration: null,
}

const serviceWorkerReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SERVICE_WORKER_UPDATE":
      return {
        ...state,
        serviceWorkerUpdated: !state.serviceWorkerUpdated,
        serviceWorkerRegistration: action.payload,
      };
    default:
      return state;
  }
}

export default serviceWorkerReducer;