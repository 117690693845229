import React from 'react'
import {
    Route,
    Redirect
} from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux'
import { isLoaded, isEmpty, useFirestoreConnect } from 'react-redux-firebase'
import { provideAdminId } from '../../store/actions/authActions';
import SplashScreen from '../../screens/SplashScreen';

function AdminRoute(props) {
    const { children, ...rest } = props;
    const { auth, profile, adminId } = useSelector(state => ({ auth: state.firebase.auth, profile: state.firebase.profile, adminId: state.auth.adminId, }));
    const dispatch = useDispatch();
    useFirestoreConnect(adminId ? {
        collection: "admin",
        doc: adminId,
        storeAs: "currentAdmin",
    } : [])

    const admin = useSelector(state => state.firestore.data.currentAdmin);

    return (
        <Route
            {...rest}
            render={({ location }) => {
                // Il login non è stato eseguito
                if (isEmpty(profile) || isEmpty(auth))
                    return (<Redirect
                        to={{
                            pathname: "/signin",
                            state: { from: location }
                        }}
                    />);

                const isAdmin = profile.token.claims.admin === true;

                // L'utente non è un fornitore
                if (!isAdmin)
                    return (<Redirect
                        to={{
                            pathname: "/forbidden",
                            state: { from: location }
                        }}
                    />);

                const isDisabled = profile.token.claims.disabled === true;

                // Il fornitore è stato disabilitato
                if (isDisabled)
                    return (<Redirect
                        to={{
                            pathname: "/disabled",
                            state: { from: location }
                        }}
                    />);

                // Non è ancora stato fornito allo store l'id del fornitore
                if (adminId === null) {
                    dispatch(provideAdminId(profile.token.claims.adminId));
                    return <SplashScreen />;
                }

                if (!isLoaded(admin)) {
                    return <SplashScreen />;
                }

                return children;
            }
            }
        />
    );
}

export default AdminRoute;