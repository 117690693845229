
import React from 'react';
import { useSelector } from 'react-redux';
import { isEmpty, isLoaded, useFirestoreConnect } from 'react-redux-firebase';
import { Link } from 'react-router-dom';
import Admin from '../../../models/admin';
import User from '../../../models/user';
import { RootState } from '../../../store/reducers/rootReducer';
import SplashScreen from '../../SplashScreen';

type CustomerListProps = {
  searchBar: string;
  areaId?: string;
  showOnlyCustomersInAdminAreas?: boolean;
}

function CustomerList({ searchBar, areaId, showOnlyCustomersInAdminAreas = true }: CustomerListProps) {
  useFirestoreConnect({
    collection: 'users',
    where: areaId ? ["areaIds", "array-contains", areaId] : undefined,
    orderBy: ['email', "asc"],
  });

  let customers: User[] | undefined = useSelector((state: RootState) => state.firestore.ordered['users'])
  const admin: Admin = useSelector((state: RootState) => state.firestore.data.currentAdmin);

  if (!isLoaded(customers)) {
    return <SplashScreen />
  }

  customers = customers!.filter((c) => c.email.toLowerCase().includes(searchBar.toLowerCase().replace(/\s+/g, '')));

  if (showOnlyCustomersInAdminAreas && !admin.isMainAdmin) {
    customers = customers.filter((c) => c.areaIds !== undefined && admin.areaIds!.find((id) => c.areaIds!.includes(id)) !== undefined);
  }

  if (isEmpty(customers))
    return (
      <div className="customerList">
        Nessun cliente in base ai criteri di ricerca.
      </div>
    );

  let customerComponents = customers.map((customer) => {
    return (
      <div className="card my-2" key={customer.uid}>
        <div className="card-body d-flex justify-content-between flex-wrap">
          <Link to={"/customers/" + customer.uid} className="text-dark col-md-4 col-sm-12">
            {customer.email}
          </Link>

          <div>
            <b>ID: </b>{customer.uid}
          </div>
        </div>
      </div>
    )
  });

  let customerCount = customers ? customers.length : 0;

  return (
    <div className="customerList">
      <b>{(customerCount === 0 ? "Nessun cliente corrispondente" : customerCount === 1 ? "Un cliente corrispondente" : (customerCount + " clienti corrispondenti")) + " ai criteri di ricerca"}</b>
      {customerComponents}
    </div>
  )
}

export default CustomerList;

