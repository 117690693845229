import React, { useState } from 'react'
import { Modal, Button } from 'react-bootstrap'
import { toastr } from 'react-redux-toastr';

type ChangeEmailModalProps = {
  show: boolean;
  onCancel: () => void;
  onDone: (email: string) => void
}

function ChangeEmailModal({ show, onCancel, onDone }: ChangeEmailModalProps) {
  const [email, setEmail] = useState("");
  const [emailConfirm, setEmailConfirm] = useState("");

  return (
    <Modal show={show} onHide={onCancel}>
      <Modal.Header closeButton>
        <Modal.Title>Reimpostazione dell&apos;email</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="form-group">
          <label htmlFor="email" className="required">Email</label>
          <input className="form-control" id="email" value={email} required onChange={(e) => setEmail(e.target.value)} />
        </div>
        <div className="form-group">
          <label htmlFor="emailConfirm" className="required">Conferma email</label>
          <input className="form-control" id="emailConfirm" value={emailConfirm} required onChange={(e) => setEmailConfirm(e.target.value)} />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" type="button" onClick={onCancel}>
          Annulla
        </Button>
        <Button variant="success" type="button" onClick={() => {
          if (email !== emailConfirm) {
            toastr.error("Le email inserite non corrispondono!", "");
            return;
          }
          onDone(email);
        }}>
          Conferma
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default ChangeEmailModal;
