import React, { useEffect, useState } from 'react'
import { Modal, Button } from 'react-bootstrap'
import firebase from 'firebase/app'
import { toastr } from 'react-redux-toastr';

export type PrintDeviceType = "NONE" | "POS" | "PRINTER" | "NEW_POS";

type ChangePrintDeviceModalProps = {
  initialValue: PrintDeviceType;
  initialPrinterName: string;
  show: boolean;
  close: () => void;
  supplierAdminId: string;
}

function ChangePrintDeviceModal({ initialValue, initialPrinterName, show, close, supplierAdminId }: ChangePrintDeviceModalProps) {
  const [device, setDevice] = useState(initialValue);
  const [printerName, setPrinterName] = useState(initialPrinterName);

  useEffect(() => {
    if (show) {
      setDevice(initialValue);
      setPrinterName(initialPrinterName);
    }
  }, [show])

  async function save() {
    close();
    const firestore = firebase.firestore();

    try {
      let printerName = null;
      if (device === "POS")
        printerName = "IposPrinter";
      else if (device === "NEW_POS")
        printerName = "InnerPrinter";
      else if (device === "PRINTER")
        printerName = printerName;

      await firestore.collection("supplier_admin").doc(supplierAdminId).update({
        printEnabled: device !== "NONE",
        printerName: printerName,
        useNewPosPrinter: device === "NEW_POS",
      });

      toastr.success("Dispositivo in dotazione cambiato con successo!", "");
    }
    catch (err) {
      toastr.error("Errore inaspettato!", "");
      console.log(err);
    }
  }

  return (
    <Modal show={show} onHide={close}>
      <Modal.Header closeButton>
        <Modal.Title>Modifica dispositivo</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="form-group">
          <label className="required">Dispositivo in dotazione</label>
          <select className="form-control mr-3" onChange={(e) => setDevice(e.target.value as PrintDeviceType)} value={device}>
            <option value="NONE">Nessuno</option>
            <option value="POS">Palmare</option>
            <option value="NEW_POS">Nuovo Palmare</option>
            <option value="PRINTER">Stampante</option>
          </select>
          {
            device === "PRINTER" &&
            <div className="form-group mt-3">
              <label htmlFor="printerName">Nome stampante</label>
              <input type="text" id="printerName" className="form-control" value={printerName} onChange={(e) => setPrinterName(e.target.value)} />
            </div>
          }
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" type="button" onClick={close}>
          Annulla
        </Button>
        <Button variant="success" type="button" onClick={save}>
          Salva
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default ChangePrintDeviceModal;
