import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import CategoryForm from './components/CategoryForm';
import firebase from '../../config/fbConfig'
import { toastr } from 'react-redux-toastr';
import shortid from 'shortid';

class SupplierCreateCategoryScreen extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isSaving: false,
    }
  }

  onSave = async (data) => {
    this.setState({
      isSaving: true
    });

    const firestore = firebase.firestore();
    const storage = firebase.storage();
    let categoryId = shortid.generate();

    const documentRef = firestore.collection('supplier_categories').doc(categoryId);
    const storageRef = storage.ref().child('supplier_categories/' + categoryId);

    try {
      let lastCategory = (await firestore.collection('supplier_categories').orderBy("index", "desc").limit(1).get()).docs;

      let index = 0;
      if (lastCategory.length === 1) {
        index = lastCategory[0].get("index") + 1;
      }

      await storageRef.child(data.imageFile.name).put(data.imageFile);
      let imageUrl = await storageRef.child(data.imageFile.name).getDownloadURL();

      await documentRef.set({
        name: data.name,
        tags: data.tags,
        id: categoryId,
        imageUrl: imageUrl,
        index: index,
      });

      toastr.success("Categoria aggiunta con successo!");
      this.props.history.push("/suppliers");
    }
    catch (err) {
      toastr.error(`Errore inaspettato!`);
      this.setState({
        isSaving: false,
      })
      console.log(err);
    }
  }

  render() {
    return <div className="container my-4">
      <CategoryForm mode="create" onSave={this.onSave} isSaving={this.state.isSaving} />
    </div>;
  }
}

export default withRouter(SupplierCreateCategoryScreen);