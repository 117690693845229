import React from 'react'

export default function UserPreview(props) {
  return (
    <div>
      {
        props.imageUrl !== undefined &&
        <img src={props.imageUrl} className="rounded mr-2 my-1" style={{ maxWidth: "200px" }}></img>
      }
      {
        props.name !== undefined &&
        <div><i className="fas fa-user mr-2" /> {props.name}</div>
      }
      {
        props.phoneNumber !== undefined &&
        <div><i className="fas fa-phone mr-2" /> {props.phoneNumber}</div>
      }
      {
        props.child !== undefined &&
        props.child
      }
    </div>
  );
}