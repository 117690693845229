import { toastr } from "react-redux-toastr";

export const updateRequestState = (requestId, state, reason = "") => {
  return async (dispatch, getState, { getFirebase }) => {
    let firebase = getFirebase();
    let firestore = firebase.firestore();
    let documentRef = firestore.collection("supplier_requests").doc(requestId);
    try {
      await documentRef.update({ 'state': state, 'reason': reason });

      if (state === "ACCEPTED")
        toastr.success('Richiesta accettata con successo!');
      else if (state === "REFUSED")
        toastr.success('Richiesta rifiutata con successo!');
    }
    catch (err) {
      toastr.error(`Errore inaspettato!`);
      console.log(err);
    }
  }
};

export const toggleDisabledSupplier = (supplierId, disabled) => {
  return async (dispatch, getState, { getFirebase }) => {
    const firebase = getFirebase();
    const functions = firebase.functions();

    try {
      const toggleDisabledSupplier = functions.httpsCallable("toggleDisabledSupplier");
      const response = await toggleDisabledSupplier({ supplierId, disabled });
      if (response.data.success === false) {
        toastr.error("Errore: " + response.data.error)
      }
      else {
        toastr.success("Fornitore " + (disabled ? "disabilitato" : "abilitato") + " con successo")
      }
    }
    catch (err) {
      console.log(err);
      toastr.error("Errore inaspettato!");
    }
  }
};


export const editSupplierField = (supplierId, field, data) => {
  return async (dispatch, getState, { getFirebase }) => {
    const firebase = getFirebase();
    let firestore = firebase.firestore();
    let documentRef = firestore.collection("suppliers").doc(supplierId);

    try {
      await documentRef.update({ [field]: data });
    }
    catch (err) {
      console.log(err);
      toastr.error("Errore inaspettato!");
    }
  }
};

export const changeCategoryIndexes = (categories) => {
  return async (dispatch, getState, { getFirebase }) => {
    const firebase = getFirebase();
    let firestore = firebase.firestore();

    try {
      let batch = firestore.batch();

      for (let i = 0; i < categories.length; i++) {
        batch.update(firestore.collection('supplier_categories').doc(categories[i].id), { index: categories[i].index });
      }

      await batch.commit();
    }
    catch (err) {
      console.log(err);
      toastr.error("Errore inaspettato!");
    }
  }
};