import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { firestoreConnect, isLoaded, isEmpty } from 'react-redux-firebase';
import SupplierList from './components/SupplierList';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom'
import { changeCategoryIndexes } from '../../store/actions/supplierActions';
import SplashScreen from '../SplashScreen';

class SupplierAreaScreen extends Component {

    render() {
        const { area, admin } = this.props;
        if (!isLoaded(area))
            return <div />;

        if (!admin.isMainAdmin && !admin.areaIds.includes(area.id))
            return <div className="container my-4">Non hai accesso alla visualizzazione di questa pagina.</div>;

        return (
            <div className="my-4 container">
                <h3>Area &quot;{area.name}&quot;</h3>
                <CategorySelect areaId={this.props.match.params.areaId} />
                <SupplierList areaId={this.props.match.params.areaId} />
            </div>
        );
    }
}



const mapStateToProps = (state) => {
    return {
        area: state.firestore.data.area,
        admin: state.firestore.data.currentAdmin,
    }
}

export default withRouter(compose(
    connect(mapStateToProps),
    firestoreConnect((props) => [
        {
            collection: 'areas',
            doc: props.match.params.areaId,
            storeAs: 'area',
        },
    ]))(SupplierAreaScreen));


class Category extends Component {
    render() {
        let { category, supplierCount, url } = this.props;

        let content;

        content = (
            <div>
                <Link to={url}>
                    {category.name}
                </Link>
                {
                    supplierCount !== null &&
                    (<div style={{ color: "#777777", fontSize: 12 }}>
                        {supplierCount === 0 ? "Nessun fornitore" : supplierCount === 1 ? "1 fornitore" : (supplierCount + " fornitori")}
                    </div>)
                }
            </div>);

        return (
            <div className="col-12 col-md-6 col-lg-4 my-1 px-2">
                <div className="card card-body py-2">
                    <div className="d-flex">
                        <div className="d-flex my-auto">
                            {content}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}


class _CategorySelect extends Component {
    render() {
        let { supplierCategories, suppliers } = this.props;

        if (!isLoaded(supplierCategories))
            return <SplashScreen />

        let supplierCategoriesSorted = supplierCategories.slice();
        supplierCategoriesSorted.sort((c1, c2) => c1.index - c2.index);

        return (
            <div className="my-4">
                <h4 className="my-auto">Categorie</h4>
                <div className="mt-3 row">
                    {supplierCategoriesSorted.map((category) => {
                        let supplierCount = null;
                        if (isLoaded(supplierCount)) {
                            if (isEmpty(suppliers))
                                supplierCount = 0;
                            else
                                supplierCount = suppliers.reduce((count, s) => s.category === category.id ? count + 1 : count, 0);
                        }
                        return <Category key={category.id} category={category} supplierCount={supplierCount} url={"/suppliers/area/" + this.props.areaId + "/category/" + category.id} />
                    })}
                </div>
            </div>
        )
    }
}

const categoryMapStateToProps = (state) => {
    return {
        supplierCategories: state.firestore.ordered['supplier_categories'],
        suppliers: state.firestore.ordered['suppliers'],
    }
}

const categoryMapDispatchToProps = (dispatch) => {
    return {
        changeCategoryIndexes: (categories) => dispatch(changeCategoryIndexes(categories)),
    }
}


const CategorySelect = compose(
    connect(categoryMapStateToProps),
    firestoreConnect((props) => {
        return [
            {
                collection: 'suppliers',
                where: ['areaId', '==', props.areaId],
                orderBy: [['name', 'asc']],
            },
            {
                collection: 'supplier_categories',
            },
        ];
    }),
    connect(null, categoryMapDispatchToProps)
)(_CategorySelect)