import React, { Component, useEffect, useState } from 'react'
import { Modal, Button } from 'react-bootstrap'
import OrderStates from '../../models/order_states';

type ChangeStateButtonProps = {
  disabled: boolean;
  initialState: OrderStates;
  onConfirm: (newState: OrderStates) => void
}

function ChangeStateButton({ disabled, initialState, onConfirm }: ChangeStateButtonProps) {
  const [showModal, setShowModal] = useState(false);
  const [state, setState] = useState(initialState);

  useEffect(() => {
    if (showModal) {
      setState(initialState);
    }
  }, [showModal])


  return (
    <div>
      <button className="btn btn-outline-warning" onClick={() => setShowModal(true)} disabled={disabled}>Cambia stato</button>
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <form onSubmit={(e) => {
          e.preventDefault();
          onConfirm(state);
          setShowModal(false);
        }}>
          <Modal.Header closeButton>
            <Modal.Title>{"Stato dell'ordine"}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="form-group">
              <label className="required">Nuovo stato dell&apos;ordine</label>
              <select className="form-control" id="orderState" value={state} required onChange={(e) => setState(e.target.value as OrderStates)}>
                <option value={"NEW"}>Nuovo</option>
                <option value={"ACCEPTED"}>Accettato</option>
                <option value={"REFUSED"}>Rifiutato</option>
                <option value={"CANCELLED"}>Annullato</option>
                <option value={"READY"}>Pronto</option>
                <option value={"PICKED_UP"}>Ritirato dal fattorino</option>
                <option value={"DELIVERED"}>Consegnato al cliente</option>
              </select>
            </div>
            <p>Sicuro di voler cambiare stato all&apos;ordine?</p>
            <div className="custom-control custom-checkbox">
              <input type="checkbox" className="custom-control-input" id="confirm" required />
              <label className="custom-control-label" htmlFor="confirm">Sono consapevole di quello che sto facendo.</label>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" type="button" onClick={() => setShowModal(false)} >
              Annulla
            </Button>
            <Button variant="primary" type="submit">
              Cambia stato
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </div>
  )
}

export default ChangeStateButton