import { toastr } from "react-redux-toastr";

export const updateSettings = (settings) => {
  return async (dispatch, getState, { getFirebase }) => {
    const firebase = getFirebase();

    const firestore = firebase.firestore();

    const documentRef = firestore.collection('settings').doc('settings');

    try {

      await documentRef.set({
        driverAmount: settings.driverAmount,
        supplierPercentage: settings.supplierPercentage,
        supplierRadius: settings.supplierRadius,
        freeDeliveryThreshold: settings.freeDeliveryThreshold,
        longDistanceThreshold: settings.longDistanceThreshold,
        longDistanceThreshold2: settings.longDistanceThreshold2,
        longDistanceDriverAmount: settings.longDistanceDriverAmount,
        longDistanceDriverAmount2: settings.longDistanceDriverAmount2,
        paypal: settings.paypal,
      }, { merge: true });

      toastr.success("Impostazioni aggiornate con successo!");
    }
    catch (err) {
      toastr.error(`Errore inaspettato!`);
      console.log(err);
    }
  }
};