import React, { Component } from 'react'
import { connect } from 'react-redux';
import { firestoreConnect, isLoaded } from 'react-redux-firebase'
import { compose } from 'redux'
import firebase from 'firebase/app'
import moment from 'moment';
import ShiftComponent from './ShiftComponent';
import SplashScreen from '../../../SplashScreen';

class ShiftsList extends Component {
  render() {
    let { shifts, shiftLength } = this.props;

    if (!isLoaded(shifts)) {
      return <SplashScreen />
    }

    let start = moment(this.props.selectedDate, "YYYY-MM-DD");
    let end = moment(this.props.selectedDate, "YYYY-MM-DD").add(24, "hours");

    let startTimes = [];
    for (let curr = start; curr.isBefore(end); curr = curr.add(shiftLength, "minutes")) {
      startTimes.push(curr.clone());
    }

    return (
      <div className="shiftList my-2">
        {startTimes.map((startTime) =>
          <ShiftComponent key={startTime.format("HH:mm")} startTime={startTime} shift={shifts?.[startTime.valueOf().toString()]} alsoEmpty={this.props.alsoEmpty} areaId={this.props.areaId} shiftLength={shiftLength} />
        )}
        {!this.props.alsoEmpty && (!shifts || shifts.length === 0) &&
          <div>Nessuna prenotazione per oggi.</div>
        }
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    shifts: state.firestore.data['shifts'],
  }
}

export default compose(
  connect(mapStateToProps, null),
  firestoreConnect((props) => {
    let lowDate = moment(props.selectedDate, "YYYY-MM-DD");
    let highDate = moment(props.selectedDate, "YYYY-MM-DD").add(1, "day");

    let lowTimestamp = firebase.firestore.Timestamp.fromDate(lowDate.toDate());
    let highTimestamp = firebase.firestore.Timestamp.fromDate(highDate.toDate());
    return [
      {
        collection: 'areas',
        doc: props.areaId,
        subcollections: [{
          collection: 'shifts',
          where: [
            ['startTime', '>=', lowTimestamp],
            ['startTime', '<', highTimestamp]
          ],
          orderBy: 'startTime',
        }],
        storeAs: 'shifts',
      },
    ];
  })
)(ShiftsList)