import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/storage'
import 'firebase/auth'
import 'firebase/functions'

let firebaseConfig;

// eslint-disable-next-line no-undef
if (process.env.REACT_APP_ENV === "production")
  firebaseConfig = {
    apiKey: "AIzaSyA7-StQ6t1T2XETxrQhv2MzwsxpepWckas",
    authDomain: "taporty-779ff.firebaseapp.com",
    databaseURL: "https://taporty-779ff.firebaseio.com",
    projectId: "taporty-779ff",
    storageBucket: "taporty-779ff.appspot.com",
    messagingSenderId: "84538476825",
    appId: "1:84538476825:web:72f5c6511eb98fa239dd88"
  }
else {
  firebaseConfig = {
    apiKey: "AIzaSyA9-TnYoAhhvsOUh6XSj3SXLFhIHa6aDJ4",
    authDomain: "taporty-dev.firebaseapp.com",
    databaseURL: "https://taporty-dev.firebaseio.com",
    projectId: "taporty-dev",
    storageBucket: "taporty-dev.appspot.com",
    messagingSenderId: "477511381697",
    appId: "1:477511381697:web:3b6ce45b6d2b0c7b8e5a40",
    measurementId: "G-3KX4SR14BG"
  };
}

// Initialize Firebase
firebase.initializeApp(firebaseConfig);
firebase.firestore();
firebase.storage();
//app.functions().useFunctionsEmulator("http://localhost:5000"); // TODO: Rimuovere quando lo carichi in Production

export default firebase;