import React, { FormEvent } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { useState } from 'react';
import firebase from '../../config/fbConfig'
import moment from 'moment';
import geohash from 'ngeohash';
import NumberFormat from 'react-number-format';
import { toastr } from 'react-redux-toastr';
import Area from '../../models/area';

type AreaEditScreenState = {
  area: Area;
}

function AreaEditScreen(props: RouteComponentProps<{}, any, AreaEditScreenState>) {
  const area = props.location.state?.area;

  const [name, setName] = useState(area?.name ? area.name : "");
  const [radius, setRadius] = useState(area?.radius ? area.radius : 7);
  const [shiftLength, setShiftLength] = useState(area?.shiftLength ? area.shiftLength : 15);
  const [supplierRadius, setSupplierRadius] = useState(area?.supplierRadius ? area.supplierRadius : 5);
  const [maxTravelDistance, setMaxTravelDistance] = useState(area?.maxTravelDistance ? area.maxTravelDistance : 4);
  const [maxTravelDuration, setMaxTravelDuration] = useState(area?.maxTravelDuration ? area.maxTravelDuration : 10);
  const [latitude, setLatitude] = useState<number | undefined>(area?.geohashPoint ? area.geohashPoint.geopoint.latitude : 40.0);
  const [longitude, setLongitude] = useState<number | undefined>(area?.geohashPoint ? area.geohashPoint.geopoint.longitude : 12.0);
  const [longDistanceThreshold, setLongDistanceThreshold] = useState<number | undefined>(area?.longDistanceThreshold ? area.longDistanceThreshold : 5.1);
  const [longDistanceThreshold2, setLongDistanceThreshold2] = useState<number | undefined>(area?.longDistanceThreshold2 ? area.longDistanceThreshold2 : 6.3);
  const [deliveryAmount, setDeliveryAmount] = useState<number | undefined>(area?.deliveryAmount ? area.deliveryAmount : 2.9);
  const [longDistanceDeliveryAmount, setLongDistanceDeliveryAmount] = useState<number | undefined>(area?.longDistanceDeliveryAmount ? area.longDistanceDeliveryAmount : 3.9);
  const [longDistanceDeliveryAmount2, setLongDistanceDeliveryAmount2] = useState<number | undefined>(area?.longDistanceDeliveryAmount2 ? area.longDistanceDeliveryAmount2 : 3.9);
  const [driverAmount, setDriverAmount] = useState<number | undefined>(area?.driverAmount ? area.driverAmount : 3.20);
  const [longDistanceDriverAmount, setLongDistanceDriverAmount] = useState<number | undefined>(area?.longDistanceDriverAmount ? area.longDistanceDriverAmount : 3.20);
  const [longDistanceDriverAmount2, setLongDistanceDriverAmount2] = useState<number | undefined>(area?.longDistanceDriverAmount2 ? area.longDistanceDriverAmount2 : 3.20);
  const [cashPaymentAvailable, setCashPaymentAvailable] = useState(area?.cashPaymentAvailable !== undefined ? area.cashPaymentAvailable : false);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  async function onSubmit(e: FormEvent) {
    e.preventDefault();

    try {
      setLoading(true);
      setError("");
      const firestore = firebase.firestore();
      let hash = geohash.encode(latitude!, longitude!);
      let ref;
      if (area === undefined)
        ref = firestore.collection("areas").doc();
      else
        ref = firestore.collection("areas").doc(area.id);
      let maxDriverCount;
      if (area === undefined) {
        let map = {};
        let start = moment("00:00", "HH:mm");
        let end = moment("00:00", "HH:mm").add(24, "hours");

        for (let curr = start.clone(); curr.isBefore(end); curr.add(shiftLength, "minutes")) {
          map = {
            ...map,
            [curr.format("HH:mm")]: -1,
          };
        }
        maxDriverCount = {
          1: map,
          2: map,
          3: map,
          4: map,
          5: map,
          6: map,
          7: map,
        }
      }
      else {
        maxDriverCount = area.maxDriverCount;
      }

      const batch = firestore.batch();

      batch.set(ref, {
        g: {
          geohash: hash,
          geopoint: new firebase.firestore.GeoPoint(latitude!, longitude!)
        },
        geohashPoint: {
          geohash: hash,
          geopoint: new firebase.firestore.GeoPoint(latitude!, longitude!)
        },
        id: ref.id,
        name: name,
        radius: radius,
        maxDriverCount: maxDriverCount,
        shiftLength: shiftLength,
        deliveryAmount,
        longDistanceDeliveryAmount,
        longDistanceDeliveryAmount2,
        supplierRadius,
        maxTravelDistance,
        maxTravelDuration,
        driverAmount,
        longDistanceDriverAmount,
        longDistanceDriverAmount2,
        cashPaymentAvailable,
        longDistanceThreshold,
        longDistanceThreshold2,
      }, { merge: true });

      if (area !== undefined && area !== null) {
        const changedDeliveryAmounts = {
          ...(area.deliveryAmount !== deliveryAmount && { deliveryAmount }),
          ...(area.longDistanceDeliveryAmount !== longDistanceDeliveryAmount && { longDistanceDeliveryAmount }),
          ...(area.longDistanceDeliveryAmount2 !== longDistanceDeliveryAmount2 && { longDistanceDeliveryAmount2 }),
        }

        // Se si sta modificando un'area preesistente, imposta il costo di spedizione in tutti i fornitori in essa
        // solo se la spesa di spedizione è effettivamente cambiata
        if (area.deliveryAmount !== deliveryAmount ||
          area.longDistanceDeliveryAmount !== longDistanceDeliveryAmount || area.longDistanceDeliveryAmount2 !== longDistanceDeliveryAmount2) {
          const suppliersSnap = await firestore.collection("suppliers").where("areaId", "==", area.id).get();

          for (let doc of suppliersSnap.docs) {
            batch.update(doc.ref, changedDeliveryAmounts);
          }
        }

      }

      await batch.commit();

      setLoading(false);

      if (area === undefined)
        toastr.success("Area creata con successo!", "");
      else
        toastr.success("Area modificata con successo!", "");

      props.history.push("/areas");
    }
    catch (err) {
      console.log(err);
      setError("Errore inaspettato.");
      setLoading(false);
    }
  }

  return <div className="container my-4">
    <form onSubmit={onSubmit}>
      <h4>{area === undefined ? "Creazione area" : "Modifica area"}</h4>
      <div className="card card-body">
        <div className="form-inline" style={{ maxWidth: "700px" }}>
          <label className="required"><b>Nome</b></label>
          <input type="text" id="name" style={{ minWidth: "400px" }} className="form-control ml-3" value={name} onChange={(e) => setName(e.target.value)} required />
        </div>
        {
          area !== undefined &&
          <div className="form-inline mt-3" style={{ maxWidth: "700px" }}>
            <label><b>ID Area</b></label>
            <div className="d-flex align-items-center">
              <input type="text" id="name" style={{ minWidth: "400px" }} className="form-control ml-3" value={area.id} disabled />
              <button type="button" className="btn btn-info ml-2" onClick={() => {
                navigator.clipboard.writeText(area.id);
                toastr.info("ID dell'area copiato con successo!", "");
              }} ><i className="fas fa-copy"></i></button>
            </div>

          </div>
        }
        <div className="mt-3"><label className="required"><b>Informazioni del centro dell&apos;area</b></label></div>
        <div className="row mt-2">
          <div className="form-group col-12 col-md-6">
            <label htmlFor="latitude">Latitudine</label>
            <NumberFormat
              decimalScale={6}
              allowNegative={false}
              thousandSeparator={false}
              decimalSeparator=","
              fixedDecimalScale={true}
              id="latitude"
              className="form-control"
              value={latitude}
              onValueChange={({ floatValue }) => {
                if (floatValue !== latitude) {
                  setLatitude(floatValue);
                }
              }} required />
          </div>
          <div className="form-group col-12 col-md-6">
            <label htmlFor="longitude">Longitudine</label>
            <NumberFormat
              decimalScale={6}
              allowNegative={false}
              thousandSeparator={false}
              decimalSeparator=","
              fixedDecimalScale={true}
              id="longitude"
              className="form-control"
              value={longitude}
              onValueChange={({ floatValue }) => {
                if (floatValue !== longitude)
                  setLongitude(floatValue);
              }} required />
          </div>
        </div>
        <div className="form-inline" style={{ maxWidth: "700px" }}>
          <label className="required"><b>Raggio dell&apos;area</b></label>
          <input type="number" min="0" max="100" step="0.1" id="radius" style={{ minWidth: "400px" }}
            className="form-control ml-3" value={radius} onChange={(e) => setRadius(parseFloat(e.target.value))} required />
        </div>
        <div className="form-inline mt-3">
          <label className="required"><b>Lunghezza turno</b></label>
          <select className="form-control ml-3" id="shiftLength" value={shiftLength} required onChange={(e) => setShiftLength(parseInt(e.target.value))}
            disabled={area !== undefined}>
            <option value="10">10 minuti</option>
            <option value="15">15 minuti</option>
            <option value="20">20 minuti</option>
            <option value="30">30 minuti</option>
          </select>
        </div>
        <div className="form-inline mt-3" >
          <label className="required"><b>Raggio fornitori (km)</b></label>
          <input type="number" min="0" max="100" step="0.1" id="supplierRadius" style={{ minWidth: "400px" }}
            className="form-control ml-3" value={supplierRadius} onChange={(e) => setSupplierRadius(parseFloat(e.target.value))} required />
        </div>
        <div className="custom-control custom-checkbox  mt-3">
          <input type="checkbox" className="custom-control-input" id="cashPaymentAvailable" checked={cashPaymentAvailable} onChange={(e) => setCashPaymentAvailable(e.target.checked)} />
          <label className="custom-control-label" htmlFor="cashPaymentAvailable"><strong>Abilita pagamenti con contanti</strong></label>
        </div>
        <div className="form-inline mt-3" >
          <label className="required"><b>Distanza di consegna massima (km)</b></label>
          <input type="number" min="0" max="100" step="0.1" id="maxTravelDistance" style={{ minWidth: "400px" }}
            className="form-control ml-3" value={maxTravelDistance} onChange={(e) => setMaxTravelDistance(parseFloat(e.target.value))} required />
        </div>
        <div className="form-inline mt-3">
          <label className="required"><b>Durata di consegna massima (min)</b></label>
          <input type="number" min="0" max="100" step="1" id="maxTravelDuration" style={{ minWidth: "400px" }}
            className="form-control ml-3" value={maxTravelDuration} onChange={(e) => setMaxTravelDuration(parseInt(e.target.value))} required />
        </div>
        <div className="form-inline mt-3">
          <label className="required" htmlFor="longDistanceThreshold"><b>Prima soglia aumento spedizione e compenso fattorino (km)</b></label>
          <input className="form-control ml-3" min="0" max="100" type="number" step="0.1" style={{ minWidth: "400px" }}
            id="longDistanceThreshold" value={longDistanceThreshold} required onChange={(e) => setLongDistanceThreshold(parseFloat(e.target.value))} />
        </div>
        <div className="form-inline mt-3">
          <label className="required" htmlFor="longDistanceThreshold2"><b>Seconda soglia aumento spedizione e compenso fattorino (km)</b></label>
          <input className="form-control ml-3" min="0" max="100" type="number" step="0.1" style={{ minWidth: "400px" }}
            id="longDistanceThreshold2" value={longDistanceThreshold2} required onChange={(e) => setLongDistanceThreshold2(parseFloat(e.target.value))} />
        </div>
        <div className="form-inline mt-3">
          <label htmlFor="deliveryAmount" className="required"><b>Costo spedizione (€)</b></label>
          <NumberFormat
            decimalScale={2}
            allowNegative={false}
            thousandSeparator={false}
            decimalSeparator=","
            prefix="€"
            fixedDecimalScale={true}
            id="deliveryAmount"
            className="form-control ml-3"
            value={deliveryAmount}
            onValueChange={({ floatValue }) => {
              if (floatValue !== deliveryAmount)
                setDeliveryAmount(floatValue);
            }} required />
        </div>
        <div className="mt-2" style={{ fontSize: "80%", color: "grey" }}>
          <i className="fas fa-info-circle mr-2"></i>
          Modificando il costo di spedizione dell&apos;area, resetterai i costi di spedizione di tutti i fornitori presenti in essa
        </div>
        <div className="form-inline mt-3">
          <label htmlFor="longDistanceDeliveryAmount" className="required"><b>Costo spedizione sopra la prima soglia (€)</b></label>
          <NumberFormat
            decimalScale={2}
            allowNegative={false}
            thousandSeparator={false}
            decimalSeparator=","
            prefix="€"
            fixedDecimalScale={true}
            id="longDistanceDeliveryAmount"
            className="form-control ml-3"
            value={longDistanceDeliveryAmount}
            onValueChange={({ floatValue }) => {
              if (floatValue !== longDistanceDeliveryAmount)
                setLongDistanceDeliveryAmount(floatValue);
            }} required />
        </div>
        <div className="mt-2" style={{ fontSize: "80%", color: "grey" }}>
          <i className="fas fa-info-circle mr-2"></i>
          Modificando il costo di spedizione sopra la soglia dell&apos;area, resetterai i costi di spedizione di tutti i fornitori presenti in essa
        </div>
        <div className="form-inline mt-3">
          <label htmlFor="longDistanceDeliveryAmount2" className="required"><b>Costo spedizione sopra la seconda soglia (€)</b></label>
          <NumberFormat
            decimalScale={2}
            allowNegative={false}
            thousandSeparator={false}
            decimalSeparator=","
            prefix="€"
            fixedDecimalScale={true}
            id="longDistanceDeliveryAmount2"
            className="form-control ml-3"
            value={longDistanceDeliveryAmount2}
            onValueChange={({ floatValue }) => {
              if (floatValue !== longDistanceDeliveryAmount2)
                setLongDistanceDeliveryAmount2(floatValue);
            }} required />
        </div>
        <div className="mt-2" style={{ fontSize: "80%", color: "grey" }}>
          <i className="fas fa-info-circle mr-2"></i>
          Modificando il costo di spedizione sopra la soglia dell&apos;area, resetterai i costi di spedizione di tutti i fornitori presenti in essa
        </div>

        <div className="form-inline mt-3">
          <label htmlFor="driverAmount" className="required"><b>Ammontare fattorino per ordine (€)</b></label>
          <NumberFormat
            decimalScale={2}
            allowNegative={false}
            thousandSeparator={false}
            decimalSeparator=","
            prefix="€"
            fixedDecimalScale={true}
            id="driverAmount"
            className="form-control ml-3"
            value={driverAmount}
            onValueChange={({ floatValue }) => {
              if (floatValue !== driverAmount)
                setDriverAmount(floatValue);
            }} required />
        </div>
        <div className="form-inline mt-3">
          <label htmlFor="longDistanceDriverAmount" className="required"><b>Ammontare fattorino per ordine sopra la prima soglia (€)</b></label>
          <NumberFormat
            decimalScale={2}
            allowNegative={false}
            thousandSeparator={false}
            decimalSeparator=","
            prefix="€"
            fixedDecimalScale={true}
            id="longDistanceDriverAmount"
            className="form-control ml-3"
            value={longDistanceDriverAmount}
            onValueChange={({ floatValue }) => {
              if (floatValue !== longDistanceDriverAmount)
                setLongDistanceDriverAmount(floatValue);
            }} required />
        </div>
        <div className="form-inline mt-3">
          <label htmlFor="longDistanceDriverAmount" className="required"><b>Ammontare fattorino per ordine sopra la seconda soglia (€)</b></label>
          <NumberFormat
            decimalScale={2}
            allowNegative={false}
            thousandSeparator={false}
            decimalSeparator=","
            prefix="€"
            fixedDecimalScale={true}
            id="longDistanceDriverAmount2"
            className="form-control ml-3"
            value={longDistanceDriverAmount2}
            onValueChange={({ floatValue }) => {
              if (floatValue !== longDistanceDriverAmount2)
                setLongDistanceDriverAmount2(floatValue);
            }} required />
        </div>
      </div>

      <div className="d-flex justify-content-between mt-4">
        <div className="text-danger">{error}</div>
        <button className="btn btn-success" type="submit" disabled={loading}>
          Salva
        </button>
      </div>
    </form>
  </div>
}

export default withRouter(AreaEditScreen);