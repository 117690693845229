import React from "react";
import { Link } from "react-router-dom";
import { intToCurrencyWithSign } from "../../../helpers/currencyHelper";
import { getDateString } from "../../../helpers/dateHelper";
import Movement from "../../../models/movement";

type MovementViewProps = {
  movement: Movement;
  action?: JSX.Element;
}

function MovementView({ movement, action }: MovementViewProps) {
  let typeComponent: JSX.Element;

  switch (movement.type) {
    case "SUPPLIER_ORDER":
    case "DRIVER_ORDER":
      typeComponent = <div>
        Incasso ordine
        {movement.orderId && <Link to={"/orders/" + movement.orderId!}> #{movement.orderId}</Link>}
      </div>;
      break;
    case "BONUS":
      typeComponent = <div>Bonus</div>;
      break;
    case "CASH":
      typeComponent = <div>
        Soldi alla mano
        {movement.orderId && <Link to={"/orders/" + movement.orderId!}> #{movement.orderId}</Link>}
      </div>;
      break;
    case "CASH_RECEIVED":
      typeComponent = <div>Soldi alla mano ricevuti</div>;
      break;
    case "OTHER":
      typeComponent = <div>Altre entrate</div>;
      break;
  }

  let amountComponent: JSX.Element;
  switch (movement.type) {
    case "CASH":
      amountComponent = <div style={{ fontSize: 18 }}>{intToCurrencyWithSign(movement.amount)}</div>;
      break;
    case "SUPPLIER_ORDER":
      amountComponent = <div style={{ fontSize: 18, color: "green" }}>{intToCurrencyWithSign((1.22 * movement.supplierPercentage! - 0.22) * movement.amount)}</div>;
      break;
    default:
      amountComponent = <div style={{ fontSize: 18, color: "green" }}>{intToCurrencyWithSign(movement.amount)}</div>;
      break;
  }

  return <div className="card card-body my-2">
    <div className="d-flex align-items-center">
      <div className="flex-grow-1">
        <div className="row mr-2">
          <div className="col-lg-2 col-6">{getDateString(movement.date)}</div>
          <div className="col-lg-2 col-6">{amountComponent}</div>
          <div className="col-lg-4 col-12">
            {typeComponent}
          </div>
        </div>
      </div>
      <div className="mr-1" style={{ textAlign: "right" }}>{action}</div>
    </div>
  </div>
}

export default MovementView;