import React, { Component } from 'react';
import { sortableHandle, sortableElement, sortableContainer, arrayMove } from 'react-sortable-hoc';
import shortid from 'shortid';
import { capitalize } from '../../../helpers/stringHelper'
import CreateTagModal from './CreateTagModal';
import { toastr } from 'react-redux-toastr';
import SingleImageUploader from '../../../components/image_uploader/SingleImageUploader';

class Tag extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editing: false,
      tagName: props.tag.name,
    }
  }

  render() {
    let { tag } = this.props;

    let content;

    if (this.state.editing) {
      content = (
        <input className="input-underline" type="text" value={this.state.tagName} onChange={(e) => this.setState({ tagName: e.target.value })} />
      );
    }
    else
      content = (
        <div>
          {tag.name}
        </div>
      );

    return <div className="card my-1 card-body d-flex py-2 pl-0 pr-4">
      <div className="d-flex">
        <div className="flex-grow-1 d-flex">
          <div className="d-flex my-auto"><DragHandle /></div>
          <div className="d-flex my-auto">
            {content}
          </div>
        </div>
        <div className="d-flex my-auto">
          {
            !this.state.editing &&
            <i className="fas fa-pen highlight-on-hover" onClick={() => { this.setState({ editing: true, tagName: tag.name }) }} />
          }
          {
            this.state.editing &&
            <i className="fas fa-check highlight-on-hover" onClick={() => { this.setState({ editing: false }); this.props.onNameChange(this.state.tagName) }} />
          }
          {
            !this.state.editing &&
            <i style={{ fontSize: 18 }} className="fas fa-times highlight-on-hover ml-3" onClick={this.props.onDelete} />
          }
        </div>
      </div>
    </div>;
  }
}


const DragHandle = sortableHandle(() => <i className="fas fa-bars px-4" style={{ cursor: "row-resize", color: "grey" }}></i>);

const TagItem = sortableElement(({ tag, onNameChange, onDelete }) =>
  <Tag tag={tag} onNameChange={onNameChange} onDelete={onDelete} />
);

const SortableTags = sortableContainer(({ children }) => {
  return <div>{children}</div>;
});

class CategoryForm extends Component {
  constructor(props) {
    super(props);
    if (props.mode === 'edit') {
      this.state = {
        name: props.category.name,
        tags: props.category.tags,
        imageFile: null,
        hasImage: true,
        showingCreateModal: false
      };
    }
    else
      this.state = {
        name: "",
        tags: [],
        imageFile: null,
        hasImage: false,
        showingCreateModal: false,
      }
  }

  onSave = (e) => {
    e.preventDefault();
    if (!this.state.hasImage) {
      toastr.error("Immagine non selezionata")
      return;
    }
    if (this.state.tags.length === 0) {
      toastr.error("Nessun tag definito per la categoria")
      return;
    }
    this.props.onSave(this.state);
  }

  handleChange = (e) => {
    this.setState({
      [e.target.id]: e.target.value
    })
  }

  handleImageChange = (image) => {
    this.setState({
      imageFile: image,
      hasImage: image === null ? false : true
    })
  }

  onSortEnd = ({ oldIndex, newIndex }) => {
    let tags = this.state.tags.slice();
    tags = arrayMove(tags, oldIndex, newIndex);
    this.setState({ tags: tags });
  };

  onTagNameChange = (tagId, newTagName) => {
    let tags = this.state.tags.slice();
    let index = tags.findIndex((t) => t.id === tagId);
    if (index === -1) return;

    tags[index].name = newTagName;
    this.setState({ tags: tags });
  }

  onTagCreate = (tagName) => {
    this.setState({ showingCreateModal: false });
    tagName = tagName.trim();
    tagName = capitalize(tagName);

    let tag = {
      name: tagName,
      id: shortid.generate(),
    }

    let tags = this.state.tags.slice();
    tags.push(tag);

    this.setState({ showingCreateModal: false, tags: tags });
  }

  onTagDelete = (tagId) => {
    let tags = this.state.tags.slice();
    let index = tags.findIndex((t) => t.id === tagId);

    if (index === -1) return;
    tags.splice(index, 1);
    this.setState({ tags: tags });
  }


  render() {
    let defaultImage;
    if (this.props.mode === 'edit')
      defaultImage = this.props.category.imageUrl;
    return <div>
      <h3>{this.props.mode === 'edit' ? "Modifica categoria - " + this.props.category.name : "Nuova categoria"}</h3>
      <div className="my-3">
        <div className="form-group">
          <label htmlFor="name">Nome della categoria</label>
          <input className="form-control" type="text" id="name" value={this.state.name} required onChange={this.handleChange} />
        </div>
        <SingleImageUploader required={true} title="Immagine" onImageChange={this.handleImageChange} defaultImage={defaultImage} />
      </div>

      <div className="justify-content-between align-items-center d-flex mt-2">
        <h4 className="d-flex">Tag</h4>
        <button type="button" className="btn btn-outline-primary d-flex" onClick={() => this.setState({ showingCreateModal: true })}>Aggiungi tag</button>
      </div>

      <div style={{ maxWidth: 500 }} className="mt-1">
        <SortableTags onSortEnd={this.onSortEnd} useDragHandle lockAxis="y">
          {this.state.tags.map((tag, index) => {
            return <TagItem key={tag.id} index={index} tag={tag} onDelete={() => this.onTagDelete(tag.id)} onNameChange={(name) => this.onTagNameChange(tag.id, name)} />
          })}
        </SortableTags>
        {this.state.tags.length === 0 && <div>Nessun tag inserito.</div>}
      </div>
      <CreateTagModal onSave={this.onTagCreate} onCancel={() => this.setState({ showingCreateModal: false })} show={this.state.showingCreateModal} />
      <div className="my-2 d-flex justify-content-end">
        <button className="btn btn-success" disabled={this.props.isSaving} onClick={this.onSave}>Salva</button>
      </div>
    </div>
  }
}

export default CategoryForm;