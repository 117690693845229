import React, { useEffect, useState } from 'react'
import { Modal, Button } from 'react-bootstrap'
import firebase from 'firebase/app'
import { toastr } from 'react-redux-toastr';
import SupplierCategory from '../../../../models/supplier_category';
import { Multiselect } from 'react-widgets';

type ChangeCategoryModalProps = {
  initialCategoryId: string,
  initialTagIds: string[],
  categories: SupplierCategory[],
  show: boolean,
  supplierId: string,
  close: () => void
}

function ChangeCategoryModal({ initialCategoryId, initialTagIds, categories, show, close, supplierId }: ChangeCategoryModalProps) {
  const [categoryId, setCategoryId] = useState(initialCategoryId);
  const [tagIds, setTagIds] = useState(initialTagIds);

  const category = categories.find((c) => c.id === categoryId)!;

  useEffect(() => {
    if (show) {
      setCategoryId(initialCategoryId);
      setTagIds(initialTagIds.filter((id) => category.tags.find((t) => t.id === id) !== undefined))
    }
  }, [show])

  async function save() {
    close();
    const firestore = firebase.firestore();

    try {
      await firestore.collection("suppliers").doc(supplierId).update({
        category: categoryId,
        tags: tagIds
      });

      toastr.success("", "Categoria e tag cambiati con successo!");
    }
    catch (err) {
      toastr.error("", "Errore inaspettato!");
      console.log(err);
    }
  }


  return (
    <Modal show={show} onHide={close}>
      <Modal.Header closeButton>
        <Modal.Title>Modifica di categoria e tag del fornitore</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="form-group">
          <label className="required">Categoria</label>
          <select className="form-control" id="orderState" value={categoryId} required onChange={(e) => {
            setCategoryId(e.target.value);
            setTagIds([]);
          }}>
            {categories.map((c) => <option value={c.id} > {c.name} </option>)}
          </select>
        </div>
        <div className="form-group">
          <label className="required">Tipo di sconto</label>
          <Multiselect
            valueField='id'
            textField='name'
            data={category.tags}
            value={tagIds.map((id) => ({ id }))}
            onChange={(values) => setTagIds(values.map((t) => t.id))}
            messages={{
              emptyList: 'Non ci sono altri tag disponibili',
              noneSelected: "Seleziona i tag"
            }}
            minLength={1}
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" type="button" onClick={close}>
          Annulla
          </Button>
        <Button variant="success" type="button" onClick={save} disabled={tagIds.length < 1}>
          Salva
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default ChangeCategoryModal;
