import React from 'react'
import AreaSelectView from '../../components/AreaSelectView';
import OrderHistory from './OrderHistory';

function OrderDashboardScreen() {
  return (
    <div className="orderDashboard container my-4">
      <h2>Ordini</h2>
      <AreaSelectView urlPrefix="orders" isExpandable={true} />
      <br />
      <OrderHistory />
    </div>
  )
}



export default OrderDashboardScreen;