import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import firebase from '../../../config/fbConfig'
import WeekdayConfig from './components/WeekdayConfig';
import './styles/ShiftsConfigScreen.css'
import { toastr } from 'react-redux-toastr';
import { connect } from 'react-redux';
import SplashScreen from '../../SplashScreen';

class ShiftsConfigScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
      isSaving: false,
      maxDriverCount: null,
      area: null,
    };

    this.loadArea();
  }

  loadArea = async () => {
    const firestore = firebase.firestore();
    let snap = await firestore.collection("areas").doc(this.props.match.params.areaId).get();
    if (!snap.exists) {
      this.setState({
        loaded: true,
        maxDriverCount: null,
      });
    }
    let area = snap.data();

    this.setState({
      maxDriverCount: area.maxDriverCount,
      area: area,
      loaded: true,
    });
  }

  onSave = async () => {
    const firestore = firebase.firestore();
    this.setState({ isSaving: true });
    try {

      await firestore.collection("areas").doc(this.props.match.params.areaId).set({
        maxDriverCount: this.state.maxDriverCount,
      }, { merge: true });

      toastr.success("Impostazioni salvate con successo!");
    }
    catch (err) {
      console.log(err);
      toastr.error("Errore inaspettato!");
    }
    finally {
      this.setState({ isSaving: false });
    }
  }

  onWeekdayChange = (weekdayNumber, timeString, maxDrivers) => {
    let maxDriverCount = this.state.maxDriverCount;

    maxDriverCount[weekdayNumber][timeString] = maxDrivers;
    this.setState({
      maxDriverCount: maxDriverCount
    });
  }

  render() {
    const { loaded, maxDriverCount, area } = this.state;
    const { admin } = this.props;

    if (!loaded)
      return <SplashScreen />;

    if (area === null)
      return <div className="container my-4">Area inesistente</div>

    if (!admin.isMainAdmin && !admin.areaIds.includes(area.id))
      return <div className="container my-4">Non hai accesso alla visualizzazione di questa pagina.</div>;


    let weekdayComponents = [];

    for (let i = 1; i <= 7; i++) {
      weekdayComponents.push(<WeekdayConfig shiftLength={area.shiftLength} weekdayNumber={i} key={i} data={maxDriverCount[i]} onChange={(timeString, maxDrivers) => this.onWeekdayChange(i, timeString, maxDrivers)} />);
    }
    return <div className="container my-4">
      <div>
        <h4 className="my-auto">Impostazione turni</h4>
        <h6 className="mt-0" style={{ color: "#777777" }}>{area.name}</h6>
        {weekdayComponents}
        <div className="justify-content-end d-flex mt-2">
          <button className="btn btn-success" onClick={this.onSave} disabled={this.state.isSaving}>Salva</button>
        </div>
      </div>
    </div>
  }
}

const mapStateToProps = (state) => {
  return {
    admin: state.firestore.data.currentAdmin,
  }
}

export default withRouter(connect(mapStateToProps, null)(ShiftsConfigScreen))
