import moment, { Moment } from "moment";
import React from "react";

export type Interval = {
  fromDate: Moment;
  toDate: Moment;
}

type IntervalSelectionProps = {
  interval: Interval;
  setInterval: (newInterval: Interval) => void;
}

function IntervalSelection({ interval, setInterval }: IntervalSelectionProps) {
  return <div className="d-flex flex-end flex-wrap">
    <div className="d-flex form-inline">
      <div className="form-group">
        <label htmlFor="fromDate">Da</label>
        <input type="date" id="fromDate" className="form-control mx-sm-3" value={interval.fromDate.format("YYYY-MM-DD")} onChange={(e) => {
          if (e.target.value === "")
            return;
          setInterval({ ...interval, fromDate: moment(e.target.value) });
        }} />
      </div>
    </div>
    <div className="d-flex form-inline my-3">
      <div className="form-group">
        <label htmlFor="toDate">a</label>
        <input type="date" id="toDate" className="form-control mx-sm-3" value={interval.toDate.format("YYYY-MM-DD")} onChange={(e) => {
          if (e.target.value === "")
            return;
          setInterval({ ...interval, toDate: moment(e.target.value) });
        }} />
      </div>
    </div>
  </div>;
}

export default IntervalSelection;