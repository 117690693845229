import React, { useState } from 'react'
import { useSelector } from 'react-redux';
import { isLoaded, isEmpty, useFirestoreConnect } from 'react-redux-firebase';
import { toastr } from 'react-redux-toastr';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import Admin from '../../models/admin';
import { editAdminBalanceToPay, resetAdminAmount } from '../../services/firestore_service';
import { toggleAdminAccountEnable } from '../../services/function_service';
import { RootState } from '../../store/reducers/rootReducer';
import { doubleToCurrency, doubleToPercentage } from '../../helpers/currencyHelper';
import OrderHistory from '../orders/OrderHistory';
import BalancePaidModal from '../../components/BalancePaidModal';
import ButtonWithConfirmation from '../../components/ButtonWithConfirmation';
import ResetAmountModal from '../../components/ResetAmountModal';
import SplashScreen from '../SplashScreen';

function AdminDetailsScreen(props: RouteComponentProps<{ id: string }, any, any>) {
  useFirestoreConnect(() => [
    {
      collection: 'admin',
      doc: props.match.params.id,
      storeAs: 'admin'
    },
  ]);

  const { currentAdmin, admin } = useSelector((state: RootState) => ({
    admin: state.firestore.data.admin as Admin,
    currentAdmin: state.firestore.data.currentAdmin as Admin,
  }));

  const [showBalancePaidModal, setShowBalancePaidModal] = useState(false);
  const [showResetBalanceModal, setShowResetBalanceModal] = useState(false);
  const [stateLoading, setStateLoading] = useState(false);

  if (!isLoaded(admin, currentAdmin))
    return <SplashScreen />

  if (isEmpty(admin))
    return <div className="container my-4">Amministratore inesistente.</div>;

  async function toggleEnabled() {
    try {
      setStateLoading(true);
      let result = await toggleAdminAccountEnable(props.match.params.id);
      if (!result.success)
        toastr.error(result.error ?? "Errore inaspettato!", "");
      else
        toastr.success("Stato dell'amministatore cambiato con successo!", "");
    }
    catch (err) {
      toastr.error("Errore inaspettato!", "");
      console.log(err);
    }
    finally {
      setStateLoading(false);
    }
  }

  return (
    <div className="supplierDetails container my-4">
      <div className="d-flex justify-content-between">
        <h4 className="my-auto">Dettaglio amministratore</h4>
        <div className="d-flex">
          {admin.disabled === true &&
            <ButtonWithConfirmation className="btn btn-success" buttonText="Abilita" disabled={stateLoading} onConfirm={() => toggleEnabled()}
              modalTitle="Abilita" modalMessage="Sicuro di voler abilitare l'amministratore?" />}
          {admin.disabled !== true &&
            <>
              <Link to={{ pathname: "/admin/edit", state: { admin } }} className="btn btn-primary">Modifica</Link>
              <ButtonWithConfirmation className="btn btn-warning ml-2" buttonText="Disabilita" disabled={stateLoading} onConfirm={() => toggleEnabled()}
                modalTitle="Disabilita" modalMessage="Sicuro di voler disabilitare l'amministratore? " />
            </>
          }
        </div>
      </div>
      <div className="card mt-2">
        <div className="card-body">
          <div className="my-auto flex-fill">
            <table className="table table-borderless table-sm">
              <tbody>
                <tr>
                  <th scope="row" style={{ verticalAlign: "middle" }}>ID Amministratore: </th>
                  <td>
                    <div className="d-flex align-items-center">
                      <div>{admin.id}</div>
                      <button type="button" className="btn btn-info ml-2" onClick={() => {
                        navigator.clipboard.writeText(admin.id);
                        toastr.info("ID del fornitore copiato con successo!", "");
                      }} ><i className="fas fa-copy"></i></button>
                    </div>
                  </td>
                </tr>
                <tr>
                  <th scope="row">Email: </th>
                  <td>{admin.email}</td>
                </tr>
                <tr>
                  <th scope="row">Percentuale sugli ordini </th>
                  <td>{admin.hasPercentageOnOrders ? doubleToPercentage(admin.adminPercentage!) : "Nessuna"}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      {
        admin.hasPercentageOnOrders &&
        <>
          <h4 className="mt-3">Saldo</h4>
          <div className="card card-body">
            <div className="mt-1">Saldo totale: <b>{doubleToCurrency(admin.balance ?? 0)}</b></div>
            <div className="mt-1">Da pagare: <b>{doubleToCurrency(admin.balanceToPay ?? 0)}</b></div>

            <div className="d-flex justify-content-start">
              <button className="btn btn-outline-primary mt-2" onClick={() => setShowBalancePaidModal(true)}>Pagato</button>
              <BalancePaidModal show={showBalancePaidModal} onCancel={() => setShowBalancePaidModal(false)}
                onSave={async (amount) => {
                  try {
                    await editAdminBalanceToPay(props.match.params.id, amount);
                  }
                  catch (err) {
                    console.log(err);
                    toastr.error("Errore inaspettato!", "");
                  }
                  setShowBalancePaidModal(false);
                }} maxAmount={admin.balanceToPay ?? 0} />

              <button className="btn btn-outline-warning ml-2 mt-2" onClick={() => setShowResetBalanceModal(true)}>Reimposta</button>
              <ResetAmountModal show={showResetBalanceModal} onCancel={() => setShowResetBalanceModal(false)}
                onSave={async (amount) => {
                  try {
                    await resetAdminAmount(props.match.params.id, amount, "balanceToPay");
                  }
                  catch (err) {
                    console.log(err);
                    toastr.error("Errore inaspettato!", "");
                  }
                  setShowResetBalanceModal(false);
                }} />
            </div>
          </div>

          <div className="my-3">
            <OrderHistory adminId={props.match.params.id} viewMode={'admin'} />
          </div>
        </>
      }

    </div>
  )
}


export default withRouter(AdminDetailsScreen);