import React from 'react'
import { useSelector } from 'react-redux';
import { isLoaded, isEmpty, useFirestoreConnect } from 'react-redux-firebase';
import { RouteComponentProps, withRouter } from 'react-router-dom'
import OrderHistory from '../orders/OrderHistory';
import { STRIPE_BASE_URL } from '../../config/stripeConfig';
import { toastr } from 'react-redux-toastr'
import { RootState } from '../../store/reducers/rootReducer';
import User from '../../models/user';
import SplashScreen from '../SplashScreen';

function CustomerDetailsScreen(props: RouteComponentProps<{ id: string }, any, any>) {
  useFirestoreConnect(() => [
    {
      collection: 'users',
      doc: props.match.params.id,
      storeAs: 'customer'
    },
  ]);

  const customer = useSelector((state: RootState) => state.firestore.data.customer as User);

  if (!isLoaded(customer))
    return <SplashScreen />

  if (isEmpty(customer))
    return <div className="container my-4">Cliente inesistente.</div>;

  return (
    <div className="customerDetails container my-4">
      <div className="d-flex justify-content-between">
        <h4 className="my-auto">Dettaglio cliente</h4>
        {customer.stripeCustomerId &&
          <a className="btn btn-outline-info" target="__blank" href={STRIPE_BASE_URL + "customers/" + customer.stripeCustomerId}>Profilo Stripe</a>}
      </div>
      <div className="card mt-2">
        <div className="card-body">
          <div className="d-flex">
            {customer.imageUrl !== undefined && customer.imageUrl !== "" && customer.imageUrl !== null &&
              <div className="my-auto">
                <img src={customer.imageUrl} className="rounded mr-2 d-block" style={{ maxWidth: "150px", height: "auto" }}></img>
              </div>}
            <div className="my-auto d-flex flex-fill">
              <table className="table table-borderless table-sm">
                <tbody>
                  <tr>
                    <th scope="row" style={{ verticalAlign: "middle" }}>ID Cliente: </th>
                    <td>
                      <div className="d-flex align-items-center">
                        <div>{customer.uid}</div>
                        <button type="button" className="btn btn-info ml-2" onClick={() => {
                          navigator.clipboard.writeText(customer.uid);
                          toastr.info("ID del cliente copiato con successo!", "");
                        }} ><i className="fas fa-copy"></i></button>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">Indirizzo email: </th>
                    <td>{customer.email}</td>
                  </tr>
                  <tr>
                    <th scope="row">Nominativo: </th>
                    <td>{customer.nominative ?? "Non specificato"}</td>
                  </tr>
                  <tr>
                    <th scope="row">Numero di telefono: </th>
                    <td style={{ whiteSpace: "nowrap" }}>{customer.phoneNumber ?? "Non specificato"}</td>
                  </tr>
                  <tr>
                    <th scope="row">Token FCM (notifiche): </th>
                    <td style={{ whiteSpace: "pre-wrap", wordWrap: "break-word", wordBreak: "break-all", }}>{customer.fcmToken ?? "Non disponibile"}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div className="my-3">
        <OrderHistory customerId={props.match.params.id} />
      </div>
    </div>
  )
}

export default withRouter(CustomerDetailsScreen);