import React, { useState } from 'react'
import { useSelector } from 'react-redux';
import { isLoaded, isEmpty, useFirestoreConnect } from 'react-redux-firebase';
import { RouteComponentProps, withRouter } from 'react-router-dom'
import OrderHistory from '../../orders/OrderHistory';
import { toggleDisabledSupplier, editSupplierField, } from '../../../store/actions/supplierActions'
import { doubleToCurrency, doubleToPercentage, intToCurrency } from '../../../helpers/currencyHelper';
import ChangePrintDeviceModal, { PrintDeviceType } from './components/ChangePrintDeviceModal';
import { Link } from 'react-router-dom';
import firebase from 'firebase/app'
import { toastr } from 'react-redux-toastr';
import ChangeSettingsFieldModal from './components/ChangeSettingsFieldModal';
import ChangeDeliveryFieldModal from './components/ChangeDeliveryFieldModal';
import Rating from 'react-rating'
import ChangeCategoryModal from './components/ChangeCategoryModal';
import ChangeOwnDriverUsageModal from './components/ChangeOwnDriverUsageModal';
import { RootState } from '../../../store/reducers/rootReducer';
import Area from '../../../models/area';
import Supplier from '../../../models/supplier';
import Settings from '../../../models/settings';
import Admin from '../../../models/admin';
import SupplierCategory from '../../../models/supplier_category';
import SupplierAdmin from '../../../models/supplier_admin';
import { useAppDispatch } from '../../../store/store';
import ChangeZipCodesModal from './components/ChangeZipCodesModal';
import ChangeCanAddOrdersModal from './components/ChangeCanAddOrdersModal';
import ChangeSupplierInfoModal from './components/ChangeSupplierInfoModal';
import moment from 'moment';
import { createMovement } from '../../../services/firestore_service';
import BalancePaidModal from '../../../components/BalancePaidModal';
import ButtonWithConfirmation from '../../../components/ButtonWithConfirmation';
import ChangeAreaModal from '../../../components/ChangeAreaModal';
import ChangeEmailModal from '../../../components/ChangeEmailModal';
import ChangePasswordModal from '../../../components/ChangePasswordModal';
import ResetAmountModal from '../../../components/ResetAmountModal';
import SplashScreen from '../../SplashScreen';
import ChangeResponsibleForCashModal from './components/ChangeResponsibleForCashModal';
import { getSupplierCompleteAmounts } from '../../../models/supplier_report';

function SupplierDetailsScreen(props: RouteComponentProps<{ id: string }, any, any>) {

  const { admin, area, categories, settings, supplier, supplierAdmins } = useSelector((state: RootState) => ({
    supplier: state.firestore.data.supplier as Supplier,
    area: state.firestore.data.area as Area,
    settings: state.firestore.data.settings as Settings,
    supplierAdmins: state.firestore.ordered['supplierAdmin'] as SupplierAdmin[],
    admin: state.firestore.data.currentAdmin as Admin,
    categories: state.firestore.ordered.supplierCategories as SupplierCategory[],
  }));
  useFirestoreConnect(() => [
    {
      collection: 'suppliers',
      doc: props.match.params.id,
      storeAs: 'supplier'
    },
    {
      collection: 'settings',
      doc: 'settings',
      storeAs: 'settings',
    },
    {
      collection: 'supplier_admin',
      where: [['supplierId', '==', props.match.params.id]],
      storeAs: 'supplierAdmin'
    },
    {
      collection: 'supplier_categories',
      storeAs: 'supplierCategories'
    },
    ...(isLoaded(supplier) ? [{
      collection: 'areas',
      doc: supplier.areaId,
      storeAs: 'area'
    }] : []),
  ]);

  const [showBalancePaidModal, setShowBalancePaidModal] = useState(false);
  const [showResetBalanceModal, setShowResetBalanceModal] = useState(false);
  const [showCashModal, setShowCashModal] = useState(false);
  const [showResetCashModal, setShowResetCashModal] = useState(false);
  const [showChangePasswordModal, setShowChangePasswordModal] = useState(false);
  const [showChangeEmailModal, setShowChangeEmailModal] = useState(false);
  const [showChangeDeliveryAmountModal, setShowChangeDeliveryAmountModal] = useState(false);
  const [showChangeSupplierPercentageModal, setShowChangeSupplierPercentageModal] = useState(false);
  const [showChangeFreeDeliveryThresholdModal, setShowChangeFreeDeliveryThresholdModal] = useState(false);
  const [showChangeLongDistanceDeliveryAmountModal, setShowChangeLongDistanceDeliveryAmountModal] = useState(false);
  const [showChangeLongDistanceDeliveryAmount2Modal, setShowChangeLongDistanceDeliveryAmount2Modal] = useState(false);
  const [showChangeZipCodesModal, setShowChangeZipCodesModal] = useState(false);
  const [showChangeCategoryModal, setShowChangeCategoryModal] = useState(false);
  const [showChangeOwnDriverUsageModal, setShowChangeOwnDriverUsageModal] = useState(false);
  const [showChangePrintDeviceModal, setShowChangePrintDeviceModal] = useState(false);
  const [showChangeCanAddOrdersModal, setShowChangeCanAddOrdersModal] = useState(false);
  const [showChangeResponsibleForCashModal, setShowChangeResponsibleForCashModal] = useState(false);
  const [showChangeInfoModal, setShowChangeInfoModal] = useState(false);
  const [showChangeAreaModal, setShowChangeAreaModal] = useState(false);

  const dispatch = useAppDispatch();

  const hideSupplier = () => dispatch(editSupplierField(props.match.params.id, "hidden", true));
  const showSupplier = () => dispatch(editSupplierField(props.match.params.id, "hidden", false));
  const disableSupplier = () => dispatch(toggleDisabledSupplier(props.match.params.id, true));
  const enableSupplier = () => dispatch(toggleDisabledSupplier(props.match.params.id, false));

  if (!isLoaded(supplier, supplierAdmins, settings, categories, area))
    return <SplashScreen />

  if (isEmpty(supplier))
    return <div className="container my-4">Fornitore inesistente.</div>;

  if (!admin.isMainAdmin && !admin.areaIds!.includes(supplier.areaId))
    return <div className="container my-4">Non hai accesso alla visualizzazione di questo fornitore.</div>;

  const supplierAdmin = supplierAdmins.length === 0 ? null : supplierAdmins[0];

  let printDevice: PrintDeviceType = "NONE";
  let printDeviceDisplayString = "Nessuno";
  let printerName = supplierAdmin?.printerName;
  let newPosPrinter = supplierAdmin?.useNewPosPrinter === true;
  if (supplierAdmin?.printEnabled === true) {
    if (newPosPrinter) {
      printDevice = "NEW_POS";
      printDeviceDisplayString = "Nuovo palmare";
    }
    else if (supplierAdmin.printerName === "IposPrinter") {
      printDevice = "POS";
      printDeviceDisplayString = "Palmare";
    }
    else {
      printDevice = "PRINTER";
      printDeviceDisplayString = "Stampante";
    }
  }

  const supplierPercentage = supplier.supplierPercentage ?? settings.supplierPercentage;
  const category = categories.find((c) => c.id === supplier.category);
  const tags = category?.tags.filter((t) => supplier.tags.includes(t.id)) ?? [];
  let tagsString = tags.reduce((str, t) => str + t.name + ", ", "");
  tagsString = tagsString.substring(0, tagsString.length - 2);

  const amounts = getSupplierCompleteAmounts(supplier.amounts);

  return (
    <div className="supplierDetails container my-4">
      <div className="d-flex justify-content-between">
        <h4 className="my-auto">Dettaglio fornitore</h4>
        <div className="d-flex">
          <Link to={"/suppliers/" + props.match.params.id + "/reports"} className="btn btn-outline-primary">
            Visualizza entrate
          </Link>
          {supplier.disabled !== true && supplier.hidden === true &&
            <ButtonWithConfirmation className="btn btn-outline-success ml-2" buttonText="Mostra" onConfirm={showSupplier}
              modalTitle="Mostra fornitore" modalMessage="Sicuro di voler mostrare il fornitore all'interno dell'app utente?" />}
          {supplier.disabled !== true && supplier.hidden !== true &&
            <ButtonWithConfirmation className="btn btn-outline-warning ml-2" buttonText="Nascondi" onConfirm={hideSupplier}
              modalTitle="Nascondi fornitore" modalMessage="Sicuro di voler nascondere il fornitore dall'app utente?" />}
          {supplier.disabled === true &&
            <ButtonWithConfirmation className="btn btn-outline-success ml-2" buttonText="Riabilita" onConfirm={enableSupplier}
              modalTitle="Riabilita fornitore" modalMessage="Sicuro di voler riabilitare il fornitore?" />}
          {supplier.disabled !== true &&
            <ButtonWithConfirmation className="btn btn-outline-danger ml-2" buttonText="Disabilita" onConfirm={disableSupplier}
              modalTitle="Disabilita fornitore" modalMessage="Sicuro di voler disabilitare il fornitore?" />}
        </div>
      </div>
      <div className="card mt-2">
        <div className="card-body">
          <div className="d-flex flex-wrap">
            <div className="my-auto">
              <img src={supplier.imageUrl} className="rounded mr-2 d-block" style={{ maxWidth: "200px", height: "auto" }}></img>
              {
                supplier.rating !== undefined && supplier.rating !== null &&
                <div className="d-flex align-items-center mt-2 justify-content-center">
                  <Rating readonly={true} initialRating={supplier.rating}
                    fullSymbol={<i className="fas fa-star"></i>}
                    emptySymbol={<i className="far fa-star"></i>}
                  />
                  <div className="ml-2">{supplier.rating.toFixed(1)}/5</div>
                </div>
              }
            </div>
            <div className="my-auto flex-fill">
              <table className="table table-borderless table-sm">
                <tbody>
                  <tr>
                    <th scope="row" style={{ verticalAlign: "middle" }}>ID Fornitore: </th>
                    <td>
                      <div className="d-flex align-items-center">
                        <div>{supplier.reference.id}</div>
                        <button type="button" className="btn btn-info ml-2" onClick={() => {
                          navigator.clipboard.writeText(supplier.reference.id);
                          toastr.info("ID del fornitore copiato con successo!", "");
                        }} ><i className="fas fa-copy"></i></button>
                      </div>
                    </td>
                  </tr>

                  <InfoRow title="Area" value={area.name} onEdit={() => setShowChangeAreaModal(true)} />
                  <ChangeAreaModal show={showChangeAreaModal} onCancel={() => setShowChangeAreaModal(false)}
                    currentAreaId={supplier.areaId} onDone={async (newAreaId) => {
                      setShowChangeAreaModal(false);
                      if (newAreaId === supplier.areaId)
                        return;
                      const functions = firebase.functions();

                      try {
                        const changeSupplierArea = functions.httpsCallable("changeSupplierArea");
                        const response = await changeSupplierArea({ supplierId: props.match.params.id, newAreaId });
                        if (response.data.success === false) {
                          toastr.error("Errore: " + response.data.error, "")
                        }
                        else {
                          toastr.success("Area del fornitore cambiata con successo!", "")
                        }
                      }
                      catch (err) {
                        console.log(err);
                        toastr.error("Errore inaspettato!", "");
                      }
                    }} />
                  <InfoRow title="Ragione sociale" value={supplier.companyName} onEdit={() => setShowChangeInfoModal(true)} />
                  <InfoRow title="Nome al pubblico" value={supplier.name} onEdit={() => setShowChangeInfoModal(true)} />
                  <InfoRow title="Descrizione" value={supplier.description} onEdit={() => setShowChangeInfoModal(true)} />
                  <InfoRow title="Indirizzo" value={supplier.address} />
                  <InfoRow title="Indirizzo sede legale" value={supplier.registeredOfficeAddress ?? "Non specificato"} onEdit={() => setShowChangeInfoModal(true)} />
                  <InfoRow title="Numero di telefono" value={supplier.phoneNumber} onEdit={() => setShowChangeInfoModal(true)} />
                  <InfoRow title="Partita IVA" value={supplier.partitaIVA} onEdit={() => setShowChangeInfoModal(true)} />
                  <InfoRow title="IBAN" value={supplier.iban} onEdit={() => setShowChangeInfoModal(true)} />
                  <InfoRow title="Codice univoco per la fatturazione" value={supplier.ipa ?? "Non specificato"} onEdit={() => setShowChangeInfoModal(true)} />
                  <InfoRow title="Email amministratore" value={supplierAdmin?.email ?? ""} onEdit={() => setShowChangeEmailModal(true)} />
                  <InfoRow title="Note aggiuntive" value={supplier.notes ?? "Nessuna nota"} onEdit={() => setShowChangeInfoModal(true)} />
                  <ChangeSupplierInfoModal initialInfo={supplier} supplierId={props.match.params.id}
                    close={() => setShowChangeInfoModal(false)} show={showChangeInfoModal} />
                </tbody>
              </table>
              <div className="d-flex mt-2 ml-1">
                <Link to="#" onClick={() => setShowChangePasswordModal(true)}>Cambia password</Link>
                <ChangePasswordModal onCancel={() => setShowChangePasswordModal(false)} onDone={async (password) => {
                  setShowChangePasswordModal(false)
                  const functions = firebase.functions();

                  try {
                    const changeSupplierAdminPassword = functions.httpsCallable("changeSupplierAdminPassword");
                    const response = await changeSupplierAdminPassword({ supplierAdminId: supplierAdmin!.uid, password });
                    if (response.data.success === false) {
                      toastr.error("Errore: " + response.data.error, "")
                    }
                    else {
                      toastr.success("Password del fornitore cambiata con successo!", "")
                    }
                  }
                  catch (err) {
                    console.log(err);
                    toastr.error("Errore inaspettato!", "");
                  }
                }} show={showChangePasswordModal} />
                <Link to="#" onClick={() => setShowChangeEmailModal(true)} className="ml-3">Cambia email</Link>
                <ChangeEmailModal onCancel={() => setShowChangeEmailModal(false)} onDone={async (email) => {
                  setShowChangeEmailModal(false)
                  const functions = firebase.functions();

                  try {
                    const changeSupplierAdminEmail = functions.httpsCallable("changeSupplierAdminEmail");
                    const response = await changeSupplierAdminEmail({ supplierAdminId: supplierAdmin!.uid, email });
                    if (response.data.success === false) {
                      toastr.error("Errore: " + response.data.error, "")
                    }
                    else {
                      toastr.success("Email del fornitore cambiata con successo!", "")
                    }
                  }
                  catch (err) {
                    console.log(err);
                    toastr.error("Errore inaspettato!", "");
                  }
                }} show={showChangeEmailModal} />
              </div>
              <hr />
              <table className="table table-borderless table-sm">
                <tbody>
                  <InfoRow title="Categoria" value={category?.name ?? "Categoria non esistente"} onEdit={() => setShowChangeCategoryModal(true)} />
                  <ChangeCategoryModal show={showChangeCategoryModal} supplierId={props.match.params.id}
                    close={() => setShowChangeCategoryModal(false)} initialCategoryId={category!.id} initialTagIds={supplier.tags}
                    categories={categories} />
                  <InfoRow title="Tag" value={tagsString} onEdit={() => setShowChangeCategoryModal(true)} />
                </tbody>
              </table>
              <hr />
              <table className="table table-borderless table-sm">
                <tbody>
                  <InfoRow title="CAP disponibili" value={supplier.zipCodes.reduce((code, string) => string + ", " + code, "").slice(0, -2)}
                    onEdit={() => setShowChangeZipCodesModal(true)} />
                  <ChangeZipCodesModal show={showChangeZipCodesModal} supplierId={props.match.params.id}
                    close={() => setShowChangeZipCodesModal(false)} initialValue={supplier.zipCodes} />
                </tbody>
              </table>
              <hr />
              <table className="table table-borderless table-sm">
                <tbody>
                  <InfoRow title="Percentuale a ordine" onEdit={() => setShowChangeSupplierPercentageModal(true)}
                    value={supplier.supplierPercentage ? doubleToPercentage(supplier.supplierPercentage) :
                      ("Default (" + doubleToPercentage(settings.supplierPercentage) + ")")} />
                  <ChangeSettingsFieldModal defaultSettings={settings.supplierPercentage} fieldId="supplierPercentage"
                    close={() => setShowChangeSupplierPercentageModal(false)}
                    initialValue={supplier.supplierPercentage ?? settings.supplierPercentage} show={showChangeSupplierPercentageModal}
                    supplierId={props.match.params.id} title="Modifica la percentuale a ordine" type="percentage"
                    initialUseDefaultSettings={!supplier.supplierPercentage} />

                  <InfoRow title="Fattorini propri abilitati" value={supplier.canUseOwnDrivers === true ? "Sì" : "No"}
                    onEdit={() => setShowChangeOwnDriverUsageModal(true)} />
                  <ChangeOwnDriverUsageModal close={() => setShowChangeOwnDriverUsageModal(false)}
                    show={showChangeOwnDriverUsageModal} initialDeliveryAmount={supplier.deliveryAmountWithOwnDrivers ?? supplier.deliveryAmount}
                    supplierId={props.match.params.id} initialCanUseOwnDrivers={supplier.canUseOwnDrivers === true}
                    initialSupplierPercentage={supplier.supplierPercentageWithOwnDrivers ?? supplier.supplierPercentage ?? settings.supplierPercentage} />
                  {
                    supplier.canUseOwnDrivers &&
                    <>
                      <InfoRow title="Percentuale a ordine con fattorini propri" value={doubleToPercentage(supplier.supplierPercentageWithOwnDrivers!)}
                        onEdit={() => setShowChangeOwnDriverUsageModal(true)} />
                      <InfoRow title="Costi spedizione con fattorini propri" value={doubleToCurrency(supplier.deliveryAmountWithOwnDrivers!)}
                        onEdit={() => setShowChangeOwnDriverUsageModal(true)} />
                    </>
                  }
                  <InfoRow title="Costo spedizione" value={supplier.deliveryAmount !== null && supplier.deliveryAmount !== undefined ?
                    doubleToCurrency(supplier.deliveryAmount) :
                    doubleToCurrency(settings.deliveryAmount)}
                    onEdit={() => setShowChangeDeliveryAmountModal(true)} />
                  <ChangeDeliveryFieldModal fieldId="deliveryAmount"
                    close={() => setShowChangeDeliveryAmountModal(false)}
                    initialValue={supplier.deliveryAmount ?? settings.deliveryAmount} show={showChangeDeliveryAmountModal}
                    supplierId={props.match.params.id} title="Modifica il costo della spedizione" />

                  <InfoRow title="Costo spedizione sopra prima soglia" value={supplier.longDistanceDeliveryAmount !== null && supplier.longDistanceDeliveryAmount !== undefined ?
                    doubleToCurrency(supplier.longDistanceDeliveryAmount) :
                    doubleToCurrency(area.longDistanceDeliveryAmount)}
                    onEdit={() => setShowChangeLongDistanceDeliveryAmountModal(true)} />
                  <ChangeDeliveryFieldModal fieldId="longDistanceDeliveryAmount"
                    close={() => setShowChangeLongDistanceDeliveryAmountModal(false)}
                    initialValue={supplier.longDistanceDeliveryAmount ?? area.longDistanceDeliveryAmount} show={showChangeLongDistanceDeliveryAmountModal}
                    supplierId={props.match.params.id} title="Modifica il costo della spedizione sopra la prima soglia" />

                  <InfoRow title="Costo spedizione sopra seconda soglia" value={supplier.longDistanceDeliveryAmount2 !== null && supplier.longDistanceDeliveryAmount2 !== undefined ?
                    doubleToCurrency(supplier.longDistanceDeliveryAmount2) :
                    doubleToCurrency(area.longDistanceDeliveryAmount2)}
                    onEdit={() => setShowChangeLongDistanceDeliveryAmount2Modal(true)} />
                  <ChangeDeliveryFieldModal fieldId="longDistanceDeliveryAmount2"
                    close={() => setShowChangeLongDistanceDeliveryAmount2Modal(false)}
                    initialValue={supplier.longDistanceDeliveryAmount2 ?? area.longDistanceDeliveryAmount2} show={showChangeLongDistanceDeliveryAmount2Modal}
                    supplierId={props.match.params.id} title="Modifica il costo della spedizione sopra la seconda soglia" />

                  <InfoRow title="Soglia spedizione gratuita" value={supplier.freeDeliveryThreshold !== null && supplier.freeDeliveryThreshold !== undefined ?
                    doubleToCurrency(supplier.freeDeliveryThreshold) :
                    ("Default (" + doubleToCurrency(settings.freeDeliveryThreshold) + ")")}
                    onEdit={() => setShowChangeFreeDeliveryThresholdModal(true)} />
                  <ChangeSettingsFieldModal defaultSettings={settings.freeDeliveryThreshold} fieldId="freeDeliveryThreshold"
                    close={() => setShowChangeFreeDeliveryThresholdModal(false)}
                    initialValue={supplier.freeDeliveryThreshold ?? settings.freeDeliveryThreshold} show={showChangeFreeDeliveryThresholdModal}
                    supplierId={props.match.params.id} title="Modifica la soglia di spedizione gratuita" type="currency"
                    initialUseDefaultSettings={!supplier.freeDeliveryThreshold} />
                </tbody>
              </table>
              <hr />
              <table className="table table-borderless table-sm">
                <tbody>
                  <InfoRow title="Responsabile dei Soldi alla mano" value={supplier.responsibleForCash === true ? "Sì" : "No"}
                    onEdit={() => setShowChangeResponsibleForCashModal(true)} />
                  <ChangeResponsibleForCashModal close={() => setShowChangeResponsibleForCashModal(false)}
                    show={showChangeResponsibleForCashModal}
                    supplierId={props.match.params.id} initialResponsibleForCash={supplier.responsibleForCash === true} />
                </tbody>
              </table>
              <hr />
              <table className="table table-borderless table-sm">
                <tbody>
                  <InfoRow title="Dispositivo in dotazione" value={printDeviceDisplayString}
                    onEdit={() => setShowChangePrintDeviceModal(true)} />
                  <ChangePrintDeviceModal show={showChangePrintDeviceModal} supplierAdminId={supplierAdmin!.uid}
                    close={() => setShowChangePrintDeviceModal(false)} initialValue={printDevice!} initialPrinterName={printDevice === "PRINTER" ? printerName! : ""} />
                </tbody>
              </table>
              <hr />
              <table className="table table-borderless table-sm">
                <tbody>
                  <InfoRow title="Può aggiungere ordini" value={supplier.canAddOrders === true ? "Sì" : "No"}
                    onEdit={() => setShowChangeCanAddOrdersModal(true)} />
                  {
                    supplier.canAddOrders &&
                    <InfoRow title="Percentuale ordini aggiunti da gestionale"
                      value={supplier.supplierPercentageOrdersCreatedBySupplier ? doubleToPercentage(supplier.supplierPercentageOrdersCreatedBySupplier!) :
                        ("Default (" + doubleToPercentage(supplier.supplierPercentage ?? settings.supplierPercentage) + ")")}
                      onEdit={() => setShowChangeCanAddOrdersModal(true)} />
                  }
                  <ChangeCanAddOrdersModal close={() => setShowChangeCanAddOrdersModal(false)}
                    show={showChangeCanAddOrdersModal} initialSupplierPercentage={supplier.supplierPercentageOrdersCreatedBySupplier}
                    supplierId={props.match.params.id} initialCanAddOrders={supplier.canAddOrders === true} />
                </tbody>
              </table>

            </div>
          </div>
        </div>
      </div>
      <h4 className="mt-3">Saldo</h4>
      <div className="card card-body">
        <div className="mt-1">Totale ordini: <b>{intToCurrency(amounts.totalOrderAmount)}</b></div>
        <div className="mt-1">Guadagno personale (IVA esclusa): <b>{intToCurrency(amounts.orderAmountNoIva)}</b></div>
        <div className="mt-1">Guadagno personale (IVA inclusa): <b>{intToCurrency(amounts.orderAmount)}</b></div>
        <div className="mt-1">Altre entrate: <b>{intToCurrency(amounts.otherAmount)}</b></div>
        <div className="mt-1">Saldo totale (IVA inclusa): <b>{intToCurrency(amounts.totalAmount)}</b></div>

        <div className="d-flex justify-content-start">
          <button className="btn btn-outline-primary mt-2" onClick={() => setShowBalancePaidModal(true)}>Pagato</button>
          <BalancePaidModal show={showBalancePaidModal} onCancel={() => setShowBalancePaidModal(false)}
            onSave={async (amount) => {
              try {
                await createMovement(props.match.params.id, "supplier", "OTHER", "", -amount, moment());
              }
              catch (err) {
                console.log(err);
                toastr.error("Errore inaspettato!", "");
              }
              setShowBalancePaidModal(false);
            }} maxAmount={(amounts.totalAmount / 100.0) ?? 0} />

          <button className="btn btn-outline-warning ml-2 mt-2" onClick={() => setShowResetBalanceModal(true)}>Reimposta</button>
          <ResetAmountModal show={showResetBalanceModal} onCancel={() => setShowResetBalanceModal(false)}
            onSave={async (amount) => {
              try {
                await createMovement(props.match.params.id, "supplier", "OTHER", "", amount - amounts.totalAmount / 100.0, moment());
              }
              catch (err) {
                console.log(err);
                toastr.error("Errore inaspettato!", "");
              }
              setShowResetBalanceModal(false);
            }} />
        </div>
      </div>
      {(supplier.canUseOwnDrivers || supplier.responsibleForCash) &&
        <>
          <h4 className="mt-3">Contanti</h4>
          <div className="card card-body">
            <div className="mt-1">Contanti accumulati: <b>{intToCurrency(amounts.cashAmount)}</b></div>
            <div className="mt-1">Contanti depositati: <b>{intToCurrency(amounts.cashReceivedAmount)}</b></div>
            <div className="mt-1">Contanti non inviati a Taporty: <b>{intToCurrency(amounts.cashDiffAmount)}</b></div>

            <div className="d-flex justify-content-start">
              <button className="btn btn-outline-primary mt-2" onClick={() => setShowCashModal(true)}>Pagato</button>
              <BalancePaidModal show={showCashModal} onCancel={() => setShowCashModal(false)} driverCash={true}
                onSave={async (amount) => {
                  try {
                    await createMovement(props.match.params.id, "supplier", "CASH_RECEIVED", "", amount, moment());
                  }
                  catch (err) {
                    console.log(err);
                    toastr.error("Errore inaspettato!", "");
                  }
                  setShowCashModal(false);
                }} maxAmount={(amounts.cashDiffAmount / 100.0) ?? 0} />

              <button className="btn btn-outline-warning ml-2 mt-2" onClick={() => setShowResetCashModal(true)}>Reimposta</button>
              <ResetAmountModal show={showResetCashModal} onCancel={() => setShowResetCashModal(false)}
                onSave={async (amount) => {
                  let actualAmount = Math.floor(amount * 100.0);
                  try {
                    if (actualAmount > amounts.cashDiffAmount)
                      await createMovement(props.match.params.id, "supplier", "CASH", "", (actualAmount - amounts.cashDiffAmount) / 100.0, moment());
                    else if (actualAmount < amounts.cashDiffAmount)
                      await createMovement(props.match.params.id, "supplier", "CASH_RECEIVED", "", (amounts.cashDiffAmount - amount) / 100.0, moment());
                  }
                  catch (err) {
                    console.log(err);
                    toastr.error("Errore inaspettato!", "");
                  }
                  setShowResetCashModal(false);
                }} />
            </div>
          </div>
        </>
      }
      <div className="my-3">
        <OrderHistory supplierId={props.match.params.id} viewMode={'supplier'} />
      </div>
    </div>
  )
}

type InfoRowProps = {
  title: string;
  value: string;
  onEdit?: () => void;
}

function InfoRow({ onEdit, title, value }: InfoRowProps) {
  return (
    <tr>
      <th scope="row">{title}</th>
      <td style={{ width: "60%" }}>
        <div style={{ display: "flex" }}>
          <div className="mr-3">{value === "" ? "Non specificato" : value}</div>
          {onEdit && <i className="fas fa-pen highlight-on-hover" onClick={onEdit} />}
        </div>
      </td>
    </tr>
  );
}

export default withRouter(SupplierDetailsScreen);

