import React, { Component } from 'react'
import { connect } from 'react-redux'
import { signOut } from '../store/actions/authActions'

class DisabledAdminScreen extends Component {
  render() {
    const { signOut } = this.props;

    return (
      <div className="jumbotron d-flex align-items-center min-vh-100 bg-light">
        <div className="container text-center">
          <div className="text-center">
            Questo account amministratore è stato disabilitato.
          </div>
          <div className="text-center mt-1">
            <button onClick={signOut} className="btn btn-primary">Log out</button>
          </div>
        </div>
      </div>
    )
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    signOut: () => dispatch(signOut())
  }
}

export default connect(null, mapDispatchToProps)(DisabledAdminScreen)