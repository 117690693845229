const initState = {
  authError: null,
  adminId: null,
}

const authReducer = (state = initState, action) => {
  switch (action.type) {
    case 'LOGIN_ERROR':
      return {
        ...state,
        authError: 'Accesso fallito',
        adminId: null,
      }

    case 'LOGIN_NOT_ADMIN':
      return {
        ...state,
        adminId: null,
        authError: null,
      }

    case 'LOGIN_SUCCESS':
      return {
        ...state,
        authError: null,
        adminId: action.adminId,
      }

    case 'ADMIN_ID_PROVIDER':
      return {
        ...state,
        adminId: action.adminId
      }

    case 'SIGNOUT_SUCCESS':
      return {
        ...state,
        authError: null,
        adminId: null
      }

    default:
      return state
  }
};

export default authReducer;