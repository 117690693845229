import firebase from "firebase";
import FunctionResult from "../models/function_result";

export { createAdminAccount, editAdminAccount, toggleAdminAccountEnable }

async function createAdminAccount(email: string, password: string, areaIds: string[], hasPercentageOnOrders: boolean, adminPercentage: number): Promise<FunctionResult> {
  const functions = firebase.functions();
  const createAdminAccount = functions.httpsCallable("createAdminAccount");
  return (await createAdminAccount({ email, password, areaIds, hasPercentageOnOrders, adminPercentage })).data as FunctionResult;
}

async function editAdminAccount(adminId: string, email: string, password: string, areaIds: string[], hasPercentageOnOrders: boolean, adminPercentage: number): Promise<FunctionResult> {
  const functions = firebase.functions();
  const editAdminAccount = functions.httpsCallable("editAdminAccount");
  return (await editAdminAccount({ adminId: adminId, email, password, areaIds, hasPercentageOnOrders, adminPercentage })).data as FunctionResult;;
}


async function toggleAdminAccountEnable(adminId: string): Promise<FunctionResult> {
  const functions = firebase.functions();
  const toggleAdminAccountEnable = functions.httpsCallable("toggleAdminAccountEnable");
  return (await toggleAdminAccountEnable({ adminId: adminId })).data as FunctionResult;;
}