import React, { FormEvent } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { useState } from 'react';
import { toastr } from 'react-redux-toastr';
import Admin from '../../models/admin';
import { isLoaded, useFirestoreConnect } from 'react-redux-firebase';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/reducers/rootReducer';
import Area from '../../models/area';
import NumberFormat from 'react-number-format';
import FunctionResult from '../../models/function_result';
import { createAdminAccount, editAdminAccount } from '../../services/function_service';
import Checkbox from '../../components/Checkbox';
import SplashScreen from '../SplashScreen';

function AdminEditScreen(props: RouteComponentProps<{}, any, { admin: Admin } | undefined>) {
  const admin = props.location.state?.admin;

  const [email, setEmail] = useState(admin === undefined ? "" : admin.email);
  const [password, setPassword] = useState(admin === undefined ? "" : admin.password);
  const [areaIds, setAreaIds] = useState(admin === undefined ? [] : admin.areaIds!);
  const [hasPercentageOnOrders, setHasPercentageOnOrders] = useState(admin?.hasPercentageOnOrders === undefined ? false : admin.hasPercentageOnOrders);
  const [adminPercentage, setAdminPercentage] = useState<number | undefined>(admin?.adminPercentage === undefined ? 10 : (admin.adminPercentage * 100.));
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  useFirestoreConnect({
    collection: "areas"
  });

  const areas: Area[] | undefined = useSelector((state: RootState) => state.firestore.ordered.areas);

  async function onSubmit(e: FormEvent) {
    e.preventDefault();

    try {
      setLoading(true);
      setError("");

      let result: FunctionResult;
      if (admin === undefined)
        result = await createAdminAccount(email, password, areaIds, hasPercentageOnOrders, adminPercentage! / 100.);
      else
        result = await editAdminAccount(admin.id, email, password, areaIds, hasPercentageOnOrders, adminPercentage! / 100.);

      if (!result.success) {
        setError(result.error ?? "Errore inaspettato, ricontrolla i dati inseriti.");
        return;
      }

      if (admin === undefined)
        toastr.success("Amministratore creato con successo!", "");
      else
        toastr.success("Amministratore modificato con successo!", "");

      props.history.push("/admin");

    }
    catch (err) {
      console.log(err);
      setError("Errore inaspettato.");
      setLoading(false);
    }
  }

  if (!isLoaded(areas))
    return <SplashScreen />;

  return <div className="container my-4">
    <form onSubmit={onSubmit}>
      <h4>{admin === undefined ? "Creazione amministratore" : "Modifica amministratore"}</h4>
      <div className="card card-body">
        {
          admin !== undefined &&
          <div className="form-inline mb-3" style={{ maxWidth: "700px" }}>
            <label><b>ID Amministratore</b></label>
            <div className="d-flex align-items-center">
              <input type="text" id="name" style={{ minWidth: "400px" }} className="form-control ml-3" value={admin.id} disabled />
              <button type="button" className="btn btn-info ml-2" onClick={() => {
                navigator.clipboard.writeText(admin.id);
                toastr.info("ID dell'amministratore copiato con successo!", "");
              }} ><i className="fas fa-copy"></i></button>
            </div>

          </div>
        }
        <div style={{ fontSize: "18px" }}><b>Informazioni di accesso</b></div>
        <div className="form-inline mt-2" style={{ maxWidth: "700px" }}>
          <label className="required"><b>Email</b></label>
          <input type="text" id="email" style={{ minWidth: "400px" }} className="form-control ml-3" value={email} onChange={(e) => setEmail(e.target.value)} required />
        </div>
        <div className="form-inline mt-2" style={{ maxWidth: "700px" }}>
          <label className="required"><b>Password</b></label>
          <input type="text" id="password" style={{ minWidth: "400px" }} className="form-control ml-3" value={password} onChange={(e) => setPassword(e.target.value)} required />
        </div>
        <div className="mt-3" style={{ fontSize: "18px" }}><b>Aree</b></div>
        {
          areas!.map((a) => {
            let checked = areaIds.includes(a.id);
            return <Checkbox id={"area" + a.id} title={a.name} checked={checked} onChange={(newVal) => {
              if (newVal && !checked)
                setAreaIds([...areaIds, a.id]);
              else if (!newVal && checked)
                setAreaIds(areaIds.filter((id) => id !== a.id));
            }} />
          })
        }
        <div className="mt-4" />

        <Checkbox checked={hasPercentageOnOrders} onChange={setHasPercentageOnOrders} id="hasPercentageOnOrders"
          title="Questo amministratore percepisce una percentuale sugli ordini consegnati nelle aree da lui amministrate." />

        {
          hasPercentageOnOrders &&
          <div className="form-inline mt-3">
            <label htmlFor="adminPercentage" className="required"><b>Ammontare fattorino per ordine (€)</b></label>
            <NumberFormat
              decimalScale={2}
              allowNegative={false}
              thousandSeparator={false}
              decimalSeparator=","
              prefix="%"
              fixedDecimalScale={true}
              id="adminPercentage"
              className="form-control ml-3"
              value={adminPercentage}
              onValueChange={({ floatValue }) => {
                if (floatValue !== adminPercentage)
                  setAdminPercentage(floatValue);
              }} required />
          </div>
        }
      </div>
      <div className="d-flex justify-content-between mt-4">
        <div className="text-danger">{error}</div>
        <button className="btn btn-success" type="submit" disabled={loading || areaIds.length === 0 || email.length === 0 || password.length === 0}>
          Salva
        </button>
      </div>
    </form>
  </div>
}

export default withRouter(AdminEditScreen);