import React, { useEffect, useState } from 'react'
import { Modal, Button } from 'react-bootstrap'
import firebase from 'firebase/app'
import { toastr } from 'react-redux-toastr';
import NumberFormat from 'react-number-format';

type ChangeOwnDriverUsageModalProps = {
  initialCanUseOwnDrivers: boolean;
  initialSupplierPercentage: number;
  initialDeliveryAmount: number;
  show: boolean;
  close: () => void;
  supplierId: string;
}

function ChangeOwnDriverUsageModal({ initialCanUseOwnDrivers, initialDeliveryAmount, initialSupplierPercentage, show, close, supplierId }: ChangeOwnDriverUsageModalProps) {
  const [deliveryAmount, setDeliveryAmount] = useState<number | undefined>(initialDeliveryAmount);
  const [supplierPercentage, setSupplierPercentage] = useState<number | undefined>(initialSupplierPercentage);
  const [canUseOwnDrivers, setCanUseOwnDrivers] = useState(initialCanUseOwnDrivers);

  useEffect(() => {
    if (show) {
      setSupplierPercentage(initialSupplierPercentage);
      setDeliveryAmount(initialDeliveryAmount);
      setCanUseOwnDrivers(initialCanUseOwnDrivers);
    }
  }, [show])

  async function save() {
    close();
    const firestore = firebase.firestore();

    try {
      await firestore.collection("suppliers").doc(supplierId).update({
        useOwnDrivers: false,
        canUseOwnDrivers,
        supplierPercentageWithOwnDrivers: canUseOwnDrivers ? supplierPercentage : null,
        deliveryAmountWithOwnDrivers: canUseOwnDrivers ? deliveryAmount : null,
      });

      toastr.success("", "Impostazione cambiata con successo!");
    }
    catch (err) {
      toastr.error("", "Errore inaspettato!");
      console.log(err);
    }
  }

  return (
    <Modal show={show} onHide={close}>
      <Modal.Header closeButton>
        <Modal.Title>Utilizzo fattorini del fornitore</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="custom-control custom-checkbox">
          <input type="checkbox" className="custom-control-input" id="canUseOwnDrivers"
            checked={canUseOwnDrivers} onChange={(e) => setCanUseOwnDrivers(e.target.checked)} />
          <label className="custom-control-label" htmlFor="canUseOwnDrivers">Il fornitore può abilitare l'impostazione di consegna con fattorini propri </label>
        </div>
        {
          canUseOwnDrivers &&
          <div className="form-group mt-4">
            <label>Percentuale fornitore con fattorini propri: </label>
            <NumberFormat
              decimalScale={2}
              allowNegative={false}
              thousandSeparator={false}
              decimalSeparator=","
              suffix="%"
              fixedDecimalScale={true}
              className="form-control"
              value={supplierPercentage === undefined ? undefined : supplierPercentage * 100.}
              isAllowed={(values) => {
                const { floatValue } = values;
                if (floatValue === undefined)
                  return true;
                return floatValue > 0 && floatValue <= 100;
              }}
              onValueChange={({ floatValue }) => {
                if (floatValue !== supplierPercentage)
                  setSupplierPercentage(floatValue === undefined ? undefined : floatValue / 100.)
              }} required />
          </div>
        }
        {
          canUseOwnDrivers &&
          <div className="form-group mt-4">
            <label>Costi di spedizione: </label>
            <NumberFormat
              decimalScale={2}
              allowNegative={false}
              thousandSeparator={false}
              decimalSeparator=","
              suffix="€"
              fixedDecimalScale={true}
              className="form-control"
              value={deliveryAmount}
              onValueChange={({ floatValue }) => {
                if (floatValue !== deliveryAmount)
                  setDeliveryAmount(floatValue)
              }} required />
          </div>
        }
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" type="button" onClick={close}>
          Annulla
        </Button>
        <Button variant="success" type="button" onClick={save} disabled={supplierPercentage === undefined}>
          Salva
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default ChangeOwnDriverUsageModal;
