import React, { Component } from 'react'
import { connect } from 'react-redux'
import { signIn } from '../store/actions/authActions'
import { Redirect } from 'react-router-dom'

class SignInScreen extends Component {
  state = {
    email: '',
    password: ''
  }
  handleChange = (e) => {
    this.setState({
      [e.target.id]: e.target.value
    })
  }
  handleSubmit = (e) => {
    e.preventDefault();
    this.props.signIn(this.state)
  }
  render() {
    const { authError, auth, location } = this.props;
    if (auth.uid) return <Redirect to={location.state ? location.state.from.pathname : "/"} />
    return (
      <div className="jumbotron d-flex align-items-center min-vh-100">
        <div className="container d-flex justify-content-center">
          <form className="form-signin bg-white p-4">
            <h1 className="h3 mb-3 font-weight-normal">Taporty - Admin Console</h1>
            <input type="email" id="email" className="form-control mt-1" placeholder="Indirizzo email" required="" autoFocus="" onChange={this.handleChange} />
            <input type="password" id="password" className="form-control mt-1" placeholder="Password" required="" onChange={this.handleChange} />
            <div className="text-danger mt-1">
              {authError ? <p>{authError}</p> : null}
            </div>
            <button className="btn btn-primary btn-block mt-2" onClick={this.handleSubmit}><i className="fas fa-sign-in-alt"></i>Accedi</button>
          </form>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    authError: state.auth.authError,
    auth: state.firebase.auth
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    signIn: (creds) => dispatch(signIn(creds))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SignInScreen)
