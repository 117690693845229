import React, { Component } from 'react'
import { connect } from 'react-redux';
import { firestoreConnect, isLoaded } from 'react-redux-firebase'
import { compose } from 'redux'
import { sortableContainer, sortableElement, sortableHandle } from 'react-sortable-hoc';
import { Link } from 'react-router-dom'
import { changeCategoryIndexes } from '../../../../store/actions/supplierActions';
import arrayMove from 'array-move';
import SplashScreen from '../../../SplashScreen';

class Category extends Component {

  render() {
    let { category } = this.props;

    let content;

    content = (
      <div>
        {category.name}
      </div>
    );

    return <div className="card my-1 card-body d-flex py-2 pl-0 pr-4">
      <div className="d-flex">
        <div className="d-flex flex-grow-1">
          <div className="d-flex my-auto"><DragHandle /></div>
          <div className="d-flex my-auto">
            {content}
          </div>
        </div>
        <div className="d-flex">
          <Link to={"/suppliers/category/" + category.id} className="my-auto"><i className="fas fa-pen highlight-on-hover" /></Link>
        </div>
      </div>
    </div>;
  }
}


const DragHandle = sortableHandle(() => <i className="fas fa-bars px-4" style={{ cursor: "row-resize", color: "grey" }}></i>);

const CategoryItem = sortableElement(({ category }) =>
  <Category category={category} />
);

const SortableCategories = sortableContainer(({ children }) => {
  return <div>{children}</div>;
});

class SupplierCategoriesSummary extends Component {
  onSortEnd = ({ oldIndex, newIndex }) => {
    let categories = this.props.supplierCategories.slice();
    categories.sort((c1, c2) => c1.index - c2.index);
    categories = arrayMove(categories, oldIndex, newIndex);
    for (let i = 0; i < categories.length; i++) {
      categories[i] = { ...categories[i], index: i };
    }
    this.props.changeCategoryIndexes(categories);
  };

  render() {
    let { supplierCategories } = this.props;

    if (!isLoaded(supplierCategories))
      return <SplashScreen />

    let supplierCategoriesSorted = supplierCategories.slice();
    supplierCategoriesSorted.sort((c1, c2) => c1.index - c2.index);

    return (
      <div className="my-4">
        <div className="d-flex justify-content-between">
          <h4 className="my-auto">Gestione categorie</h4>
          <Link className="btn btn-outline-primary" to="/suppliers/category/new">Aggiungi categoria</Link>

        </div>
        <div style={{ maxWidth: 500 }} className="mt-3">
          <SortableCategories onSortEnd={this.onSortEnd} useDragHandle lockAxis="y">
            {supplierCategoriesSorted.map((category, index) => {
              return <CategoryItem key={category.id} index={index} category={category} />
            })}
          </SortableCategories>
        </div>
      </div>
    )
  }
}


const mapStateToProps = (state) => {
  return {
    supplierCategories: state.firestore.ordered['supplier_categories'],
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    changeCategoryIndexes: (categories) => dispatch(changeCategoryIndexes(categories)),
  }
}

export default compose(
  connect(mapStateToProps),
  firestoreConnect(() => {
    return [
      {
        collection: 'supplier_categories',
      },
    ];
  }),
  connect(null, mapDispatchToProps)
)(SupplierCategoriesSummary)