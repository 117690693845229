import React, { useEffect, useState } from 'react'
import Collapsible from 'react-collapsible';
import { useSelector } from 'react-redux';
import { isLoaded, useFirestoreConnect } from 'react-redux-firebase';
import { Multiselect } from 'react-widgets';
import Admin from '../models/admin';
import Area from '../models/area';
import WorkWithUsRequest from '../models/work_with_us_request';
import { RootState } from '../store/reducers/rootReducer';
import firebase from '../config/fbConfig'
import { toastr } from 'react-redux-toastr';
import { Modal, Button } from 'react-bootstrap';
import OrderStates from '../models/order_states';
import moment from 'moment';
import ButtonWithConfirmation from '../components/ButtonWithConfirmation';
import Checkbox from '../components/Checkbox';
import SplashScreen from './SplashScreen';

function WorkWithUsDashboardScreen() {
  const [type, setType] = useState<"drivers" | "suppliers">("drivers");
  return (
    <div className="orderDashboard container my-4">
      <div className="d-flex align-items-center mb-3">
        <h2 className="m-0">Lavora con noi</h2>
        <select className="form-control ml-3" style={{ maxWidth: "200px" }} id="type" value={type} onChange={(e) => setType(e.target.value as "drivers" | "suppliers")}>
          <option value="drivers">Fattorini</option>
          <option value="suppliers">Fornitori</option>
        </select>
      </div>
      <WorkWithUsContent type={type} />
    </div>
  )
}

export default WorkWithUsDashboardScreen;

type WorkWithUsContentProps = { type: "drivers" | "suppliers" };
type RequestStatus = "VALID" | "NOT_VALID" | "TO_CONTACT" | "CONTACTED";

function WorkWithUsContent({ type }: WorkWithUsContentProps) {
  const admin = useSelector((state: RootState) => state.firestore.data.currentAdmin as Admin);

  const collectionName = type === "drivers" ? "driver_work_with_us" : "supplier_work_with_us";

  useFirestoreConnect([
    {
      collection: "areas",
      ...!admin.isMainAdmin ? {
        where: ["id", "in", admin.areaIds]
      } : {},
      orderBy: ['name', 'asc'],
    },
    {
      collection: collectionName,
      storeAs: "workWithUsRequests"
    },
  ]);

  const areas = useSelector((state: RootState) => state.firestore.ordered.areas as Area[]);
  let requests = useSelector((state: RootState) => state.firestore.ordered.workWithUsRequests as WorkWithUsRequest[]);

  const [showHidden, setShowHidden] = useState(false);
  const [areaIds, setAreaIds] = useState<string[]>([]);
  const [status, setStatus] = useState<RequestStatus[]>([]);
  const [changeStatusLoading, setChangeStatusLoading] = useState(false);

  async function toggleHidden(requestId: string, hidden: boolean) {
    const firestore = firebase.firestore();

    setChangeStatusLoading(true);
    try {
      await firestore.collection(collectionName).doc(requestId).update({ hidden });
    }
    catch (err) {
      console.log(err);
      toastr.error("Errore inaspettato!", "");
    }
    finally {
      setChangeStatusLoading(false);
    }
  }

  async function changeStatus(requestId: string, status: RequestStatus, notes: string) {
    const firestore = firebase.firestore();

    setChangeStatusLoading(true);
    try {
      await firestore.collection(collectionName).doc(requestId).update({
        status: status === "TO_CONTACT" ? null : status,
        notes,
      });

      toastr.success("Stato della richiesta cambiato con successo!", "");
    }
    catch (err) {
      console.log(err);
      toastr.error("Errore inaspettato durante il cambiamento dello stato della richiesta!", "");
    }
    finally {
      setChangeStatusLoading(false);
    }
  }

  if (!isLoaded(areas, requests))
    return <SplashScreen />;

  requests = requests.filter((r) => showHidden || r.hidden !== true)
    .filter((r) => (areaIds.length === 0 ? areas.map((a) => a.id) : areaIds).includes(r.areaId))
    .filter((r) => (status.length === 0 ? ["VALID", "NOT_VALID", "TO_CONTACT", "CONTACTED"] : status).includes(r.status ?? "TO_CONTACT"));

  requests.sort((r1, r2) => {
    const r1ToContact = r1.status === undefined || r1.status === null;
    const r2ToContact = r2.status === undefined || r2.status === null;

    if (r1ToContact && !r2ToContact)
      return -1;
    if (r2ToContact && !r1ToContact)
      return 1;
    return r1.timestamp.toMillis() > r2.timestamp.toMillis() ? -1 : 1;
  })

  return <div>
    <div className="d-flex align-items-center flex-wrap mb-2">
      <div className="d-flex align-items-center mr-4">
        <label className="required mr-3 my-0">Area dove si vuole lavorare</label>
        <Multiselect
          valueField='id'
          textField='name'
          data={areas}
          value={areaIds.map((a) => ({ id: a }))}
          onChange={(areas) => setAreaIds(areas.map((s) => s.id))}
          messages={{
            emptyList: 'Non ci sono altre aree disponibili',
            noneSelected: "Tutte le aree"
          }}
        />
      </div>
      <div className="d-flex align-items-center mr-4">
        <label className="required mr-3 my-0">Stato</label>
        <Multiselect
          valueField='id'
          textField='name'
          data={[
            { id: "VALID", name: "Adatto" },
            { id: "NOT_VALID", name: "Non adatto" },
            { id: "TO_CONTACT", name: "Da contattare" },
            { id: "CONTACTED", name: "Contattato" },
          ]}
          value={status.map((s) => ({ id: s }))}
          onChange={(status) => setStatus(status.map((s) => s.id))}
          messages={{
            emptyList: 'Non ci sono altri stati disponibili',
            noneSelected: "Tutte le richieste"
          }}
        />
      </div>
      <Checkbox checked={showHidden} onChange={setShowHidden} id="showHidden" title="Mostra anche le richieste nascoste" />
    </div>
    <div className="mt-4">
      {
        requests.length === 0 && "Nessuna richiesta in base ai criteri di ricerca."
      }
      {
        requests.map((r) => <div className="my-2 card card-body py-2" key={r.id}>
          <div className="d-flex justify-content-between flex-wrap mb-2">
            <div>
              {r.hidden === true && <div className="text-grey"><i>Richiesta nascosta</i></div>}
              <div className="d-flex align-items-center">
                <i className={"fas fa-circle mr-1 fa-xs " + (!r.status ? "text-warning" : (r.status === "CONTACTED" ? "text-primary" : (r.status === "NOT_VALID" ? "text-danger" : "text-success")))} />
                <div className="text-grey"> {!r.status ? "Da contattare" : (r.status === "CONTACTED" ? "Contattato" : (r.status === "NOT_VALID" ? "Non adatto" : "Adatto"))}</div>
              </div>
            </div>
            <div className="d-flex">
              {
                r.hidden === true ?
                  <button className="btn btn-sm btn-outline-primary" disabled={changeStatusLoading} onClick={() => toggleHidden(r.id, false)}>Mostra</button> :
                  <ButtonWithConfirmation className="btn btn-sm btn-outline-secondary" buttonText="Nascondi" disabled={changeStatusLoading} onConfirm={() => toggleHidden(r.id, true)}
                    modalTitle="Nascondi richiesta" modalMessage="Sicuro di voler nascondere la richiesta?" />
              }
              <div className="mx-1" />
              <ChangeStatusButton disabled={changeStatusLoading}
                initialState={r.status ?? "TO_CONTACT"} initialNotes={r.notes}
                onConfirm={(newStatus, newNotes) => changeStatus(r.id, newStatus, newNotes)} />
            </div>
          </div>

          <div className="d-flex justify-content-between flex-wrap">
            <div>
              <div>Data: <b>{moment(r.timestamp.toMillis()).format("D MMMM YYYY")}</b></div>
              <div>Indirizzo di provenienza: <b>{r.address}</b></div>
              <div>Area dove vuole lavorare: <b>{areas.find((a) => a.id === r.areaId)!.name}</b></div>
            </div>
            <div>
              <div>Nome: <b>{r.name}</b></div>
              <div>Email: <b>{r.email}</b></div>
              <div>Numero di telefono: <b>{r.phoneNumber}</b></div>
            </div>
          </div>
          {
            r.notes && r.notes.length > 0 &&
            <div className="mt-2">
              Note di contatto: <i>{r.notes!}</i>
            </div>
          }
        </div>
        )
      }
    </div>
  </div>
}

type ChangeStatusButtonProps = {
  disabled: boolean;
  initialState: RequestStatus;
  initialNotes?: string;
  onConfirm: (newState: RequestStatus, newNotes: string) => void
}

function ChangeStatusButton({ disabled, initialState, initialNotes = "", onConfirm }: ChangeStatusButtonProps) {
  const [showModal, setShowModal] = useState(false);
  const [state, setState] = useState(initialState);
  const [notes, setNotes] = useState(initialNotes);

  useEffect(() => {
    if (showModal) {
      setState(initialState);
      setNotes(initialNotes);
    }
  }, [showModal])

  return (
    <div>
      <button className="btn btn-outline-warning btn-sm" onClick={() => setShowModal(true)} disabled={disabled}>Cambia stato</button>
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <form onSubmit={(e) => {
          e.preventDefault();
          onConfirm(state, notes);
          setShowModal(false);
        }}>
          <Modal.Header closeButton>
            <Modal.Title>{"Stato della richiesta"}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="form-group">
              <label className="required">Nuovo stato della richiesta</label>
              <select className="form-control" id="orderState" value={state} required onChange={(e) => setState(e.target.value as RequestStatus)}>
                <option value={"TO_CONTACT"}>Da contattare</option>
                <option value={"CONTACTED"}>Contattato</option>
                <option value={"VALID"}>Adatto</option>
                <option value={"NOT_VALID"}>Non adatto</option>
              </select>
              <div className="form-group mt-3">
                <label>Note di contatto</label>
                <textarea className="form-control" style={{ maxWidth: "500px" }} value={notes} onChange={(e) => setNotes(e.target.value)} />
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" type="button" onClick={() => setShowModal(false)} >
              Annulla
            </Button>
            <Button variant="primary" type="submit">
              Cambia stato
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </div>
  )
}