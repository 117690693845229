import React, { Component } from 'react'
import { Modal, Button } from 'react-bootstrap'

class ButtonWithConfirmationAndTextArea extends Component {
  state = {
    showModal: false,
    text: "",
  }

  handleClick = () => {
    this.setState({
      showModal: true,
    })
  }

  handleChange = (e) => {
    this.setState({
      text: e.target.value,
    });
  }

  handleCancel = () => {
    this.setState({
      showModal: false,
    })
  }

  handleConfirm = (e) => {
    e.preventDefault();
    this.props.onConfirm(this.state.text);
    this.setState({
      showModal: false,
    })
  }

  render() {
    return (
      <div>
        <button className={this.props.className ?? "btn btn-primary"} style={this.props.style} onClick={this.handleClick}>{this.props.buttonText}</button>
        <Modal show={this.state.showModal} onHide={this.handleCancel}>
          <form onSubmit={this.handleConfirm}>
            <Modal.Header closeButton>
              <Modal.Title>{this.props.modalTitle ?? "Confermi?"}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {
                this.props.modalMessage
              }
              <div className="form-group mt-3">
                <label htmlFor="text" className="required">{this.props.textAreaTitle}</label>
                <textarea className="form-control" style={{ minHeight: 100 }} id="text" onChange={this.handleChange} value={this.state.text} required/>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" type="button" onClick={this.handleCancel} >
                Annulla
            </Button>
              <Button variant="primary" type="submit">
                Conferma
            </Button>
            </Modal.Footer>
          </form>
        </Modal>
      </div>
    )
  }
}
export default ButtonWithConfirmationAndTextArea