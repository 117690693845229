import React, { useEffect, useState } from 'react'
import { Modal, Button } from 'react-bootstrap'
import firebase from 'firebase/app'
import { toastr } from 'react-redux-toastr';
import NumberFormat from 'react-number-format';

type ChangeDeliveryFieldModalProps = {
  initialValue: number;
  show: boolean;
  close: () => void;
  supplierId: string;
  fieldId: string;
  title: string;
}

function ChangeDeliveryFieldModal({ initialValue, show, close, supplierId, fieldId, title }: ChangeDeliveryFieldModalProps) {
  const [value, setValue] = useState<number | undefined>(initialValue ?? 2.9);

  useEffect(() => {
    if (show) {
      setValue(initialValue);
    }
  }, [show])

  async function save() {
    close();
    const firestore = firebase.firestore();

    try {
      await firestore.collection("suppliers").doc(supplierId).update({
        [fieldId]: value,
      });

      toastr.success("Impostazione cambiata con successo!", "");
    }
    catch (err) {
      toastr.error("Errore inaspettato!", "");
      console.log(err);
    }
  }

  return (
    <Modal show={show} onHide={close}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="mt-2 d-flex align-items-center">
          <div className="mr-2">Valore: </div>
          <NumberFormat
            decimalScale={2}
            allowNegative={false}
            thousandSeparator={false}
            decimalSeparator=","
            suffix={"€"}
            fixedDecimalScale={true}
            className="form-control"
            value={value}
            onValueChange={({ floatValue }) => {
              if (floatValue !== value)
                setValue(floatValue)
            }} required />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" type="button" onClick={close}>
          Annulla
        </Button>
        <Button variant="success" type="button" onClick={save}>
          Salva
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default ChangeDeliveryFieldModal;
