import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import moment from 'moment';
import { compose } from 'redux';
import { firestoreConnect, isLoaded } from 'react-redux-firebase';
import { connect } from 'react-redux';
import ShiftsList from './components/ShiftsList';

class ShiftsAreaScreen extends Component {
  constructor() {
    super();
    let today = moment();
    this.state = {
      selectedDate: today.format("YYYY-MM-DD"),
      alsoEmpty: false,
    }
  }

  onChange = (e) => {
    if (e.target.value !== "")
      this.setState({
        selectedDate: e.target.value,
      });
  }

  onAlsoEmptyChange = () => {
    this.setState({
      alsoEmpty: !this.state.alsoEmpty,
    });
  }


  render() {
    const { area, admin } = this.props;
    if (!isLoaded(area))
      return <div />;

    if (!admin.isMainAdmin && !admin.areaIds.includes(area.id))
      return <div className="container my-4">Non hai accesso alla visualizzazione di questa pagina.</div>;

    return (
      <div className="my-4 container">
        <div className="d-flex justify-content-between align-items-center">
          <div>
            <h4 className="my-auto">Turni</h4>
            <h6 className="mt-0" style={{ color: "#777777" }}>{area.name}</h6>
          </div>
          <div>
            <Link to={"/shifts/area/" + this.props.match.params.areaId + "/config"}>Configurazione turni</Link>
          </div>
        </div>
        <div className="d-flex justify-content-between align-items-center flex-wrap my-3">
          <div className="form-inline">
            <div className="form-group d-flex ">
              <label htmlFor="selectedDate">Data di visualizzazione</label>
              <input type="date" id="selectedDate" className="form-control mx-sm-3" value={this.state.selectedDate} onChange={this.onChange} />
            </div>
          </div>
          <div className="custom-control custom-checkbox d-flex">
            <input type="checkbox" className="custom-control-input" id="alsoEmpty" value={this.state.alsoEmpty} onChange={this.onAlsoEmptyChange} required />
            <label className="custom-control-label" htmlFor="alsoEmpty">Mostra anche i turni con nessuna prenotazione</label>
          </div>
        </div>
        <ShiftsList selectedDate={this.state.selectedDate} areaId={this.props.match.params.areaId} alsoEmpty={this.state.alsoEmpty} shiftLength={area.shiftLength} />
      </div>
    );
  }
}



const mapStateToProps = (state) => {
  return {
    area: state.firestore.data.area,
    admin: state.firestore.data.currentAdmin,
  }
}

export default withRouter(compose(
  connect(mapStateToProps),
  firestoreConnect((props) => [
    {
      collection: 'areas',
      doc: props.match.params.areaId,
      storeAs: 'area',
    },
  ]))(ShiftsAreaScreen));