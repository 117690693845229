import React, { useEffect, useState } from 'react'
import { Modal, Button } from 'react-bootstrap'
import firebase from 'firebase/app'
import { toastr } from 'react-redux-toastr';

type ChangeResponsibleForCashModalProps = {
  initialResponsibleForCash: boolean;
  show: boolean;
  close: () => void;
  supplierId: string;
}

function ChangeResponsibleForCashModal({ initialResponsibleForCash, show, close, supplierId }: ChangeResponsibleForCashModalProps) {
  const [responsibleForCash, setResponsibleForCash] = useState(initialResponsibleForCash);

  useEffect(() => {
    if (show) {
      setResponsibleForCash(initialResponsibleForCash);
    }
  }, [show])

  async function save() {
    close();
    const firestore = firebase.firestore();

    try {
      await firestore.collection("suppliers").doc(supplierId).update({
        responsibleForCash,
      });

      toastr.success("", "Impostazione cambiata con successo!");
    }
    catch (err) {
      toastr.error("", "Errore inaspettato!");
      console.log(err);
    }
  }

  return (
    <Modal show={show} onHide={close}>
      <Modal.Header closeButton>
        <Modal.Title>Aggiunta di ordini da parte del fornitore</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="custom-control custom-checkbox">
          <input type="checkbox" className="custom-control-input" id="responsibleForCash"
            checked={responsibleForCash} onChange={(e) => setResponsibleForCash(e.target.checked)} />
          <label className="custom-control-label" htmlFor="responsibleForCash">
            Il fornitore è responsabile della gestione dei Soldi alla mano dei suoi ordini
            (il fattorino dovrà anticipare i soldi dell'ordine al fornitore nel caso di pagamento in contanti)
          </label>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" type="button" onClick={close}>
          Annulla
        </Button>
        <Button variant="success" type="button" onClick={save}>
          Salva
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default ChangeResponsibleForCashModal;
