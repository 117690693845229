import React, { useState } from 'react'
import { Modal, Button } from 'react-bootstrap'
import NumberFormat from 'react-number-format'

type BalancePaidModalProps = {
  show: boolean;
  onCancel: () => void;
  onSave: (val: number) => void;
  maxAmount?: number;
  driverCash?: boolean;
}

function BalancePaidModal({ onCancel, onSave, show, maxAmount, driverCash = false }: BalancePaidModalProps) {
  const [balancePaid, setBalancePaid] = useState<number | undefined>(0);
  return (
    <Modal show={show} onHide={onCancel}>
      <form onSubmit={(e) => {
        e.preventDefault();
        onSave(balancePaid!);
        setBalancePaid(0);
      }}>
        <Modal.Header closeButton>
          <Modal.Title>{driverCash === true ? "Soldi alla mano" : "Pagamento del saldo"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-group">
            <label className="required">Ammontare pagato</label>
            <NumberFormat
              decimalScale={2}
              allowNegative={false}
              thousandSeparator={false}
              decimalSeparator=","
              prefix="€"
              isAllowed={(values) => {
                const { floatValue } = values;
                return floatValue === undefined || (floatValue >= 0 && (maxAmount === undefined || floatValue <= maxAmount));
              }}
              fixedDecimalScale={true}
              className="form-control"
              value={balancePaid}
              onValueChange={({ floatValue }) => {
                if (floatValue === balancePaid)
                  return;
                setBalancePaid(floatValue);
              }} required />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" type="button" onClick={onCancel}>
            Annulla
          </Button>
          <Button variant="success" type="submit">
            Conferma
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  )
}

export default BalancePaidModal;
