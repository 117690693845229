import React, { Component } from 'react'
import AreaSelectView from '../../components/AreaSelectView';

class ShiftsDashboardScreen extends Component {
    render() {
        return (
            <div className="driverDashboard container my-4">
                <h2>Turni</h2>
                <AreaSelectView urlPrefix="shifts" />
            </div>
        )
    }
}



export default ShiftsDashboardScreen;