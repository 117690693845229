import { toastr } from "react-redux-toastr";

export const updateRequestState = (requestId, state, reason = "") => {
  return async (dispatch, getState, { getFirebase }) => {
    let firebase = getFirebase();
    let firestore = firebase.firestore();
    let documentRef = firestore.collection("driver_requests").doc(requestId);
    try {
      await documentRef.update({ 'state': state, 'reason': reason });

      if (state === "ACCEPTED")
        toastr.success('Richiesta accettata con successo!');
      else if (state === "REFUSED")
        toastr.success('Richiesta rifiutata con successo!');
    }
    catch (err) {
      toastr.error(`Errore inaspettato!`);
      console.log(err);
    }
  }
};

export const toggleDisabledDriver = (driverId, disabled) => {
  return async (dispatch, getState, { getFirebase }) => {
    const firebase = getFirebase();
    const functions = firebase.functions();

    try {
      const toggleDisabledDriver = functions.httpsCallable("toggleDisabledDriver");
      const response = await toggleDisabledDriver({ driverId, disabled });
      if (response.data.success === false) {
        toastr.error("Errore: " + response.data.error)
      }
      else {
        toastr.success("Fattorino " + (disabled ? "disabilitato" : "abilitato") + " con successo")
      }
    }
    catch (err) {
      console.log(err);
      toastr.error("Errore inaspettato!");
    }
  }
};


export const editDriverField = (driverId, field, data) => {
  return async (dispatch, getState, { getFirebase }) => {
    const firebase = getFirebase();
    let firestore = firebase.firestore();
    let documentRef = firestore.collection("drivers").doc(driverId);

    try {
      await documentRef.update({ [field]: data });
    }
    catch (err) {
      console.log(err);
      toastr.error("Errore inaspettato!");
    }
  }
};

