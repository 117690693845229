import firebase from 'firebase/app'

export type DiscountCodeType = "SINGLE_CUSTOMER" | "SINGLE_SUPPLIER" | "SINGLE_AREA" | "ALL_CUSTOMERS" | "REWARD" | "BRING_A_FRIEND" | "SPECIAL";

export function codeTypeToString(type: DiscountCodeType): string {
  switch (type) {
    case "ALL_CUSTOMERS":
      return "Sconto per tutti i clienti";
    case "SINGLE_CUSTOMER":
      return "Sconto per clienti singoli";
    case "SINGLE_SUPPLIER":
      return "Sconto per fornitore";
    case "SINGLE_AREA":
      return "Sconto per area";
    case "REWARD":
      return "Ricompensa punti";
    case "BRING_A_FRIEND":
      return "Presenta un amico";
    case "SPECIAL":
      return "Speciale";
  }
}

export type WeekdayNumber = 1 | 2 | 3 | 4 | 5 | 6 | 7;

export type WeekdayValidity = {
  1: boolean,
  2: boolean,
  3: boolean,
  4: boolean,
  5: boolean,
  6: boolean,
  7: boolean,
};

export const defaultWeekdayValidity = {
  1: true,
  2: true,
  3: true,
  4: true,
  5: true,
  6: true,
  7: true,
}


type DiscountCode = {
  active: boolean;
  deleted: boolean;
  type: DiscountCodeType;

  startDate: firebase.firestore.Timestamp | null;
  endDate: firebase.firestore.Timestamp | null;

  startTime?: string;
  endTime?: string;

  isPeriodic?: boolean;
  weekdayValidity?: WeekdayValidity;

  id: string;
  code: string;

  hasFixedAmount: boolean;
  amount: number;
  percentage: number;
  hasFreeDelivery: boolean;

  pointsThreshold: number;

  supplierId: string;
  areaId: string;

  isPromo?: boolean;
  promoMessage?: string;
  promoTitle?: string

  bringAFriendData?: {
    status: "PENDING" | "ACTIVE";
    friendId: string;
    friendName: string | null;
    showPopup: boolean;
    date: firebase.firestore.Timestamp,
    customerId: string,
  }
}

export default DiscountCode