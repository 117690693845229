
import React from "react";
import { DriverAmounts, DriverCompleteAmounts, getDriverCompleteAmounts } from "../../../models/driver_report";
import AmountsCard from "./AmountsCard";
import { intToCurrency } from "../../../helpers/currencyHelper";
import { getSupplierCompleteAmounts, SupplierAmounts, SupplierCompleteAmounts } from "../../../models/supplier_report";

type ReportCardProps = {
    amounts: DriverAmounts | SupplierAmounts,
    showCash: boolean,
    useCashToPay: boolean,
    type: "driver" | "supplier"
}

function ReportCard({ amounts, useCashToPay, showCash, type }: ReportCardProps) {
    let completeAmounts: DriverCompleteAmounts | SupplierCompleteAmounts;

    if (type === "driver") completeAmounts = getDriverCompleteAmounts(amounts as DriverAmounts);
    else completeAmounts = getSupplierCompleteAmounts(amounts as SupplierAmounts);

    return <>
        <AmountsCard amounts={amounts} showCash={showCash}
            entityType={type}
            otherComponents={useCashToPay ?
                <>
                    <div>
                        <b>{intToCurrency(Math.min(completeAmounts.cashDiffAmount, completeAmounts.totalAmount))}</b> di contanti utilizzati per pagare il {type === "driver" ? "fattorino" : "fornitore"}.
                    </div>
                    {
                        completeAmounts.totalAmount > completeAmounts.cashDiffAmount &&
                        <div>I restanti <b>{intToCurrency(completeAmounts.totalAmount - completeAmounts.cashDiffAmount)}</b> di entrate sono stati pagati tramite bonifico.</div>
                    }
                    {
                        completeAmounts.totalAmount < completeAmounts.cashDiffAmount &&
                        <div>Rimangono <b>{intToCurrency(completeAmounts.cashDiffAmount - completeAmounts.totalAmount)}</b> di contanti da pagare alla piattaforma</div>
                    }
                </> : undefined
            } />
    </>
}

export default ReportCard;