import React, { useState } from 'react';
import { isLoaded, useFirestoreConnect } from 'react-redux-firebase';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { RootState } from '../../store/reducers/rootReducer';
import Admin from '../../models/admin';
import Checkbox from '../../components/Checkbox';
import SplashScreen from '../SplashScreen';

function AdminDashboardScreen() {
  useFirestoreConnect([{
    collection: "admin",
    storeAs: "admins"
  }]);

  let admin: Admin[] | undefined = useSelector((state: RootState) => state.firestore.ordered.admins);
  const [alsoDisabled, setAlsoDisabled] = useState(false);

  if (!isLoaded(admin))
    return <SplashScreen />;

  admin = admin!.filter((a) => !a.isMainAdmin && (alsoDisabled || a.disabled !== true));

  const adminWithPercentage = admin.filter((a) => a.hasPercentageOnOrders);
  const adminWithoutPercentage = admin.filter((a) => !a.hasPercentageOnOrders);

  return <div className="my-3 container">
    <div className="d-flex justify-content-between align-items-center mb-3" style={{ maxWidth: "700px" }}>
      <h4 className="m-0">Gestione amministratori</h4>
      <Link className="btn btn-outline-primary" to="admin/edit">Aggiungi amministratore</Link>
    </div>
    <Checkbox checked={alsoDisabled} onChange={setAlsoDisabled} id="alsoDisabled" title="Mostra anche gli amministratori disabilitati" />
    <h5 className="mt-4">Admin con percentuale sugli ordini</h5>
    {adminWithPercentage!.map((admin) =>
      <div key={admin.id} className="card card-body col-12 col-md-6 col-lg-4 py-2 mb-1">
        <Link to={"/admin/" + admin.id}>{admin.email}</Link>
      </div>
    )}

    {adminWithPercentage!.length === 0 && <div>Nessun account amministratore con percentuale sugli ordini.</div>}
    {
      adminWithoutPercentage.length > 0 &&
      <>
        <h5 className="mt-4">Altri admin</h5>
        {adminWithoutPercentage!.map((admin) =>
          <div key={admin.id} className="card card-body col-12 col-md-6 col-lg-4 py-2 mb-1">
            <Link to={"/admin/" + admin.id}>{admin.email}</Link>
          </div>
        )}
      </>
    }
  </div>
}

export default AdminDashboardScreen;