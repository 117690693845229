import moment from "moment";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useFirestoreConnect, isLoaded, isEmpty } from "react-redux-firebase";
import { Link } from "react-router-dom";
import Admin from "../../../models/admin";
import Supplier from "../../../models/supplier";
import SupplierCategory from "../../../models/supplier_category";
import { RootState } from "../../../store/reducers/rootReducer";
import { intToCurrency } from "../../../helpers/currencyHelper";
import { getDateString } from "../../../helpers/dateHelper";
import SplashScreen from "../../SplashScreen";
import { getSupplierCompleteAmounts } from "../../../models/supplier_report";

type SupplierListProps = {
    areaId: string;
    category?: SupplierCategory;
}

function SupplierList({ areaId, category }: SupplierListProps) {
    const [searchBar, setSearchBar] = useState("");
    const [alsoDisabled, setAlsoDisabled] = useState(false);
    const [onlyWithReportToDo, setOnlyWithReportToDo] = useState(false);

    const admin = useSelector((state: RootState) => state.firestore.data.currentAdmin as Admin);

    useFirestoreConnect({
        collection: 'suppliers',
        where: ['areaId', '==', areaId],
        orderBy: ['name', 'asc']
    });


    let suppliers = useSelector((state: RootState) => state.firestore.ordered.suppliers as Supplier[]);

    if (!admin.isMainAdmin && !admin.areaIds!.includes(areaId))
        return <div className="container my-4">Non hai accesso alla visualizzazione di questa pagina.</div>;

    if (!isLoaded(suppliers)) {
        return <SplashScreen />
    }

    if (category)
        suppliers = suppliers.filter((s) => s.category === category.id);

    suppliers = suppliers.filter((s) => {
        const { totalAmount, cashDiffAmount } = getSupplierCompleteAmounts(s.amounts);
        return s.name.toLowerCase().includes(searchBar.toLowerCase().replace(/\s+/g, '')) &&
            (alsoDisabled || s.disabled === false) &&
            (!onlyWithReportToDo || totalAmount > 0 || cashDiffAmount !== 0);
    });

    if (onlyWithReportToDo)
        suppliers.sort((s1, s2) => {
            if (!s1.lastReportDate)
                return -1;
            if (!s2.lastReportDate)
                return 1;
            return moment(s1.lastReportDate.toMillis()).isSameOrBefore(moment(s2.lastReportDate.toMillis())) ? -1 : 1;
        });


    if (onlyWithReportToDo)
        suppliers.sort((d1, d2) => {
            if (!d1.lastReportDate)
                return -1;
            if (!d2.lastReportDate)
                return 1;
            return moment(d1.lastReportDate.toMillis()).isSameOrBefore(moment(d2.lastReportDate.toMillis())) ? -1 : 1;
        });

    let supplierComponents;

    if (isEmpty(suppliers))
        supplierComponents = (
            <div>
                Nessun fornitore in base ai criteri di ricerca.
            </div>
        );
    else
        supplierComponents = suppliers.map((supplier) => {
            const { totalAmount, cashDiffAmount } = getSupplierCompleteAmounts(supplier.amounts);
            return (
                <div className="card my-2" key={supplier.reference.id}>
                    <Link to={"/suppliers/" + supplier.reference.id} className="card-link">
                        <div className="card-body d-flex justify-content-between" style={{ fontSize: 14 }}>
                            <div className="mr-2">
                                <div><b>ID:</b> {supplier.reference.id}</div>
                                <div><b>Nome:</b> {supplier.name}</div>
                            </div>
                            <div style={{ textAlign: "right" }}>
                                <div><b>Da pagare:</b> {intToCurrency(totalAmount)}</div>
                                {(supplier.canUseOwnDrivers || supplier.responsibleForCash) &&
                                    <div><b>Soldi alla mano: </b> {intToCurrency(cashDiffAmount)}</div>}
                                <div><b>Ultimo report:</b> {supplier.lastReportDate ? getDateString(supplier.lastReportDate) : "-"}</div>
                            </div>
                        </div>
                    </Link>
                </div>
            )
        });

    return (
        <div className="supplierList pb-3">
            <div className="d-flex align-items-center justify-content-between">
                <div>
                    <h4 className="mb-0">Fornitori</h4>
                    <h6 className="mt-0" style={{ color: "#777777" }}>{category ? "Categoria '" + category.name + "'" : "Tutte le categorie"}</h6>
                </div>
                {category &&
                    <Link className="btn btn-outline-primary" to={"/suppliers/category/" + category.id}>Modifica categoria</Link>}
            </div>
            <div className="d-flex flex-wrap justify-content-between my-3 align-items-center">
                <div className="form-inline">
                    <div className="form-group">
                        <label htmlFor="searchBar">Cerca per nome</label>
                        <input type="text" id="searchBar" className="form-control mx-sm-3" value={searchBar} onChange={(e) => setSearchBar(e.target.value)} />
                    </div>
                </div>
                <div>
                    <div className="custom-control custom-checkbox">
                        <input type="checkbox" className="custom-control-input" id="onlyWithReportToDo" checked={onlyWithReportToDo} onChange={(e) => setOnlyWithReportToDo(e.target.checked)} />
                        <label className="custom-control-label" htmlFor="onlyWithReportToDo">Mostra solo fornitori con nuovi movimenti</label>
                    </div>
                    <div className="custom-control custom-checkbox">
                        <input type="checkbox" className="custom-control-input" id="alsoDisabled" checked={alsoDisabled} onChange={(e) => setAlsoDisabled(e.target.checked)} />
                        <label className="custom-control-label" htmlFor="alsoDisabled">Mostra anche i fornitori disabilitati</label>
                    </div>
                </div>
            </div>
            {supplierComponents}
        </div>
    )

}

export default SupplierList;