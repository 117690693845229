import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { isLoaded, useFirestoreConnect } from 'react-redux-firebase';
import { useSelector } from 'react-redux';
import OrderHistory from './OrderHistory';
import { RootState } from '../../store/reducers/rootReducer';
import Area from '../../models/area';
import Admin from '../../models/admin';

function OrderAreaScreen(props: RouteComponentProps<{ areaId: string }, any, any>) {
    useFirestoreConnect(() => [
        {
            collection: 'areas',
            doc: props.match.params.areaId,
            storeAs: 'area',
        },
    ])
    const { area, admin } = useSelector((state: RootState) => ({

        area: state.firestore.data.area as Area,
        admin: state.firestore.data.currentAdmin as Admin,
    }));
    if (!isLoaded(area))
        return <div />;

    if (!admin.isMainAdmin && !admin.areaIds!.includes(area.id))
        return <div className="container my-4">Non hai accesso alla visualizzazione di questa pagina.</div>;

    return (
        <div className="my-4 container">
            <OrderHistory area={area} />
        </div>
    );
}


export default withRouter(OrderAreaScreen);