import React from "react";
import { useSelector } from "react-redux";
import { useFirestoreConnect, isLoaded, isEmpty } from "react-redux-firebase";
import { Link, RouteComponentProps, withRouter } from "react-router-dom";
import ReactExport from "react-export-excel";
import { intToCurrency } from "../../helpers/currencyHelper";
import { getDateString } from "../../helpers/dateHelper";
import Admin from "../../models/admin";
import Supplier from "../../models/supplier";
import SupplierReport, { getSupplierCompleteAmounts } from "../../models/supplier_report";
import { RootState } from "../../store/reducers/rootReducer";
import SplashScreen from "../SplashScreen";
import ReportView from "./components/ReportView";


type SuppliersReportsScreenProps = {
  supplierId?: string
}

function SuppliersReportsScreen({ supplierId }: SuppliersReportsScreenProps) {
  const isSingleSupplier = supplierId !== undefined;
  const supplier = useSelector((state: RootState) => supplierId === undefined ? null : state.firestore.data.supplier as Supplier);
  const reports = useSelector((state: RootState) => state.firestore.ordered.reports as SupplierReport[]);
  const admin = useSelector((state: RootState) => state.firestore.data.currentAdmin as Admin);

  useFirestoreConnect(() => supplierId === undefined ? [
    {
      collection: "supplier_reports",
      storeAs: "reports",
    },
  ] : [
    {
      collection: "suppliers",
      doc: supplierId,
      storeAs: "supplier",
    },
    {
      collection: "supplier_reports",
      where: ["supplierId", "==", supplierId!],
      storeAs: "reports",
    },
  ]);

  if (!isLoaded(reports, admin) || (isSingleSupplier && !isLoaded(supplier)))
    return <SplashScreen />;

  if (isSingleSupplier && isEmpty(supplier))
    return <div className="container my-4">Fornitore inesistente.</div>;

  if (!admin.isMainAdmin && (!isSingleSupplier || !admin.areaIds!.includes(supplier!.areaId)))
    return <div className="container my-4">Non hai accesso alla visualizzazione di questo fornitore.</div>;

  let exportFileName = "Resoconti";
  if (isSingleSupplier)
    exportFileName += "_fornitore_" + supplierId;
  else
    exportFileName += "_fornitori";

  return <div className="container my-4">
    <div className="d-flex justify-content-between align-items-center mb-2">
      <div>
        <h2>Elenco resoconti {isSingleSupplier ? "del fornitore" : "dei fornitori"}</h2>
        {
          isSingleSupplier && <h4>{supplier!.name}</h4>
        }
      </div>
      <div className="d-flex flex-column align-items-end">
        {
          isSingleSupplier &&
          <div className="d-flex mb-2">
            <Link to={"/suppliers/" + supplierId + "/reports/create"} className="btn btn-outline-primary">Genera resoconto</Link>
            <Link to={"/suppliers/" + supplierId + "/reports"} className="btn btn-outline-secondary ml-2">Visualizza entrate</Link>
          </div>
        }
        <ReportExportExcel
          fileName={exportFileName}
          reports={reports} />
      </div>
    </div>
    <div className="py-1" />
    {reports.length === 0 && "Nessun resoconto ancora creato."}
    {reports.map((report) => <ReportView
      report={report}
      type="supplier"
      key={report.id}
      showEntityInfo={!isSingleSupplier}
    />)}
  </div>
}


export default SuppliersReportsScreen;

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

type ReportExportExcel = {
  reports: SupplierReport[],
  fileName: string,
}

function ReportExportExcel({ reports, fileName }: ReportExportExcel) {
  console.log(reports)
  const reportToData = (r: SupplierReport) => {
    let completeReport = getSupplierCompleteAmounts(r);
    return ({
      date: getDateString(r.datetime),
      id: r.id,
      totalAmount: intToCurrency(completeReport.totalAmount),
      cashDiffAmount: intToCurrency(completeReport.cashDiffAmount),
      supplierName: r.supplierName,
      supplierId: r.supplierId,
    });
  };

  return <ExcelFile element={<button className="btn btn-outline-success">Esporta resoconti</button>}
    filename={fileName}>
    <ExcelSheet data={reports.filter((r) => r.useCashToPay).map(reportToData)}
      name={"Resoconti - Contanti usati come compenso"}>
      <ExcelColumn label="CODICE RESOCONTO" value="id" />
      <ExcelColumn label="DATA RESOCONTO" value="date" />
      <ExcelColumn label="NOME" value="supplierName" />
      <ExcelColumn label="CODICE FORNITORE" value="supplierId" />
      <ExcelColumn label="TOTALE ENTRATE (IVA INCLUSA)" value="totalAmount" />
      <ExcelColumn label="CONTANTI USATI COME COMPENSO" value="cashDiffAmount" />
    </ExcelSheet>
  </ExcelFile>;
}

/*
function ReportExportExcel({ reports, fileName }: ReportExportExcel) {
  console.log(reports)
  const reportToData = (r: SupplierReport) => {
    let completeReport = getSupplierCompleteAmounts(r);
    return ({
      date: getDateString(r.datetime),
      id: r.id,
      useCashToPay: r.useCashToPay ? "Si" : "No",
      totalOrderAmount: intToCurrency(r.totalOrderAmount),
      orderAmountNoIva: intToCurrency(r.orderAmountNoIva),
      orderAmount: intToCurrency(r.orderAmount),
      otherAmount: intToCurrency(r.otherAmount),
      totalAmount: intToCurrency(completeReport.totalAmount),
      cashAmount: intToCurrency(r.cashAmount),
      cashReceivedAmount: intToCurrency(r.cashReceivedAmount),
      cashDiffAmount: intToCurrency(completeReport.cashDiffAmount),
      supplierName: r.supplierName,
      supplierId: r.supplierId,
    });
  };

  return <ExcelFile element={<button className="btn btn-outline-success">Esporta resoconti</button>}
    filename={fileName}>
    <ExcelSheet data={reports.map(reportToData)} name={"Resoconti"}>
      <ExcelColumn label="NOME" value="supplierName" />
      <ExcelColumn label="CODICE FORNITORE" value="supplierId" />
      <ExcelColumn label="DATA RESOCONTO" value="date" />
      <ExcelColumn label="CONTANTI USATI COME COMPENSO" value="useCashToPay" />
      <ExcelColumn label="CODICE RESOCONTO" value="id" />
      <ExcelColumn label="TOTALE ORDINI" value="totalOrderAmount" />
      <ExcelColumn label="GUADAGNO PERSONALE (IVA ESCLUSA)" value="orderAmountNoIva" />
      <ExcelColumn label="GUADAGNO PERSONALE (IVA INCLUSA)" value="orderAmount" />
      <ExcelColumn label="ENTRATE NON CATEGORIZZATE" value="otherAmount" />
      <ExcelColumn label="TOTALE ENTRATE (IVA INCLUSA)" value="totalAmount" />
      <ExcelColumn label="CONTANTI ACCUMULATI" value="cashAmount" />
      <ExcelColumn label="CONTANTI RICEVUTI" value="cashReceivedAmount" />
      <ExcelColumn label="DIFFERENZA CONTANTI" value="cashDiffAmount" />
    </ExcelSheet>
    <ExcelSheet data={reports.filter((r) => r.useCashToPay).map(reportToData)} name={"Resoconti - Contanti usati come compenso"}>
      <ExcelColumn label="NOME" value="supplierName" />
      <ExcelColumn label="CODICE FORNITORE" value="supplierId" />
      <ExcelColumn label="DATA RESOCONTO" value="date" />
      <ExcelColumn label="CONTANTI USATI COME COMPENSO" value="useCashToPay" />
      <ExcelColumn label="CODICE RESOCONTO" value="id" />
      <ExcelColumn label="TOTALE ORDINI" value="totalOrderAmount" />
      <ExcelColumn label="GUADAGNO PERSONALE (IVA ESCLUSA)" value="orderAmountNoIva" />
      <ExcelColumn label="GUADAGNO PERSONALE (IVA INCLUSA)" value="orderAmount" />
      <ExcelColumn label="ENTRATE NON CATEGORIZZATE" value="otherAmount" />
      <ExcelColumn label="TOTALE ENTRATE (IVA INCLUSA)" value="totalAmount" />
      <ExcelColumn label="CONTANTI ACCUMULATI" value="cashAmount" />
      <ExcelColumn label="CONTANTI RICEVUTI" value="cashReceivedAmount" />
      <ExcelColumn label="DIFFERENZA CONTANTI" value="cashDiffAmount" />
    </ExcelSheet>
  </ExcelFile>;

}
*/

type SupplierReportsScreenProps = RouteComponentProps<{ id: string }, any, any>;

function _SupplierReportsScreen(props: SupplierReportsScreenProps) {
  return <SuppliersReportsScreen supplierId={props.match.params.id} />
}

const SupplierReportsScreen = withRouter(_SupplierReportsScreen);

export { SupplierReportsScreen };