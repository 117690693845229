import React, { Component } from 'react'
import { compose } from 'redux';
import { connect } from 'react-redux';
import { firestoreConnect, isLoaded } from 'react-redux-firebase';
import { updateSettings } from '../store/actions/settingActions'
import SplashScreen from './SplashScreen';
import NumberFormat from 'react-number-format';

class SettingsScreen extends Component {

  handleFormSubmit = (data) => {
    this.props.updateSettings(data);
  }

  render() {
    let { settings } = this.props;

    if (!isLoaded(settings))
      return <SplashScreen />;
    return (
      <div className="settings container my-2">
        <h4 className="my-3">Impostazioni</h4>
        <SettingsForm onSubmit={this.handleFormSubmit} settings={settings} />
      </div>
    )
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    updateSettings: (settings) => dispatch(updateSettings(settings))
  }
}

const mapStateToProps = (state) => {
  return {
    settings: state.firestore.data['settings'],
  }
}

export default compose(
  connect(mapStateToProps),
  firestoreConnect(() => [
    {
      collection: 'settings',
      doc: 'settings',
      storeAs: 'settings'
    }
  ]),
  connect(null, mapDispatchToProps))(SettingsScreen)


class SettingsForm extends Component {
  constructor(props) {
    super(props);

    this.state = this.stateFromProps(props);
  }

  stateFromProps(props) {
    return {
      supplierPercentage: props.settings.supplierPercentage,
      driverAmount: props.settings.driverAmount,
      longDistanceDriverAmount: props.settings.longDistanceDriverAmount,
      longDistanceDriverAmount2: props.settings.longDistanceDriverAmount2,
      supplierRadius: props.settings.supplierRadius,
      freeDeliveryThreshold: props.settings.freeDeliveryThreshold ?? 50,
      longDistanceThreshold: props.settings.longDistanceThreshold,
      longDistanceThreshold2: props.settings.longDistanceThreshold2,
      paypal: props.settings.paypal ?? "",
    }
  }

  handleFloatChange = (floatValue, id) => {
    if (floatValue === this.state[id])
      return;
    this.setState({
      [id]: floatValue,
      edited: true,
    });
  }


  handleChange = (e) => {
    this.setState({
      [e.target.id]: parseFloat(e.target.value),
      edited: true,
    });
  }

  handleCancel = () => {
    this.setState({
      ...this.stateFromProps(this.props),
      edited: false,
    });
  }

  handleSubmit = (e) => {
    e.preventDefault();

    const data = Object.assign({}, this.state);

    this.setState({ edited: false });
    this.props.onSubmit(data);
  }

  componentDidUpdate(oldProps, oldState) {
    if (oldState.edited !== true && oldProps !== this.props) {
      this.setState(this.stateFromProps(this.props));
    }
  }

  render() {
    return (
      <form onSubmit={this.handleSubmit}>
        <div className="card mb-4">
          <div className="mx-4 my-3">
            <div className="form-group">
              <label htmlFor="supplierRadius">Raggio fornitori (km)</label>
              <input className="form-control" min="0" max="100" type="number" step="0.1" id="supplierRadius" value={this.state.supplierRadius} required onChange={this.handleChange} />
            </div>
            <div className="form-group">
              <label htmlFor="longDistanceThreshold">Prima soglia aumento spedizione e compenso fattorino (km)</label>
              <input className="form-control" min="0" max="100" type="number" step="0.1" id="longDistanceThreshold" value={this.state.longDistanceThreshold} required onChange={this.handleChange} />
            </div>
            <div className="form-group">
              <label htmlFor="longDistanceThreshold2">Seconda soglia aumento spedizione e compenso fattorino (km)</label>
              <input className="form-control" min="0" max="100" type="number" step="0.1" id="longDistanceThreshold2" value={this.state.longDistanceThreshold2} required onChange={this.handleChange} />
            </div>
            <div className="form-group">
              <label htmlFor="freeDeliveryThreshold">Soglia della spedizione gratuita (€)</label>
              <NumberFormat
                decimalScale={2}
                allowNegative={false}
                thousandSeparator={false}
                decimalSeparator=","
                prefix="€"
                fixedDecimalScale={true}
                id="freeDeliveryThreshold"
                className="form-control"
                value={this.state.freeDeliveryThreshold}
                onValueChange={({ floatValue }) => this.handleFloatChange(floatValue, "freeDeliveryThreshold")} required />
            </div>
            <div className="form-group">
              <label htmlFor="supplierPercentage">Percentuale fornitore</label>
              <NumberFormat
                decimalScale={1}
                allowNegative={false}
                thousandSeparator={false}
                decimalSeparator=","
                suffix="%"
                fixedDecimalScale={true}
                className="form-control"
                value={this.state.supplierPercentage * 100}
                isAllowed={(values) => {
                  const { floatValue } = values;
                  return floatValue > 0 && floatValue <= 100;
                }}
                onValueChange={({ floatValue }) => {
                  if (floatValue !== this.state.supplierPercentage)
                    this.setState({ supplierPercentage: floatValue / 100., edited: true });
                }} required />
            </div>
            <div className="form-group">
              <label htmlFor="driverAmount">Ammontare fattorino per ordine (€)</label>
              <NumberFormat
                decimalScale={2}
                allowNegative={false}
                thousandSeparator={false}
                decimalSeparator=","
                prefix="€"
                fixedDecimalScale={true}
                id="driverAmount"
                className="form-control"
                value={this.state.driverAmount}
                onValueChange={({ floatValue }) => this.handleFloatChange(floatValue, "driverAmount")} required />
            </div>
            <div className="form-group">
              <label htmlFor="driverAmount">Ammontare fattorino per ordine sopra la prima soglia (€)</label>
              <NumberFormat
                decimalScale={2}
                allowNegative={false}
                thousandSeparator={false}
                decimalSeparator=","
                prefix="€"
                fixedDecimalScale={true}
                id="longDistanceDriverAmount"
                className="form-control"
                value={this.state.longDistanceDriverAmount}
                onValueChange={({ floatValue }) => this.handleFloatChange(floatValue, "longDistanceDriverAmount")} required />
            </div>
            <div className="form-group">
              <label htmlFor="driverAmount">Ammontare fattorino per ordine sopra la seconda soglia (€)</label>
              <NumberFormat
                decimalScale={2}
                allowNegative={false}
                thousandSeparator={false}
                decimalSeparator=","
                prefix="€"
                fixedDecimalScale={true}
                id="longDistanceDriverAmount2"
                className="form-control"
                value={this.state.longDistanceDriverAmount2}
                onValueChange={({ floatValue }) => this.handleFloatChange(floatValue, "longDistanceDriverAmount2")} required />
            </div>
            <div className="form-group">
              <label htmlFor="paypal" className="required">Email Paypal</label>
              <input className="form-control" type="text" id="paypal" value={this.state.paypal} required onChange={(e) => this.setState({ paypal: e.target.value, edited: true })} />
            </div>
          </div>
        </div>

        {(this.state.edited) &&
          <div className="my-2 d-flex justify-content-between">
            <button className="btn btn-danger" type="button" onClick={this.handleCancel}>Annulla</button>
            <button className="btn btn-success">Salva</button>
          </div>}

      </form>
    )
  }
}