import React from 'react'
import SupplierRequests from './components/SupplierRequests';
import SupplierCategoriesSummary from './components/SupplierCategoriesSummary';
import { useSelector } from 'react-redux';
import AreaSelectView from '../../../components/AreaSelectView';
import { RootState } from '../../../store/reducers/rootReducer';
import { Link } from 'react-router-dom';

function SupplierDashboardScreen() {
  const admin = useSelector((state: RootState) => state.firestore.data.currentAdmin);
  return (
    <div className="supplierDashboard container my-4">
      <div className="d-flex justify-content-between flex-wrap align-items-center">
        <h2 className="mb-2 mr-2">Fornitori</h2>
        <Link to="suppliers/reports" className="btn btn-outline-secondary mb-2">Visualizza resoconti</Link>
      </div>
      <SupplierRequests />
      {
        admin.isMainAdmin &&
        <SupplierCategoriesSummary />
      }
      <AreaSelectView urlPrefix="suppliers" />
    </div>
  )
}

export default SupplierDashboardScreen;