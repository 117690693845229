import React, { FormEvent } from 'react'
import { Modal, Button } from 'react-bootstrap'
import { useEffect } from 'react';
import firebase from '../../config/fbConfig'
import { useState } from 'react';
import { toastr } from 'react-redux-toastr';
import Shift from '../../models/shift';
import ShiftDriverData from '../../models/shift_driver_data';

type ChangeDriverModalProps = {
  show: boolean;
  startTime: firebase.firestore.Timestamp,
  onClose: () => void;
  orderId: string;
  areaId: string;
  canUseOwnDrivers: boolean;
  isUsingOwnDriver: boolean;
}

function ChangeDriverModal({ show, startTime, onClose, orderId, areaId, canUseOwnDrivers, isUsingOwnDriver }: ChangeDriverModalProps) {
  const [drivers, setDrivers] = useState<ShiftDriverData[] | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [selectedDriver, setSelectedDriver] = useState<string | null>(null);
  const [useOwnDrivers, setUseOwnDrivers] = useState(false);
  const [freeOtherDriver, setFreeOtherDriver] = useState(true);

  const [confirmLoading, setConfirmLoading] = useState(false);

  async function getAvailableDrivers(startTime: firebase.firestore.Timestamp) {
    const firestore = firebase.firestore();
    try {
      setLoading(true);
      setError(null);

      const docSnap = await firestore.collection("areas").doc(areaId).collection("shifts").doc(startTime.toMillis().toString()).get();
      if (!docSnap.exists)
        setDrivers([]);
      else {
        let shift = docSnap.data() as Shift;
        let availableDriversId = shift.drivers.filter((d) => shift.occupiedDrivers === undefined || shift.occupiedDrivers === null || !shift.occupiedDrivers.includes(d));

        if (availableDriversId.length > 0) {
          setUseOwnDrivers(false);
          setSelectedDriver(availableDriversId[0]);
        }
        else if (canUseOwnDrivers && !isUsingOwnDriver) {
          setUseOwnDrivers(true);
          setSelectedDriver(null);
        }

        const driversDataSnap = await firestore.collection("areas").doc(areaId).collection("shifts").doc(startTime.toMillis().toString()).collection("driversData").get();

        let drivers = [];
        for (let snap of driversDataSnap.docs) {
          let driver = snap.data() as ShiftDriverData;
          if (availableDriversId.includes(driver.driverId))
            drivers.push(driver);
        }
        setDrivers(drivers);
      }

      setLoading(false);
      setError(null);
    }
    catch (err) {
      console.log(err);
      setLoading(false);
      setError("Errore inaspettato. Riprovare più avanti.");
    }
  }

  useEffect(() => {
    if (show) {
      getAvailableDrivers(startTime);
    }
  }, [show, startTime]);

  async function handleSubmit(e: FormEvent) {
    e.preventDefault();
    const functions = firebase.functions();
    const changeOrderDriver = functions.httpsCallable("changeOrderDriver");
    try {
      setConfirmLoading(true);
      const response = await changeOrderDriver({ orderId, newDriverId: selectedDriver, useOwnDrivers: useOwnDrivers, freeOtherDriver });
      if (response.data.success) {
        toastr.success("", "Riassegnazione dell'ordine eseguita con successo.");
      }
      else {
        toastr.error("", "Errore durante la riassegnazione dell'ordine. " + response.data.error);
      }
      setConfirmLoading(false);
    }
    catch (err) {
      console.log(err)
      toastr.error("", "Errore inaspettato. Riprovare più avanti!");
      setConfirmLoading(false);
    }
    finally {
      onClose();
    }
  }

  let body;

  if (loading) {
    body =
      <div className="my-2 d-flex align-items-center flex-column">
        <div className="small-loader" />
        <div className="mt-2">Caricamento in corso...</div>
      </div>;
  }
  else if (confirmLoading) {
    body =
      <div className="my-2 d-flex align-items-center flex-column">
        <div className="small-loader" />
        <div className="mt-2">Salvataggio delle modifiche in corso...</div>
      </div>;

  }
  else if (error !== null) {
    body = <div className="my-2">{error}</div>
  }
  else {
    if (drivers!.length > 0 || (canUseOwnDrivers && !isUsingOwnDriver))
      body = <div>
        <div className="form-group">
          <label className="required">Nuovo fattorino</label>
          <select className="form-control" value={useOwnDrivers ? "OWN_DRIVER" : selectedDriver!} onChange={(e) => {
            if (e.target.value === "OWN_DRIVER") {
              setSelectedDriver(null);
              setUseOwnDrivers(true);
            }
            else {
              setSelectedDriver(e.target.value);
              setUseOwnDrivers(false);
            }
          }}>
            {canUseOwnDrivers && !isUsingOwnDriver && <option key="OWN_DRIVER" value={"OWN_DRIVER"}>Fattorino del locale</option>}
            {drivers!.map((d) => {
              return <option key={d.driverId} value={d.driverId}>{d.name}</option>;
            })}
          </select>
          {
            !isUsingOwnDriver &&
            <div className="custom-control custom-checkbox mt-2">
              <input type="checkbox" className="custom-control-input" id="freeOtherDriver" checked={freeOtherDriver} onChange={(e) => setFreeOtherDriver(e.target.checked)} />
              <label className="custom-control-label" htmlFor="freeOtherDriver">Libera il fattorino precedente in modo che sia assegnabile ad altri ordini</label>
            </div>
          }
        </div>
      </div>
    else
      body = <div>
        Nessun fattorino disponibile nel turno di quest&apos;ordine.
        <br />
        Non è possibile riassegnare l&apos;ordine.
      </div>
  }

  return (
    <Modal show={show} onHide={confirmLoading ? () => { } : onClose}>
      <form onSubmit={handleSubmit}>
        <Modal.Header closeButton={!confirmLoading}>
          <Modal.Title>Riassegna l&apos;ordine</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {body}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" type="button" onClick={onClose} disabled={confirmLoading}>
            Annulla
          </Button>
          <Button variant="success" type="submit" disabled={confirmLoading || loading || drivers === null || (drivers?.length === 0 && (!canUseOwnDrivers || isUsingOwnDriver))}>
            Conferma
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
}

export default ChangeDriverModal;
